import { VStack } from '@chakra-ui/react';
import BaseSelect from 'components/Forms/BaseSelect';
import { Site } from 'graphql/generated';
import ChartSelect from '../ChartSelect';
import ChartSwitchPanel from '../ChartSwitchPanel';
import { ChartController } from '../types';
import { ChartSettings } from './Chart';
import { ParameterOptions } from './useHydrographyPlot';

export type ControllerInputs = {
  availableSites: Site[];
};

const Controller = ({
  chartSettings,
  setChartSettings,
  chartInputs
}: ChartController<ChartSettings, ControllerInputs>) => {
  const locationOptions: { label: string; value: string }[] = chartInputs?.availableSites
    ? chartInputs.availableSites.map((s) => ({ label: s.name, value: s.smbId.toString() }))
    : [];
  const variableOptions = [
    { label: 'Water Temperature', value: 'waterTempC' },
    { label: 'Oxygen Saturation', value: 'oxygenSat' },
    { label: 'Salinity', value: 'salinityPsu' },
    { label: 'Visibility', value: 'visibility' }
  ];

  return (
    <VStack>
      {locationOptions.length > 0 && (
        <ChartSelect
          width="300px"
          label="Sublocation"
          options={locationOptions}
          value={locationOptions.find((o) => o.value === chartSettings.site.smbId.toString())}
          onChange={(option: { label: string; value: string }) => {
            setChartSettings({
              ...chartSettings,
              site: chartInputs.availableSites.find((s) => s.smbId === Number(option.value))
            });
          }}
        />
      )}
      {chartSettings.showParameterSelect && (
        <BaseSelect
          label="Variables"
          options={variableOptions}
          isMulti={true}
          value={chartSettings.parameters.map((p) => variableOptions.find((vo) => vo.value === p))}
          onChange={(options) => {
            setChartSettings({
              ...chartSettings,
              parameters: options.map((o) => o.value) as ParameterOptions
            });
          }}
        />
      )}
      <ChartSwitchPanel
        w="100%"
        py="10px"
        switches={[
          {
            left: { value: 'manual', label: 'Manually Recorded' },
            right: {
              value: 'sensor',
              label: 'Sensor Recorded',
              tooltip: 'Toggle between manual and sensor measurements'
            },
            toggle: ({ checked }) => setChartSettings({ ...chartSettings, useSensor: checked }),
            switchProps: { isChecked: chartSettings.useSensor }
          },
          {
            left: { value: 'saturation', label: 'Oxygen %' },
            right: {
              value: 'concentratiotn',
              label: 'Oxygen mg/L',
              tooltip: 'Toggle between oxygen saturation (%) and concentration (mg/L)'
            },
            toggle: ({ checked }) =>
              setChartSettings({ ...chartSettings, useOxygenConcentration: checked }),
            switchProps: { isChecked: chartSettings.useOxygenConcentration }
          },
          {
            left: { value: 'regional', label: 'Regional Color Scale' },
            right: {
              value: 'local',
              label: 'Local Color Scale',
              tooltip:
                'Adjusts the color scale limits to the maximum and minimum values measured at this site vs the regional defaults.'
            },
            toggle: ({ checked }) =>
              setChartSettings({ ...chartSettings, useLocalMinMax: checked }),
            switchProps: { isChecked: chartSettings.useLocalMinMax }
          }
        ]}
      />
    </VStack>
  );
};

export default Controller;
