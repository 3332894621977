import { Alert, AlertIcon, Box, Button, ButtonGroup, HStack, Text, VStack } from '@chakra-ui/react';
import { BinaryFilter, Query, TCubeDimension, TCubeMeasure } from '@cubejs-client/core';
import FormCheckbox from 'components/Forms/FormCheckbox';
import FormInput from 'components/Forms/FormInput';
import FormSelect from 'components/Forms/FormSelect';
import InfoPopover from 'components/InfoPopover';
import { capitalize } from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  MdAreaChart,
  MdBarChart,
  MdScatterPlot,
  MdStackedLineChart,
  MdTableChart
} from 'react-icons/md';
import { ChartController } from '../types';
import { ChartSettings } from './Chart';
import FormCubeFiltersList, { FormFilter, supportedOperators } from './FormCubeFiltersList';
import FormTimeFragmentPicker from './FormTimeFragmentPicker';
import { TimeFragment } from './TimeFragmentPicker';
import useExploreCube from './useExploreCube';

const EXCLUDE_SITE_CUBES = ['TessTides', 'TessRivers'];

type ExploreChartType = 'line' | 'table' | 'bar' | 'area' | 'scatter';

type Form = {
  displayName: string;
  measures: { label: string; value: TCubeMeasure }[];
  dimensions: { label: string; value: TCubeDimension }[];
  timeFragment: TimeFragment;
  chartType: 'line' | 'table' | 'bar' | 'area' | 'scatter';
  fillZeros: boolean;
  filters: FormFilter[];
};

// Determines if two measures or dimensions are from the same originating cube.
export const fromSameCube = (
  one: TCubeDimension | TCubeMeasure,
  two: TCubeDimension | TCubeMeasure
) => {
  return one.name
    .replaceAll('Lookup', '')
    .includes(two.name.replaceAll('Lookup', '').split('_')?.[0]);
};

// Maps Site Types to the cube filters allowed based on that type if not 'Farm'
const siteTypeMeasureFilters = {
  'Seed Area': ['TessPlankton'],
  ASC: ['TessAsc'],
  Pilot: ['TessPlankton', 'ManualHydrography']
};

const Controller = forwardRef<
  { getChartSettings: () => ChartSettings },
  ChartController<ChartSettings>
>(({ chartSettings, setChartSettings }, ref) => {
  const chartTypes: { type: ExploreChartType; icon: JSX.Element }[] = [
    { type: 'line', icon: <MdStackedLineChart /> },
    { type: 'table', icon: <MdTableChart /> },
    { type: 'bar', icon: <MdBarChart /> },
    { type: 'area', icon: <MdAreaChart /> },
    { type: 'scatter', icon: <MdScatterPlot /> }
  ];

  const { control, handleSubmit, setValue, getValues, watch } = useForm<Form>({
    defaultValues: {
      displayName: chartSettings?.displayName ?? '',
      // These initial values set later in the useEffect hook. Need loaded data...
      measures: [],
      dimensions: [],
      timeFragment: {
        granularity: 'day',
        dateRange: 'last 7 days until now'
      } as TimeFragment,
      filters: [],
      fillZeros: chartSettings?.fillZeros ?? false,
      chartType: chartSettings?.chartType ?? chartTypes[0].type
    }
  });

  const [selectedMeasures, selectedDimensions, selectedFilters, selectedChartType] = watch([
    'measures',
    'dimensions',
    'filters',
    'chartType'
  ]);

  const hasTimeDimension = useMemo(
    () => selectedDimensions.some((d) => d.value.type === 'time'),
    [selectedDimensions]
  );

  useEffect(() => {
    if (selectedMeasures.length === 0) {
      setValue('dimensions', []);
    }

    const selectedMembers = [
      ...selectedMeasures.map((sm) => sm.value.name),
      ...selectedDimensions.map((sd) => sd.value.name)
    ];

    const relatedFilters = selectedFilters.filter((sf) => {
      //Allow 'new' entries that haven't selected members yet
      if (sf.member === null || sf.member === undefined) return true;
      return selectedMembers.includes(sf.member?.value.name);
    });

    if (relatedFilters.length !== selectedFilters.length) {
      setValue('filters', relatedFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMeasures.length, selectedDimensions.length, selectedFilters.length]);

  const { exploreCube } = useExploreCube();
  const [measureOptions, dimensionOptions] = useMemo(() => {
    const mos = Object.values(exploreCube?.measures ?? {}).map((measure) => ({
      // Titles from the explore view come in odd, so we have to lookup the real 'title' from the other cube metadata
      // @ts-ignore
      label: measure.original.title.replaceAll('Lookup', ''),
      value: measure
    }));

    const dos = Object.values(exploreCube?.dimensions ?? {}).map((dimension) => ({
      // @ts-ignore
      label: dimension.original.title.replaceAll('Lookup', ''),
      value: dimension
    }));

    return [mos, dos];
  }, [exploreCube?.measures, exploreCube?.dimensions]);

  // Set init values based on loaded options if init chartSettings present.
  useEffect(() => {
    if (chartSettings?.query?.dimensions.length > 0) {
      const selectedDimensions = dimensionOptions.filter((dom) =>
        chartSettings.query.dimensions.includes(dom.value.name)
      );
      setValue('dimensions', selectedDimensions);
    }

    if (chartSettings?.query?.measures.length > 0) {
      const selectedMeasures = measureOptions.filter((m) =>
        chartSettings.query.measures.includes(m.value.name)
      );
      setValue('measures', selectedMeasures);
    }

    let queryFilters = chartSettings?.query?.filters;
    if (chartSettings.site) {
      queryFilters = chartSettings?.query?.filters.filter(
        (f: BinaryFilter) => !f.member.includes('Site.Id') || !f.member.includes('Site_id')
      );
    }

    if (queryFilters?.length > 0) {
      const formFilters: FormFilter[] = queryFilters.map((f: BinaryFilter) => {
        const member = [...dimensionOptions, ...measureOptions].find(
          (o) => o.value.name === f.member
        );

        return {
          member,
          operator: supportedOperators.find((so) => so.value === f.operator),
          value: f.values.join(',')
        };
      });

      setValue('filters', formFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensionOptions.length, measureOptions.length]);

  const [displayedDimensions, displayedMeasures] = useMemo(() => {
    const selectedDimensionValues = selectedDimensions.map((sm) => sm.value);
    let dos = dimensionOptions.filter(
      (dim) => !selectedDimensionValues.map((dv) => dv.name).includes(dim.value.name)
    );

    // Filter measures if they are already selected
    const selectedMeasureValues = selectedMeasures.map((sm) => sm.value);
    let mos = measureOptions.filter(
      (mo) => !selectedMeasureValues.map((sv) => sv.name).includes(mo.value.name)
    );

    // Filter measures to the same cube for now
    if (selectedMeasureValues.length > 0)
      mos = mos.filter((mo) => fromSameCube(selectedMeasureValues?.[0], mo.value));

    // Filter to only dimensions related to the measures selected
    // With the exception of 'Site Name' if we are in the project context
    if (selectedMeasureValues.length > 0) {
      dos = dos.filter(
        (dim) =>
          fromSameCube(selectedMeasureValues?.[0], dim.value) ||
          (dim.value.name === 'Explore.Site_name' && !chartSettings.site)
      );
    }

    // Project filters
    if (!chartSettings.project.siwi) {
      mos = mos.filter((mo) => !mo.label.toLowerCase().includes('siwi'));
      dos = dos.filter((dim) => !dim.label.toLowerCase().includes('siwi'));
    }

    if (!chartSettings.project.forecasting) {
      mos = mos.filter((mo) => !mo.label.toLowerCase().includes('hydrography forecast'));
      dos = dos.filter((dim) => !dim.label.toLowerCase().includes('hydrography forecast'));
    }

    // Site filters
    if (chartSettings?.site?.siteLabel in siteTypeMeasureFilters) {
      mos = mos.filter((mos) =>
        siteTypeMeasureFilters[chartSettings.site.siteLabel].some((cubeName: string) =>
          mos.value.name.includes(cubeName)
        )
      );
    }

    if (chartSettings?.site?.siteLabel !== 'ASC') {
      mos = mos.filter((mos) => !mos.value.name.includes('TessAsc'));
    }

    return [dos, mos];
  }, [
    selectedDimensions,
    dimensionOptions,
    selectedMeasures,
    measureOptions,
    chartSettings?.project.siwi,
    chartSettings?.project.forecasting,
    chartSettings?.site?.siteLabel
  ]);

  const setChartSettingsFromForm = (form: Form) => {
    const timeDimensions = Object.values(exploreCube?.dimensions).filter((d) => d.type === 'time');
    const selectedTimeDimension = timeDimensions.find((timeD) =>
      fromSameCube(timeD, form?.measures?.[0].value)
    );

    const additionalFilters: BinaryFilter[] = form.filters
      .filter((ff) => ff.member && ff.operator && ff.value)
      .map((formFilter) => {
        return {
          member: formFilter.member.value.name,
          operator: formFilter.operator.value,
          values: formFilter.value.split(',').map((v) => v.trim())
        };
      });

    const settings = {
      ...chartSettings,
      chartType: form.chartType,
      fillZeros: form.fillZeros,
      displayName: form.displayName,
      query: {
        measures: form.measures?.map((m) => m.value.name) ?? [],
        dimensions:
          // If a time dimension is selected and the granularity is 'no grouping', then use it as an authentic dimension
          hasTimeDimension && !form.timeFragment.granularity
            ? form.dimensions.map((d) => d?.value?.name)
            : form.dimensions?.filter((d) => d.value.type !== 'time').map((d) => d?.value?.name) ??
              [],
        timeDimensions: [
          {
            //Auto-match measure to time dimension by default
            dimension: selectedTimeDimension.name,
            ...form.timeFragment,
            granularity: !hasTimeDimension ? undefined : form.timeFragment.granularity
          }
        ],
        filters: [
          ...additionalFilters,
          ...(chartSettings?.site &&
          !selectedMeasures.some((m) => EXCLUDE_SITE_CUBES.some((e) => m.value.name.includes(e)))
            ? [
                {
                  member: 'Explore.Site_id',
                  operator: 'equals',
                  values: [chartSettings.site.smbId]
                }
              ]
            : [])
        ],
        order: hasTimeDimension ? { [selectedTimeDimension.name]: 'desc' } : undefined
      } as Query
    };
    setChartSettings(settings);
    return settings;
  };

  // See Explore onSave for where this is called.
  // It was the best way to handle the 'useForm' flow with the save button.
  useImperativeHandle(ref, () => ({
    getChartSettings() {
      return setChartSettingsFromForm(getValues());
    }
  }));

  return (
    <Box mb="15px">
      <VStack spacing={5} alignItems="stretch" justifyContent="space-between">
        <FormInput control={control} name="displayName" label="Display Name" />
        <HStack>
          <Box w="50%">
            <FormSelect
              control={control}
              name="measures"
              options={displayedMeasures}
              label="Measures"
              tooltip="Select a data source from the  ‘Measure’ dropdown.  Modify the time range and aggregation with the 'TimeFrame' and 'By' dropdowns."
              isMulti
              placeholder="Please select"
            />
          </Box>
          <Box w="50%">
            <FormSelect
              control={control}
              name="dimensions"
              options={displayedDimensions}
              isDisabled={selectedMeasures?.length === 0}
              label="Dimension"
              tooltip="Group the data source by metadata like sublocation, depth, etc."
              isMulti
              placeholder="Please select"
            />
          </Box>
        </HStack>

        <FormCubeFiltersList
          control={control}
          name="filters"
          members={[...selectedMeasures, ...selectedDimensions]}
        />

        <HStack alignItems="start" w="100%">
          <FormTimeFragmentPicker
            includeNoGrouping={true}
            control={control}
            name="timeFragment"
            showGranularity={hasTimeDimension}
          />
        </HStack>

        {!hasTimeDimension ? (
          <Alert w="800px" status="info" variant="left-accent">
            <AlertIcon mb="5px" />
            The selected time frame of aggregates are &apos;frozen&apos; and are NOT overwritten on
            the explore page. Use the &apos;Custom&apos; Time Frame option to select a custom time
            range here.
          </Alert>
        ) : (
          <>
            <Alert w="800px" status="info" variant="left-accent">
              <AlertIcon mb="5px" />
              The selected time frame is over-written on the explore page. This is for sandbox
              purposes only.
            </Alert>

            <HStack>
              <FormCheckbox
                w="fit-content"
                display="inline-block"
                label="Fill Zeros"
                control={control}
                name="fillZeros"
              />
              <InfoPopover>
                If enabled, the chart will fill in missing data points with 0.
              </InfoPopover>
            </HStack>
          </>
        )}

        <Box>
          <Text>Chart Type</Text>
          <ButtonGroup isAttached>
            {chartTypes.map((ct, i) => (
              <Button
                variant={ct.type === selectedChartType ? 'solid' : 'outline'}
                colorScheme={ct.type === selectedChartType ? 'blue' : undefined}
                key={i}
                leftIcon={ct.icon}
                onClick={() => setValue('chartType', ct.type)}>
                {capitalize(ct.type)}
              </Button>
            ))}
          </ButtonGroup>
        </Box>

        <Button colorScheme="blue" variant="solid" onClick={handleSubmit(setChartSettingsFromForm)}>
          Preview
        </Button>
      </VStack>
    </Box>
  );
});

Controller.displayName = 'Controller';

export default Controller;
