import { permissions } from '@scoot/permissions';
import { Site, User } from 'graphql/generated';
import { FC } from 'react';
import { ASCEntry } from './ASC/ASCEntry';
import Alarm from './Alarm/Alarm';
import Environment from './Environment/Environment';
import { Weather } from './Environment/Weather';
import EquipmentData from './Equipment/Data';
import { EquipmentDevices } from './Equipment/EquipmentDevices';
import { EquipmentEntry } from './Equipment/EquipmentEntry';
import Events from './Events/Events';
import Explore from './Explore/Explore';
import FishHealth from './FishHealth/FishHealth';
import Forecast from './Forecast/Forecast';
import HydrographyData from './Hydrography/HydrographyData';
import { HydrographyEntry } from './Hydrography/HydrographyDataEntryTab/HydrographyEntry';
import { PlanktonData } from './Plankton/PlanktonData';
import { PlanktonEntry } from './Plankton/PlanktonDataEntryTab/PlanktonEntry';
import SIWI from './SIWI/SIWI';
import SeaLiceData from './SeaLice/SeaLiceData';
import SeaLiceEntry from './SeaLice/SeaLiceDataEntryTab/SeaLiceEntry';

type Tab<TProps = object> = {
  id: string;
  serverKey?: string;
  displayName: string;
  panes?: Pane[];
  element?: FC<TProps>;
  permission?: (user: User, site: Site) => boolean;
  alwaysInclude?: boolean;
};

type Pane<TProps = object> = {
  id: string;
  serverKey: string;
  displayName: string;
  element?: FC<TProps>;
  permission?: (user: User, site: Site) => boolean;
};

const alarmTab = {
  id: 'alarm',
  displayName: 'Alarms',
  element: Alarm,
  alwaysInclude: true
};

const oceanPane = {
  id: 'environment',
  serverKey: 'Environment',
  displayName: 'Ocean',
  element: Environment
};
const weatherPane = {
  id: 'weather',
  serverKey: 'Weather',
  displayName: 'Weather',
  element: Weather
};

const environmentTab = {
  id: 'environment',
  displayName: 'Environment',
  panes: [oceanPane, weatherPane]
};

const fishHealthTab = {
  id: 'fish-health',
  serverKey: 'Fish Health',
  displayName: 'Fish Health',
  element: FishHealth
};

const planktonDataPane = {
  id: 'data',
  serverKey: 'Plankton Data',
  displayName: 'Data',
  element: PlanktonData
};
const planktonEntryPane = {
  id: 'entry',
  serverKey: 'Plankton Entry',
  displayName: 'Entry',
  element: PlanktonEntry,
  permission: permissions.canSubmitPlanktonForSite
};
const planktonTab = {
  id: 'plankton',
  displayName: 'Plankton',
  panes: [planktonDataPane, planktonEntryPane]
};

const hydrographyDataPane = {
  id: 'data',
  serverKey: 'Hydrography Data',
  displayName: 'Data',
  element: HydrographyData
};
const hydrographyEntryPane = {
  id: 'entry',
  serverKey: 'Hydrography Entry',
  displayName: 'Entry',
  element: HydrographyEntry,
  permission: permissions.canSubmitManualHydrographyForSite
};
const hydrographyTab = {
  id: 'hydrography',
  displayName: 'Hydrography',
  panes: [hydrographyDataPane, hydrographyEntryPane]
};

const ascTab = {
  id: 'asc',
  serverKey: 'ASC',
  displayName: 'ASC',
  element: ASCEntry,
  permission: permissions.canSubmitASCForSite
};

const exploreTab = {
  id: 'explore',
  displayName: 'Explore',
  element: Explore,
  alwaysInclude: true
};

const eventsTab = {
  id: 'events',
  serverKey: 'Events',
  displayName: 'Events',
  element: Events,
  permission: permissions.canManageEventsForSite
};

const equipmentEntryPane = {
  id: 'entry',
  serverKey: 'Equipment Entry',
  displayName: 'Entry',
  permission: permissions.canSubmitEquipmentForSite,
  element: EquipmentEntry
};
const equipmentDevicePane = {
  id: 'devices',
  serverKey: 'Equipment Devices',
  displayName: 'Devices',
  element: EquipmentDevices
};
const equipmentDataPane = {
  id: 'data',
  serverKey: 'Equipment Status',
  displayName: 'Data',
  element: EquipmentData
};
const equipmentTab = {
  id: 'equipment',
  displayName: 'Equipment',
  panes: [equipmentDataPane, equipmentEntryPane, equipmentDevicePane]
};

const seaLiceDataPane = {
  id: 'data',
  serverKey: 'Sea Lice Data',
  displayName: 'Data',
  element: SeaLiceData
};
const seaLiceEntryPane = {
  id: 'entry',
  serverKey: 'Sea Lice Entry',
  displayName: 'Entry',
  element: SeaLiceEntry,
  permission: permissions.canSubmitSeaLiceForSite
};
const seaLiceTab = {
  id: 'sea-lice',
  displayName: 'Sea Lice',
  panes: [seaLiceDataPane, seaLiceEntryPane]
};

const siwiTab: Tab = {
  id: 'siwi',
  displayName: 'SIWI',
  serverKey: 'Siwi',
  element: SIWI
  //Limit griegbc siwi to superuser only for the time being
  // permission: (_user, site) => site.tabs.includes('Siwi')
};

const forecastTab = {
  id: 'forecast',
  displayName: 'Forecast',
  serverKey: 'Forecast',
  element: Forecast
  // permission: permissions.isSuperuser
  // alwaysInclude: true // this is temporary for dev.
};

export const tabArray: Tab[] = [
  environmentTab,
  fishHealthTab,
  siwiTab,
  forecastTab,
  planktonTab,
  hydrographyTab,
  ascTab,
  exploreTab,
  eventsTab,
  equipmentTab,
  seaLiceTab,
  alarmTab
];

export const siteTabs = (site: Site, user: User): Tab[] => {
  if (!site || !site.tabs) return [];

  if (site.archived) return [exploreTab];

  const filteredTabs: Tab[] = [];

  tabArray.forEach((primaryTab) => {
    if (site.tabs.includes(primaryTab.serverKey) || primaryTab.alwaysInclude) {
      if (!primaryTab.permission) {
        filteredTabs.push(primaryTab);
      } else {
        primaryTab.permission(user, site) && filteredTabs.push(primaryTab);
      }
    }

    primaryTab.panes &&
      primaryTab.panes.forEach((pane) => {
        if (pane.permission && !pane.permission(user, site)) {
          return;
        }

        if (site.tabs.includes(pane.serverKey)) {
          const existingTab = filteredTabs.find((et) => et.id === primaryTab.id);

          //if there's an existing tab, add the pane
          if (existingTab) {
            existingTab.panes.push(pane);
            //otherwise add the tab, and just this pane
          } else {
            filteredTabs.push({ ...primaryTab, panes: [pane] });
          }
        }
      });
  });

  return filteredTabs;
};
