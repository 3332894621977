import { ProjectContextType } from 'contexts/ProjectContext';
import { Site } from 'graphql/generated';
import { useState } from 'react';
import ChartTile from '../ChartTile';
import Chart, { ChartSettings } from './Chart';
import Controller from './Controller';

const Tile = ({ site, project }: { site: Site; project: ProjectContextType }) => {
  const [hydrographyOptions, setHydrographyOptions] = useState<ChartSettings>({
    site,
    project,
    useLocalMinMax: false,
    useSensor: false,
    useOxygenConcentration: false,
    parameters: ['waterTempC', 'oxygenSat', 'salinityPsu', 'visibility']
  });
  if (!site) {
    return <></>;
  }
  return (
    <ChartTile
      heading="Barge Hydrography"
      hash="barge-hydrography"
      site={site}
      control={
        <Controller chartSettings={hydrographyOptions} setChartSettings={setHydrographyOptions} />
      }>
      <Chart skip={false} settings={hydrographyOptions} />
    </ChartTile>
  );
};

export default Tile;
