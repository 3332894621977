import { InfoOutlineIcon } from '@chakra-ui/icons';
import { HStack, Text, Tooltip } from '@chakra-ui/react';
import { uniq } from 'lodash';
import { useMemo } from 'react';
import Select from 'react-select';
import { locationToIndex } from 'shared/functions/location';
import { ChartController } from '../types';
import { ChartSettings } from './Chart';

export type ControllerInputs = {
  availableSublocations: string[];
};

const Controller = ({
  setChartSettings,
  chartSettings,
  chartInputs: { availableSublocations }
}: ChartController<ChartSettings, ControllerInputs>) => {
  const cageSublocs = useMemo(
    () =>
      uniq(['All', ...availableSublocations])
        .sort((a, b) => locationToIndex(a) - locationToIndex(b))
        .map((x) => {
          return { value: x, label: x };
        }),
    [availableSublocations]
  );

  return (
    <HStack justify="center" pb="10px">
      <Tooltip label="Fish count summed across the selected sublocations" fontSize="md">
        <InfoOutlineIcon fontSize="md" mb="5px" />
      </Tooltip>
      <Text>Sublocation </Text>
      <Select
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            width: '300px'
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            textAlign: 'left'
          })
        }}
        defaultValue={{ value: 'All', label: 'All' }}
        onChange={(x) => setChartSettings({ ...chartSettings, selectedSublocation: x.value })}
        options={cageSublocs}
        value={cageSublocs.find((lo) => lo.value === chartSettings?.selectedSublocation)}
      />
    </HStack>
  );
};

export default Controller;
