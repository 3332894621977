import {
  Box,
  ListItem,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList
} from '@chakra-ui/react';
import { UserContext } from 'contexts/UserContext';
import { useContext } from 'react';
import {
  LICE_STAGE_NAMES,
  LICE_STAGE_NAMES_PROJECT_ID,
  MOTILE_LICE_STAGE_IDS_PROJECT_ID
} from 'shared/Utils';
import { User } from '../../graphql/generated';
import FaqEntry from './FaqEntry';

const liceRowList = (currentUser: User, projectIds: number[]) => {
  const allRows = projectIds.flatMap((projectId) => {
    const allRows = [];
    if (Object.keys(MOTILE_LICE_STAGE_IDS_PROJECT_ID).includes(projectId.toString())) {
      let org = '';
      currentUser.organizations.map((d) => {
        const orgProjects = d.projects.map((d) => d.id);
        if (orgProjects.includes(projectId)) {
          org = d.name;
        }
      });
      Object.entries(LICE_STAGE_NAMES_PROJECT_ID[projectId]).map(([lsId, lsName]) => {
        allRows.push(
          <tr key={lsId}>
            <Td>{lsName}</Td>
            <Td>
              <b>{MOTILE_LICE_STAGE_IDS_PROJECT_ID[projectId].includes(lsId).toString()}</b>
            </Td>
            <Td>{org}</Td>
          </tr>
        );
      });
    }
    return allRows;
  });
  const liceTable = (
    <TableContainer>
      <Table variant="striped">
        <TableCaption>Lice stages included in SeaState.</TableCaption>
        <Thead>
          <Tr>
            <Th>Lice Stage</Th>
            <Th>Is Motile?</Th>
            <Th>Organization</Th>
          </Tr>
        </Thead>
        <Tbody>{allRows}</Tbody>
      </Table>
    </TableContainer>
  );
  return liceTable;
};

const FAQ = () => {
  const currentUser = useContext(UserContext);
  const projectIds = currentUser.projects.flatMap((d) => d.id);

  const liceTable = liceRowList(currentUser, projectIds);
  return (
    <>
      <Text fontSize="4xl" margin="1em 0">
        FAQs
      </Text>
      <Box backgroundColor="#f0f3f5" pt={10} pb={8} width="100%">
        <Box pl={14} pr={14}>
          <Text fontSize="3xl">Data</Text>
          <FaqEntry title="Why is data in SeaState not matching Fishtalk?">
            <p>
              Depending on how we ingest your data from Fish Talk, it can take a day for changes
              made to Fishtalk to propagate through SeaState.{' '}
            </p>
            <br />
            <p>
              Check back in a couple days and if that data still doesn’t match what you see in
              Fishtalk, please submit a ticket.{' '}
            </p>
          </FaqEntry>

          <FaqEntry title="How often does data update in SeaState?">
            <p>
              We access data from your existing hardware systems and publicly available sources, and
              unify it in the SeaState dashboard.{' '}
            </p>
            <br />
            <p>
              SeaState checks for new data every (frequencies depend on the systems your farm
              operates):
            </p>
            <dl style={{ paddingRight: 50, paddingLeft: 50 }}>
              <dd>- Fishtalk 4 times per day</dd>
              <dd>- RealFish 1 time per day in the afternoon PST (pacific standard time)</dd>
              <dd>- Atmospheric weather data 4 times per day</dd>
              <dd>- In-Pen Sensors every 5 to 15 minutes</dd>
            </dl>
          </FaqEntry>

          <FaqEntry title="If I change data in Fishtalk, when does that change show up in SeaState?">
            <p>We look backward 36 days for any changes in Fishtalk. </p>
            <br />
            <p>
              If we detect a change, we re-ingest that data and update that information in SeaState.{' '}
            </p>
          </FaqEntry>

          <FaqEntry title="How long do I have to edit data entered in SeaState?">
            <p>
              All forms can be edited for 30 days after the report date. Once that 30 days has
              passed, you will be able to open and view the report in read-only mode.
            </p>
            <br />
            <p>
              To make changes to a report that is more than 30 days old please contact us or report
              an issue.{' '}
            </p>
          </FaqEntry>

          <Text fontSize="3xl">Platform</Text>
          <FaqEntry title="Why is the In-Pen sensor data a different opacity and have a gray border?">
            <p>
              If SeaState has not received data from your sensors for more than 1 hour from the
              present time, we highlight that data stream as stale with a gray border color and
              lighter opacity.
            </p>
            <br />
            <p>
              We do this to call attention to that particular sensor and for someone on site to
              confirm that it is working properly.
            </p>
            <br />
            <p>
              It’s possible that the sensor is disconnected, the site computer isn’t online, or
              there is an interruption to the sensor database.{' '}
              <b>If this problem persists, contact your hardware provider.</b>
            </p>
          </FaqEntry>
          {/* Really bad hack for limit this area to GriegBc only */}
          {projectIds.includes(2) && (
            <FaqEntry title="Why is GDB not connected or installed on my site computer?">
              <p>
                <b>Not applicable for farms using Innovasea equipment.</b>
              </p>
              <br />
              <p>
                GDB (a program that connects directly to the sensor database on the site computer)
                is probably not sending data. This could be because GDB isn’t running, the computer
                is not connected to the internet, the site is running an outdated version of GDB,
                data is not being written to the database on the site, or GDB can’t access the
                database on the site.
              </p>
              <br />
              <p>
                If GDB is running an outdated version or gives an error message on startup, it can
                usually be resolved by re-installing the application.
              </p>
              <p>
                More precise troubleshooting information can be gleaned by looking at the Devices
                page under the Equipment Status tab. Here you will see a list of all of the devices
                we have in our system for the site. Each row can be expanded to view recent activity
                and a running daily average value for the device. In some cases the devices on site
                will have changed how they are writing data and our system will be lagging behind
                that change. In these cases a red ! icon will show next to Device Verification.
                Clicking on the icon you should see Unmapped devices (ids being written to the site
                database that we don’t have in our system) and Stale devices (ids we have in our
                system that haven’t had new data written in over a day). The latter case is
                something that should be fixed on our end while the former is usually an issue with
                the probe or probe software and you should follow up with the probe manufacturer to
                get that resolved.
              </p>
              <br />
              <p>
                Installation guide:{' '}
                <a
                  style={{ textDecoration: 'underline', color: 'blue' }}
                  href="https://gdb-external.s3.us-west-2.amazonaws.com/INSTALL.html"
                  target="_blank"
                  rel="noreferrer">
                  click here
                </a>
              </p>
            </FaqEntry>
          )}

          <FaqEntry title="A sensor is missing from the Current Status In-Pen figure. What do I do?">
            <p>
              If you are missing in-pen sensors it is either because we have not received data from
              that sensor in over 7 days or:
            </p>
            <br />
            <b>
              Case - GDB is connected, and the probe is showing data in the native platform, but not
              in Seastate:
            </b>
            <br />
            <p>
              If the probes are displaying data within their native program but not in SeaState, the
              data likely isn’t being written to the local database on the site computer.
            </p>
            <br />
            <p>
              The next step is to contact the probe manufacturer and have them check why the probes
              are not writing data to the local database on the site computer.
            </p>
            <p>
              The GDB program installed on the site computer will automatically detect and transmit
              new probe data that is successfully written to the local database. You can confirm
              that all probe device IDs are associated and up to date by checking in the Equipment
              Status Tab &#8594; Devices.
            </p>
            <br />
            <p>
              Otherwise, after the probe manufacturer does this work, please contact us and we’ll
              re-associate the probe device IDs.
            </p>
            <br />
            <b>
              Case - Innovasea probe is showing data in the native platform, but not in Seastate:
            </b>
            <br />
            <p>
              If the probes are displaying data within their native program but not in SeaState,
              please contact us to diagnose the issue further.
            </p>
          </FaqEntry>

          <FaqEntry title="How can I access the SeaState API?">
            <p>
              If you would like access to the SeaState API please submit an issue via the ‘report an
              issue’ feature in SeaState.
            </p>
            <br />
            <p>
              Documentation and examples can be found at{' '}
              <a
                href="https://seastate.scootscience.com/docs"
                style={{ textDecoration: 'underline', color: 'blue' }}
                target="_blank"
                rel="noreferrer">
                https://seastate.scootscience.com/docs
              </a>{' '}
              or by clicking ‘seastate documentation’ in the top right task bar drop down.
            </p>
          </FaqEntry>

          <Text fontSize="3xl">Forecast</Text>
          <FaqEntry title="What data is used in my farm’s ocean forecast?">
            <p>
              We initialize our models with your manually measured temperature, salinity, and
              dissolved oxygen data. The data you collect throughout the water column is critical to
              ocean forecasting! The accuracy and consistency of your data is essential in producing
              a good forecast. Forecasts for the ocean at your farm update once per day.
            </p>
            <br />
            <p>
              We couple your measured ocean data with the atmospheric weather to drive forward an
              ocean forecast specifically for your farm. Every farm is unique. We performed a
              historical analysis and tuning process for each farm site to optimize the forecasts
              for that specific location.
            </p>
          </FaqEntry>

          <Text fontSize="3xl">Sea Lice</Text>
          <FaqEntry title="How are sea lice averages computed?">
            <p>
              Sea lice data are generated by sampling individual fish from containers at a site. The{' '}
              <b>average sea lice per fish</b> is the{' '}
              <u>
                sum of the lice counted across all fish samples divided by the total number of fish
                sampled.
              </u>{' '}
              It can also be the sum of the lice count of a given stage divided by the total number
              of fish sampled when data are broken down by lice stage.{' '}
            </p>
            <p>
              <br />
              When different numbers of fish are sampled for sea lice in different containers, a{' '}
              <b>weighted average</b> reflecting the difference in sample numbers is returned any
              time sea lice data are aggregated across those containers.
            </p>
          </FaqEntry>
          <FaqEntry title="What are MOTILE sea lice?">
            <p>
              The sea lice life cycle begins with free floating eggs, which hatch and continue
              development as larvae. If the find a host fish, they attach and begin to grow. Once
              they begin to move around on the surface of the host fish, typically at the
              &quot;pre-adult&quot; to &quot;adult&quot; phase, they are considered <b> motile </b>{' '}
              (see{' '}
              <a href="https://www.dfo-mpo.gc.ca/about-notre-sujet/publications/infographics-infographies/lice-pou-eng.html">
                <u>Gov. Canada Info on Sea Lice</u>
              </a>
              ).
            </p>
            <p>
              <br /> The following sea lice stages are considered motile on SeaState:
            </p>{' '}
            <UnorderedList>
              {projectIds.flatMap((projectId) => {
                const allRows = [];
                if (Object.keys(MOTILE_LICE_STAGE_IDS_PROJECT_ID).includes(projectId.toString())) {
                  MOTILE_LICE_STAGE_IDS_PROJECT_ID[projectId].map((ls) => {
                    allRows.push(<ListItem key={ls}>{LICE_STAGE_NAMES[ls]}</ListItem>);
                  });
                }
                return allRows;
              }, {})}
            </UnorderedList>
          </FaqEntry>
          <FaqEntry title="What sea lice stages are in SeaState?">
            <p>The following sea lice stages are included in SeaState:</p>
            <br />
            {liceTable}
          </FaqEntry>
        </Box>
      </Box>
    </>
  );
};

export default FAQ;
