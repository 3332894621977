import { Box, HStack, Icon, Text, Tooltip } from '@chakra-ui/react';
import { ProjectContext } from 'contexts/ProjectContext';
import { useContext } from 'react';
import { toTimezone } from 'shared/Utils';
import {
  WiMoonAltFirstQuarter,
  WiMoonAltThirdQuarter,
  WiMoonAltWaningCrescent3,
  WiMoonAltWaningGibbous3,
  WiMoonAltWaxingCrescent3,
  WiMoonAltWaxingGibbous3,
  WiMoonFull,
  WiMoonNew
} from 'weather-icons-react';

const Moon = {
  phases: [
    'New',
    'Waxing Crescent',
    'First Quarter',
    'Waxing Gibbous',
    'Full',
    'Waning Gibbous',
    'Last Quarter',
    'Waning Crescent'
  ],
  emojis: [0x1f311, 0x1f312, 0x1f313, 0x1f314, 0x1f315, 0x1f316, 0x1f317, 0x1f318],
  icons: [
    WiMoonNew,
    WiMoonAltWaxingCrescent3,
    WiMoonAltFirstQuarter,
    WiMoonAltWaxingGibbous3,
    WiMoonFull,
    WiMoonAltWaningGibbous3,
    WiMoonAltThirdQuarter,
    WiMoonAltWaningCrescent3
  ],
  phase: function (rightnow) {
    // modified from https://github.com/jasonsturges/lunarphase-js
    // Timestamp epoch, January 1, 1970, in Julian Days.
    const EPOCH = 2440587.5;
    // Length of one synodic month - lunation, or days for the phases to complete a cycle.
    const SYNODIC_MONTH = 29.53058770576;
    // get Julian Date
    // Julian calendar, chronological days since noon Universal Time on January 1, 4713 BC
    const julianDate = rightnow.getTime() / 86400000 - rightnow.getTimezoneOffset() / 1440 + EPOCH;
    // Percentage through the lunar synodic month.
    let lunarAgePercent = (julianDate - 2451550.1) / SYNODIC_MONTH;
    lunarAgePercent -= Math.floor(lunarAgePercent);
    if (lunarAgePercent < 0) lunarAgePercent += 1;
    let lunarPhase = Math.round(lunarAgePercent * 8); // scale fraction from 0-8 and round
    if (lunarPhase >= 8) lunarPhase = 0; // 0 and 8 are the same so turn 8 into 0
    return {
      phase: lunarPhase,
      name: Moon.phases[lunarPhase],
      emoji: Moon.emojis[lunarPhase],
      icon: Moon.icons[lunarPhase]
    };
  }
};

const MoonPhase = () => {
  const projectContext = useContext(ProjectContext);
  const rightnow = toTimezone(new Date(), projectContext.timezoneOffset);
  const moon = Moon.phase(rightnow);
  return (
    <Box textAlign="center">
      <Tooltip
        label="Moon Phases: New > Waxing Crescent > First Quarter > Waxing Gibbous > Full > Waning Gibbous > Last Quarter > Waning Crescent"
        fontSize="md">
        <HStack justifyContent="center">
          <Icon as={moon.icon} color="gray.500" boxSize="40px" />
          <Text fontSize="sm">{moon.name}</Text>
        </HStack>
      </Tooltip>
    </Box>
  );
};

export default MoonPhase;
