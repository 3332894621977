import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
};

export type AscReport = {
  __typename?: 'ASCReport';
  formInput?: Maybe<Scalars['JSON']>;
  id: Scalars['Int'];
  lastUpdated: Scalars['Date'];
  reportTime: Scalars['Date'];
  reportedBy: Scalars['String'];
  siteId?: Maybe<Scalars['Int']>;
  transactionId: Scalars['String'];
};

export type AlarmChecksCount = {
  __typename?: 'AlarmChecksCount';
  count: Scalars['Int'];
  state: AlarmState;
};

export type AlarmFrequency = {
  __typename?: 'AlarmFrequency';
  interval: Scalars['Int'];
  unit: AlarmFrequencyUnit;
};

export type AlarmFrequencyInput = {
  interval: Scalars['Int'];
  unit: Scalars['String'];
};

export enum AlarmFrequencyUnit {
  Hours = 'HOURS',
  Minutes = 'MINUTES'
}

export type AlarmGroup = {
  __typename?: 'AlarmGroup';
  checkRuns: Array<AlarmStatusCheckRun>;
  description: Scalars['String'];
  frequency?: Maybe<AlarmFrequency>;
  id: Scalars['Int'];
  name: Scalars['String'];
  siteAlarms: Array<SiteAlarm>;
  thresholds: Array<Maybe<AlarmThreshold>>;
  unknownIntervalSetting: UnknownAlarmIntervalSetting;
};


export type AlarmGroupCheckRunsArgs = {
  endTime: Scalars['Date'];
  siteId?: InputMaybe<Scalars['Int']>;
  startTime: Scalars['Date'];
};

export type AlarmGroupInput = {
  description: Scalars['String'];
  frequency?: InputMaybe<AlarmFrequencyInput>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  thresholds: Array<AlarmThresholdInput>;
  unknownIntervalSetting: UnknownAlarmIntervalSetting;
};

export type AlarmNotificationReceipt = {
  __typename?: 'AlarmNotificationReceipt';
  destination: Scalars['String'];
  status: Scalars['String'];
  type: ContactType;
};

export type AlarmNotifier = {
  __typename?: 'AlarmNotifier';
  id: Scalars['Int'];
  type: ContactType;
  value: Scalars['String'];
};

export type AlarmNotifierInput = {
  contactListId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  type: ContactType;
  value: Scalars['String'];
};

export enum AlarmState {
  Alert = 'ALERT',
  Error = 'ERROR',
  Ok = 'OK',
  Unknown = 'UNKNOWN'
}

export type AlarmStatusCheckRun = {
  __typename?: 'AlarmStatusCheckRun';
  alarmGroup: AlarmGroup;
  checkedAt: Scalars['Date'];
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  site: Site;
  siteId: Scalars['Int'];
  state: AlarmState;
};

export type AlarmThreshold = {
  __typename?: 'AlarmThreshold';
  alarmGroup: AlarmGroup;
  alarmGroupId: Scalars['Int'];
  filters?: Maybe<Scalars['JSON']>;
  id: Scalars['Int'];
  measure: Scalars['String'];
  operator: Scalars['String'];
  timeDimension: Scalars['String'];
  value: Scalars['String'];
};

export type AlarmThresholdInput = {
  filters: Scalars['JSON'];
  id?: InputMaybe<Scalars['Int']>;
  measure: Scalars['String'];
  operator: Scalars['String'];
  timeDimension: Scalars['String'];
  value: Scalars['String'];
};

export type AlertsCount = {
  __typename?: 'AlertsCount';
  alarmGroupId: Scalars['Int'];
  count: Scalars['Int'];
  siteAlarmId: Scalars['Int'];
  siteId: Scalars['Int'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  action: Scalars['String'];
  key: Scalars['String'];
  siteName: Scalars['String'];
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type Chart = {
  __typename?: 'Chart';
  chartId: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  settings: Scalars['JSON'];
};

export type ChartInfoInput = {
  displayName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  settings: Scalars['JSON'];
};

export type ChartSet = {
  __typename?: 'ChartSet';
  charts: Array<Chart>;
  description: Scalars['String'];
  id: Scalars['Int'];
  isProjectSet: Scalars['Boolean'];
  name: Scalars['String'];
  timeRange: TimeFragment;
};

export type ChartSetInput = {
  charts: Array<ChartInfoInput>;
  description?: InputMaybe<Scalars['String']>;
  isProjectSet: Scalars['Boolean'];
  name: Scalars['String'];
  timeRange: TimeFragmentInput;
};

export type ContactList = {
  __typename?: 'ContactList';
  contacts: Array<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ContactListArgs = {
  contacts: Array<Scalars['String']>;
  name: Scalars['String'];
  projectId: Scalars['Int'];
};

export enum ContactType {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export type CreateUserResponse = {
  __typename?: 'CreateUserResponse';
  error?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
};

export type EquipmentAction = {
  __typename?: 'EquipmentAction';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type EquipmentActionInput = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type EquipmentNumberState = {
  __typename?: 'EquipmentNumberState';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type EquipmentSelectionState = {
  __typename?: 'EquipmentSelectionState';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  options: Array<EquipmentSelectionStateOption>;
};

export type EquipmentSelectionStateOption = {
  __typename?: 'EquipmentSelectionStateOption';
  color: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type EquipmentSelectionStateOptionInput = {
  color: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type EquipmentState = EquipmentNumberState | EquipmentSelectionState | EquipmentTextState;

export type EquipmentStateInput = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<EquipmentSelectionStateOptionInput>>;
  type: Scalars['String'];
};

export type EquipmentTextState = {
  __typename?: 'EquipmentTextState';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type EquipmentType = {
  __typename?: 'EquipmentType';
  actions: Array<EquipmentAction>;
  apiManaged: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  states: Array<EquipmentState>;
};

export type EquipmentTypeInput = {
  actions: Array<EquipmentActionInput>;
  apiManaged?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  states: Array<EquipmentStateInput>;
};

export type Event = {
  __typename?: 'Event';
  comments?: Maybe<Array<EventComment>>;
  created: Scalars['Date'];
  creator: Scalars['String'];
  detail?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['Date']>;
  eventType: Scalars['String'];
  hasNew?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  severity: Scalars['String'];
  siteId: Scalars['Int'];
  startTime: Scalars['Date'];
  subscribed: Scalars['Boolean'];
  trace?: Maybe<Scalars['String']>;
};

export type EventComment = {
  __typename?: 'EventComment';
  attachmentRefs?: Maybe<Array<Scalars['String']>>;
  comment: Scalars['String'];
  created: Scalars['Date'];
  creator: Scalars['String'];
  eventId: Scalars['Int'];
  id: Scalars['Int'];
};

export type EventInput = {
  created: Scalars['Date'];
  creator: Scalars['String'];
  detail?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['Date']>;
  eventType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  severity: Scalars['String'];
  siteId: Scalars['Int'];
  startTime?: InputMaybe<Scalars['Date']>;
  trace?: InputMaybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  encoding: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
};

export type HydrographyReport = {
  __typename?: 'HydrographyReport';
  formInput?: Maybe<Scalars['JSON']>;
  id: Scalars['Int'];
  lastUpdated: Scalars['Date'];
  reportTime: Scalars['Date'];
  reportedBy: Scalars['String'];
  siteId?: Maybe<Scalars['Int']>;
  transactionId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createSiteEquipmentEntry: Scalars['Boolean'];
  createUser: CreateUserResponse;
  deleteASCReports: Array<Scalars['Int']>;
  deleteAlarmGroup: Scalars['Boolean'];
  deleteChartSet: Scalars['Boolean'];
  deleteContactList: Scalars['Boolean'];
  deleteEquipmentType: Scalars['Boolean'];
  deleteEvent: Scalars['Boolean'];
  deleteHydrographyReports: Array<Scalars['Int']>;
  deletePlanktonReports: Array<Scalars['Int']>;
  deleteSeaLiceReports: Array<Scalars['Int']>;
  deleteSiteEquipment: Scalars['Boolean'];
  deleteUser?: Maybe<Scalars['Boolean']>;
  getS3PresignedURL: Scalars['String'];
  saveASCReport?: Maybe<AscReport>;
  saveAlarmGroup: Scalars['Boolean'];
  saveChartSet: Scalars['Int'];
  saveContactList: ContactList;
  saveEquipmentType: Scalars['Boolean'];
  saveEvent: Event;
  saveEventComment: Event;
  saveHydrographyReport?: Maybe<HydrographyReport>;
  savePlanktonReport?: Maybe<PlanktonReport>;
  saveSeaLiceReport?: Maybe<SeaLiceReport>;
  saveSiteEquipment: Scalars['Boolean'];
  saveUserAlarmPreferences: Scalars['Boolean'];
  sendTestEmail: Scalars['JSON'];
  setSiteAlarmSuspension: Scalars['Boolean'];
  submitDeviceValues: Scalars['JSON'];
  updateNotificationReceipt: Scalars['Boolean'];
  updateUser: User;
};


export type MutationCreateSiteEquipmentEntryArgs = {
  equipmentUpdate: SiteEquipmentEntryInput;
};


export type MutationCreateUserArgs = {
  email: Scalars['String'];
  projectId: Scalars['Int'];
};


export type MutationDeleteAscReportsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteAlarmGroupArgs = {
  alarmGroupId: Scalars['Int'];
};


export type MutationDeleteChartSetArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteContactListArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteEquipmentTypeArgs = {
  equipmentTypeId: Scalars['Int'];
};


export type MutationDeleteEventArgs = {
  eventId: Scalars['Int'];
};


export type MutationDeleteHydrographyReportsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeletePlanktonReportsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteSeaLiceReportsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteSiteEquipmentArgs = {
  siteEquipmentId: Scalars['Int'];
};


export type MutationDeleteUserArgs = {
  projectId: Scalars['Int'];
  userId: Scalars['String'];
};


export type MutationGetS3PresignedUrlArgs = {
  bucket?: InputMaybe<Scalars['String']>;
  contentType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  operation: Scalars['String'];
};


export type MutationSaveAscReportArgs = {
  formInput: Scalars['JSON'];
  id?: InputMaybe<Scalars['Int']>;
  lastUpdated?: InputMaybe<Scalars['Date']>;
  reportTime: Scalars['Date'];
  reportedBy: Scalars['String'];
  siteId: Scalars['Int'];
  transactionId: Scalars['String'];
};


export type MutationSaveAlarmGroupArgs = {
  alarmGroup: AlarmGroupInput;
  projectId: Scalars['Int'];
  siteAlarms: Array<SiteAlarmInput>;
};


export type MutationSaveChartSetArgs = {
  chartSet: ChartSetInput;
  id?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['Int'];
};


export type MutationSaveContactListArgs = {
  contactListArgs: ContactListArgs;
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationSaveEquipmentTypeArgs = {
  equipmentType: EquipmentTypeInput;
  projectId: Scalars['Int'];
};


export type MutationSaveEventArgs = {
  event: EventInput;
  notify?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationSaveEventCommentArgs = {
  attachmentRefs?: InputMaybe<Array<Scalars['String']>>;
  comment: Scalars['String'];
  eventId: Scalars['Int'];
  notify?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationSaveHydrographyReportArgs = {
  formInput: Scalars['JSON'];
  id?: InputMaybe<Scalars['Int']>;
  lastUpdated?: InputMaybe<Scalars['Date']>;
  reportTime: Scalars['Date'];
  reportedBy: Scalars['String'];
  siteId: Scalars['Int'];
  transactionId: Scalars['String'];
};


export type MutationSavePlanktonReportArgs = {
  formInput: Scalars['JSON'];
  id?: InputMaybe<Scalars['Int']>;
  lastUpdated?: InputMaybe<Scalars['Date']>;
  reportTime: Scalars['Date'];
  reportedBy: Scalars['String'];
  siteId: Scalars['Int'];
  sublocation?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
};


export type MutationSaveSeaLiceReportArgs = {
  formInput: Scalars['JSON'];
  id?: InputMaybe<Scalars['Int']>;
  lastUpdated: Scalars['Date'];
  reportTime: Scalars['Date'];
  reportedBy: Scalars['String'];
  siteId: Scalars['Int'];
  sublocation?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
};


export type MutationSaveSiteEquipmentArgs = {
  siteEquipment: SiteEquipmentInput;
};


export type MutationSaveUserAlarmPreferencesArgs = {
  siteIds: Array<Scalars['Int']>;
};


export type MutationSendTestEmailArgs = {
  emails: Array<Scalars['String']>;
};


export type MutationSetSiteAlarmSuspensionArgs = {
  siteAlarmId: Scalars['Int'];
  suspended: Scalars['Boolean'];
};


export type MutationSubmitDeviceValuesArgs = {
  action: Scalars['String'];
  tableName: Scalars['String'];
  values: Scalars['String'];
};


export type MutationUpdateNotificationReceiptArgs = {
  messageId: Scalars['String'];
  update: NotificationReceiptInput;
};


export type MutationUpdateUserArgs = {
  attributes: UserInput;
  projectId?: InputMaybe<Scalars['Int']>;
  userId: Scalars['String'];
};

export type NotificationEvent = {
  __typename?: 'NotificationEvent';
  notifiedAt: Scalars['Date'];
  receipts: Array<AlarmNotificationReceipt>;
};

export type NotificationReceiptInput = {
  destination: Array<Scalars['String']>;
  status: Scalars['String'];
  timestamp: Scalars['Date'];
  type: Scalars['String'];
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderInput = {
  field: Scalars['String'];
  order: OrderDirection;
};

export type Organization = {
  __typename?: 'Organization';
  active: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  projects?: Maybe<Array<Project>>;
  users?: Maybe<Array<User>>;
};

export enum PlanktonCategory {
  Jelly = 'JELLY',
  JellyGenus = 'JELLY_GENUS',
  JellySpecies = 'JELLY_SPECIES',
  Plankton = 'PLANKTON',
  PlanktonGenus = 'PLANKTON_GENUS',
  PlanktonSpecies = 'PLANKTON_SPECIES'
}

export type PlanktonGeneric = {
  __typename?: 'PlanktonGeneric';
  category: PlanktonCategory;
  id: Scalars['Int'];
  imageUrl: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
};

export type PlanktonPolicy = {
  __typename?: 'PlanktonPolicy';
  caution: Scalars['Int'];
  danger: Scalars['Int'];
  generic?: Maybe<PlanktonGeneric>;
  species?: Maybe<PlanktonSpecies>;
};

export type PlanktonReport = {
  __typename?: 'PlanktonReport';
  formInput?: Maybe<Scalars['JSON']>;
  id: Scalars['Int'];
  lastUpdated: Scalars['Date'];
  reportTime: Scalars['Date'];
  reportedBy: Scalars['String'];
  siteId?: Maybe<Scalars['Int']>;
  sublocation?: Maybe<Scalars['String']>;
  transactionId: Scalars['String'];
};

export type PlanktonSpecies = {
  __typename?: 'PlanktonSpecies';
  category: PlanktonCategory;
  id: Scalars['Int'];
  imageUrl: Scalars['String'];
  key: Scalars['String'];
  species: Scalars['String'];
};

export type Project = {
  __typename?: 'Project';
  active: Scalars['Boolean'];
  admins: Array<User>;
  altBargeHydrography: Scalars['Boolean'];
  contactLists: Array<ContactList>;
  dataEntry?: Maybe<Scalars['JSON']>;
  demo: Scalars['Boolean'];
  forecasting: Scalars['Boolean'];
  freeTrial: Scalars['Boolean'];
  hiresParams: Array<Scalars['String']>;
  id: Scalars['Int'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  mapLayers: Array<Scalars['String']>;
  mapParamSuffix: Scalars['String'];
  name: Scalars['String'];
  organization: Organization;
  organizationId: Scalars['Int'];
  planktonPolicy: Array<PlanktonPolicy>;
  region: Region;
  sites: Array<Site>;
  siwi: Scalars['Boolean'];
  tabs: Array<Scalars['String']>;
  thresholds: Scalars['JSON'];
  timezone: Scalars['String'];
  users: Array<User>;
  usership: Array<Usership>;
  visits: Array<VisitInfo>;
  zoomlevel?: Maybe<Scalars['Float']>;
};


export type ProjectContactListsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']>>;
};


export type ProjectSitesArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']>;
};


export type ProjectUsersArgs = {
  search?: InputMaybe<Scalars['String']>;
};


export type ProjectUsershipArgs = {
  end?: InputMaybe<Scalars['Date']>;
  start: Scalars['Date'];
  userId?: InputMaybe<Scalars['String']>;
};


export type ProjectVisitsArgs = {
  end?: InputMaybe<Scalars['Date']>;
  start: Scalars['Date'];
};

export type Query = {
  __typename?: 'Query';
  alarmChecksSummary: Array<AlarmChecksCount>;
  alarmGroups: Array<AlarmGroup>;
  alarmStatusCheckRuns: Array<AlarmStatusCheckRun>;
  apiKeys: Array<ApiKey>;
  chartSets: Array<ChartSet>;
  currentUser?: Maybe<User>;
  equipmentTypes: Array<EquipmentType>;
  getASCReports: Array<AscReport>;
  getEventNotificationEmails: Array<Scalars['String']>;
  getEvents?: Maybe<Array<Event>>;
  getHydrographyReports?: Maybe<Array<HydrographyReport>>;
  getPlanktonReports?: Maybe<Array<PlanktonReport>>;
  getSeaLiceReports?: Maybe<Array<SeaLiceReport>>;
  lastAlarmStatus: Array<AlarmStatusCheckRun>;
  project?: Maybe<Project>;
  projects?: Maybe<Array<Project>>;
  releaseNotes: Array<ReleaseNote>;
  site?: Maybe<Site>;
  siteAlarm: SiteAlarm;
  siteAlarms: Array<SiteAlarm>;
  sites: Array<Site>;
};


export type QueryAlarmChecksSummaryArgs = {
  endTime: Scalars['Date'];
  projectId: Scalars['Int'];
  startTime: Scalars['Date'];
};


export type QueryAlarmGroupsArgs = {
  projectId: Scalars['Int'];
};


export type QueryAlarmStatusCheckRunsArgs = {
  currentStatus?: InputMaybe<AlarmState>;
  projectId?: InputMaybe<Scalars['Int']>;
  siteIds?: InputMaybe<Array<Scalars['Int']>>;
  uiFilter?: InputMaybe<Scalars['Boolean']>;
};


export type QueryChartSetsArgs = {
  isProjectSet?: InputMaybe<Scalars['Boolean']>;
  projectId: Scalars['Int'];
};


export type QueryEquipmentTypesArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  includeApiManaged?: InputMaybe<Scalars['Boolean']>;
  projectId: Scalars['Int'];
};


export type QueryGetAscReportsArgs = {
  siteId: Scalars['Int'];
};


export type QueryGetEventNotificationEmailsArgs = {
  projectId: Scalars['Int'];
};


export type QueryGetEventsArgs = {
  endTime?: InputMaybe<Scalars['Date']>;
  siteId: Scalars['Int'];
  startTime?: InputMaybe<Scalars['Date']>;
};


export type QueryGetHydrographyReportsArgs = {
  siteId: Scalars['Int'];
};


export type QueryGetPlanktonReportsArgs = {
  siteId: Scalars['Int'];
};


export type QueryGetSeaLiceReportsArgs = {
  siteId: Scalars['Int'];
};


export type QueryLastAlarmStatusArgs = {
  siteAlarmIds: Array<Scalars['Int']>;
};


export type QueryProjectArgs = {
  id: Scalars['Int'];
};


export type QuerySiteArgs = {
  id: Scalars['Int'];
};


export type QuerySiteAlarmArgs = {
  id: Scalars['Int'];
};


export type QuerySiteAlarmsArgs = {
  alarmGroupId?: InputMaybe<Scalars['Int']>;
  siteId?: InputMaybe<Scalars['Int']>;
};


export type QuerySitesArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<OrderInput>;
  projectId: Scalars['Int'];
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['Int'];
  name: Scalars['String'];
  stations: Array<Station>;
};

export type ReleaseNote = {
  __typename?: 'ReleaseNote';
  content: Scalars['String'];
  version: Scalars['String'];
};

export type SeaLiceReport = {
  __typename?: 'SeaLiceReport';
  formInput?: Maybe<Scalars['JSON']>;
  id: Scalars['Int'];
  lastUpdated: Scalars['Date'];
  reportTime: Scalars['Date'];
  reportedBy: Scalars['String'];
  siteId?: Maybe<Scalars['Int']>;
  sublocation?: Maybe<Scalars['String']>;
  transactionId: Scalars['String'];
};

export type Site = {
  __typename?: 'Site';
  alarms: Array<SiteAlarm>;
  archived: Scalars['Boolean'];
  equipment: Array<SiteEquipment>;
  hasGdb?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  projectId: Scalars['Int'];
  region?: Maybe<Scalars['String']>;
  riverStations: Scalars['JSON'];
  sampleLocations: Array<Scalars['String']>;
  siteLabel?: Maybe<Scalars['String']>;
  smbId: Scalars['Int'];
  sublocations?: Maybe<Array<Scalars['String']>>;
  tabs?: Maybe<Array<Scalars['String']>>;
  tideStations: Scalars['JSON'];
  type: Scalars['String'];
};

export type SiteAlarm = {
  __typename?: 'SiteAlarm';
  alarmGroup: AlarmGroup;
  alarmGroupId: Scalars['Int'];
  contactLists: Array<ContactList>;
  id: Scalars['Int'];
  lastAlert?: Maybe<AlarmStatusCheckRun>;
  lastCheck?: Maybe<AlarmStatusCheckRun>;
  notify: Array<AlarmNotifier>;
  pastNotifications: Array<NotificationEvent>;
  site: Site;
  siteId: Scalars['Int'];
  suspended: Scalars['Boolean'];
};


export type SiteAlarmLastAlertArgs = {
  startTime?: InputMaybe<Scalars['String']>;
};


export type SiteAlarmPastNotificationsArgs = {
  endTime: Scalars['Date'];
  startTime: Scalars['Date'];
};

export type SiteAlarmInput = {
  contactListIds: Array<Scalars['Int']>;
  notify: Array<AlarmNotifierInput>;
  siteId: Scalars['Int'];
};

export type SiteEquipment = {
  __typename?: 'SiteEquipment';
  deleted: Scalars['Boolean'];
  details: Scalars['String'];
  equipmentType: EquipmentType;
  id: Scalars['Int'];
  name: Scalars['String'];
  site: Site;
  sublocation?: Maybe<Scalars['String']>;
};

export type SiteEquipmentEntryInput = {
  actionId?: InputMaybe<Scalars['Int']>;
  siteEquipmentId: Scalars['Int'];
  stateUpdates?: InputMaybe<Array<SiteEquipmentEntryStateUpdateInput>>;
  updateTime?: InputMaybe<Scalars['String']>;
};

export type SiteEquipmentEntryStateUpdateInput = {
  equipmentStateId: Scalars['Int'];
  value: Scalars['String'];
};

export type SiteEquipmentInput = {
  details?: InputMaybe<Scalars['String']>;
  equipmentTypeId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  siteId: Scalars['Int'];
  sublocation?: InputMaybe<Scalars['String']>;
};

export type Station = {
  __typename?: 'Station';
  id: Scalars['Int'];
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  name: Scalars['String'];
  owner: Scalars['String'];
  stationKey: Scalars['String'];
  type: StationType;
};

export enum StationType {
  River = 'RIVER',
  Tide = 'TIDE'
}

export type TimeFragment = {
  __typename?: 'TimeFragment';
  dateRange?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  granularity?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
};

export type TimeFragmentInput = {
  dateRange?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['String']>;
  granularity?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['String']>;
};

export enum UnknownAlarmIntervalSetting {
  Continue = 'CONTINUE',
  Ok = 'OK',
  Unknown = 'UNKNOWN'
}

export type User = {
  __typename?: 'User';
  alarmSites: Array<Scalars['Int']>;
  alerts: Array<Scalars['Int']>;
  asc: Array<Scalars['Int']>;
  audioNotifications: Scalars['Boolean'];
  avatarUrl?: Maybe<Scalars['String']>;
  creationTime: Scalars['String'];
  email: Scalars['String'];
  equipment: Array<Scalars['Int']>;
  events: Array<Scalars['Int']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastActivity?: Maybe<Scalars['Date']>;
  lastVersion: Scalars['String'];
  manualHydrography: Array<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  organizations: Array<Organization>;
  oryId: Scalars['String'];
  plankton: Array<Scalars['Int']>;
  projectAdministrator: Array<Scalars['Int']>;
  projects: Array<Project>;
  read: Array<Scalars['Int']>;
  seaLice: Array<Scalars['Int']>;
  superuser: Scalars['Boolean'];
};

export type UserInput = {
  alarmSites?: InputMaybe<Array<Scalars['Int']>>;
  alerts?: InputMaybe<Array<Scalars['Int']>>;
  asc?: InputMaybe<Array<Scalars['Int']>>;
  audioNotifications?: InputMaybe<Scalars['Boolean']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  equipment?: InputMaybe<Array<Scalars['Int']>>;
  events?: InputMaybe<Array<Scalars['Int']>>;
  jobTitle?: InputMaybe<Scalars['String']>;
  lastVersion?: InputMaybe<Scalars['String']>;
  manualHydrography?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  organizations?: InputMaybe<Array<Scalars['Int']>>;
  password?: InputMaybe<Scalars['String']>;
  plankton?: InputMaybe<Array<Scalars['Int']>>;
  projectAdministrator?: InputMaybe<Array<Scalars['Int']>>;
  projects?: InputMaybe<Array<Scalars['Int']>>;
  read?: InputMaybe<Array<Scalars['Int']>>;
  seaLice?: InputMaybe<Array<Scalars['Int']>>;
  superuser?: InputMaybe<Scalars['Boolean']>;
};

export type Usership = {
  __typename?: 'Usership';
  count: Scalars['Int'];
  datetime: Scalars['Date'];
};

export type VisitInfo = {
  __typename?: 'VisitInfo';
  count: Scalars['Int'];
  path: Scalars['String'];
};

export type UpdateUserMutationVariables = Exact<{
  userId: Scalars['String'];
  projectId?: InputMaybe<Scalars['Int']>;
  attributes: UserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', oryId: string, name?: string | null, jobTitle?: string | null, avatarUrl?: string | null, email: string, superuser: boolean, creationTime: string, lastActivity?: any | null, projectAdministrator: Array<number>, alerts: Array<number>, asc: Array<number>, manualHydrography: Array<number>, read: Array<number>, events: Array<number>, equipment: Array<number>, plankton: Array<number> } };

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['String'];
  projectId: Scalars['Int'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: boolean | null };

export type OrderedProjectSitesQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type OrderedProjectSitesQuery = { __typename?: 'Query', sites: Array<{ __typename?: 'Site', id: number, name: string, siteLabel?: string | null, tabs?: Array<string> | null }> };

export type CreateUserMutationVariables = Exact<{
  email: Scalars['String'];
  projectId: Scalars['Int'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'CreateUserResponse', error?: string | null, updated?: boolean | null, user?: { __typename?: 'User', oryId: string, name?: string | null, jobTitle?: string | null, avatarUrl?: string | null, email: string, superuser: boolean, creationTime: string, projectAdministrator: Array<number>, alerts: Array<number>, asc: Array<number>, manualHydrography: Array<number>, read: Array<number>, events: Array<number>, equipment: Array<number>, plankton: Array<number>, seaLice: Array<number> } | null } };

export type ProjectViewingQueryVariables = Exact<{
  projectId: Scalars['Int'];
  after: Scalars['Date'];
}>;


export type ProjectViewingQuery = { __typename?: 'Query', project?: { __typename?: 'Project', id: number, visits: Array<{ __typename?: 'VisitInfo', path: string, count: number }>, usership: Array<{ __typename?: 'Usership', datetime: any, count: number }> } | null };

export type GetSiteAlarmDetailsQueryVariables = Exact<{
  alarmGroupId: Scalars['Int'];
}>;


export type GetSiteAlarmDetailsQuery = { __typename?: 'Query', siteAlarms: Array<{ __typename?: 'SiteAlarm', id: number, lastCheck?: { __typename?: 'AlarmStatusCheckRun', id: string, state: AlarmState, checkedAt: any, errorMessage?: string | null } | null, lastAlert?: { __typename?: 'AlarmStatusCheckRun', id: string, checkedAt: any } | null }> };

export type ContactListsQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type ContactListsQuery = { __typename?: 'Query', project?: { __typename?: 'Project', id: number, contactLists: Array<{ __typename?: 'ContactList', id: number, name: string, contacts: Array<string> }> } | null };

export type GetNotificationsSummaryQueryVariables = Exact<{
  projectId: Scalars['Int'];
  startTime: Scalars['Date'];
  endTime: Scalars['Date'];
}>;


export type GetNotificationsSummaryQuery = { __typename?: 'Query', sites: Array<{ __typename?: 'Site', id: number, alarms: Array<{ __typename?: 'SiteAlarm', id: number, pastNotifications: Array<{ __typename?: 'NotificationEvent', notifiedAt: any }> }> }> };

export type GetAlarmChecksSummaryQueryVariables = Exact<{
  projectId: Scalars['Int'];
  startTime: Scalars['Date'];
  endTime: Scalars['Date'];
}>;


export type GetAlarmChecksSummaryQuery = { __typename?: 'Query', alarmChecksSummary: Array<{ __typename?: 'AlarmChecksCount', state: AlarmState, count: number }> };

export type ProjectSitesQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type ProjectSitesQuery = { __typename?: 'Query', sites: Array<{ __typename?: 'Site', id: number, name: string, sublocations?: Array<string> | null, smbId: number, siteLabel?: string | null }> };

export type SaveAlarmGroupMutationVariables = Exact<{
  projectId: Scalars['Int'];
  alarmGroup: AlarmGroupInput;
  siteAlarms: Array<SiteAlarmInput> | SiteAlarmInput;
}>;


export type SaveAlarmGroupMutation = { __typename?: 'Mutation', saveAlarmGroup: boolean };

export type GetRecentProjectAlertsQueryVariables = Exact<{
  projectId: Scalars['Int'];
  startTime: Scalars['String'];
}>;


export type GetRecentProjectAlertsQuery = { __typename?: 'Query', alarmGroups: Array<{ __typename?: 'AlarmGroup', id: number, name: string, siteAlarms: Array<{ __typename?: 'SiteAlarm', id: number, site: { __typename?: 'Site', id: number, name: string }, lastAlert?: { __typename?: 'AlarmStatusCheckRun', id: string, checkedAt: any, alarmGroup: { __typename?: 'AlarmGroup', id: number, name: string } } | null }> }> };

export type TestEmailMutationVariables = Exact<{
  emails: Array<Scalars['String']> | Scalars['String'];
}>;


export type TestEmailMutation = { __typename?: 'Mutation', sendTestEmail: any };

export type GetChecksQueryVariables = Exact<{
  id: Scalars['Int'];
  siteId: Scalars['Int'];
  startTime: Scalars['Date'];
  endTime: Scalars['Date'];
}>;


export type GetChecksQuery = { __typename?: 'Query', siteAlarm: { __typename?: 'SiteAlarm', id: number, site: { __typename?: 'Site', name: string }, pastNotifications: Array<{ __typename?: 'NotificationEvent', notifiedAt: any, receipts: Array<{ __typename?: 'AlarmNotificationReceipt', destination: string, type: ContactType, status: string }> }>, alarmGroup: { __typename?: 'AlarmGroup', id: number, name: string, checkRuns: Array<{ __typename?: 'AlarmStatusCheckRun', id: string, state: AlarmState, checkedAt: any }> } } };

export type GetEquipmentTypesForAssignmentQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type GetEquipmentTypesForAssignmentQuery = { __typename?: 'Query', equipmentTypes: Array<{ __typename?: 'EquipmentType', id: number, name: string }> };

export type GetSitesForEquipmentAssignmentQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type GetSitesForEquipmentAssignmentQuery = { __typename?: 'Query', sites: Array<{ __typename?: 'Site', id: number, name: string, sublocations?: Array<string> | null }> };

export type SaveSiteEquipmentMutationVariables = Exact<{
  siteEquipment: SiteEquipmentInput;
}>;


export type SaveSiteEquipmentMutation = { __typename?: 'Mutation', saveSiteEquipment: boolean };

export type CreateEquipmentEntryMutationVariables = Exact<{
  equipmentUpdate: SiteEquipmentEntryInput;
}>;


export type CreateEquipmentEntryMutation = { __typename?: 'Mutation', createSiteEquipmentEntry: boolean };

export type ProjectUsersByNameQueryVariables = Exact<{
  projectId: Scalars['Int'];
  name: Scalars['String'];
}>;


export type ProjectUsersByNameQuery = { __typename?: 'Query', project?: { __typename?: 'Project', users: Array<{ __typename?: 'User', oryId: string, email: string, name?: string | null }> } | null };

export type AknowledgeVersionMutationVariables = Exact<{
  userId: Scalars['String'];
  version: Scalars['String'];
}>;


export type AknowledgeVersionMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', oryId: string, lastVersion: string } };

export type ReleaseNotesQueryVariables = Exact<{ [key: string]: never; }>;


export type ReleaseNotesQuery = { __typename?: 'Query', releaseNotes: Array<{ __typename?: 'ReleaseNote', version: string, content: string }> };

export type GetUserActivityQueryVariables = Exact<{
  projectId: Scalars['Int'];
  userId: Scalars['String'];
  after: Scalars['Date'];
}>;


export type GetUserActivityQuery = { __typename?: 'Query', project?: { __typename?: 'Project', usership: Array<{ __typename?: 'Usership', datetime: any, count: number }> } | null };

export type GetAscReportsQueryVariables = Exact<{
  siteId: Scalars['Int'];
}>;


export type GetAscReportsQuery = { __typename?: 'Query', reports: Array<{ __typename?: 'ASCReport', id: number, reportTime: any, lastUpdated: any, reportedBy: string, siteId?: number | null, formInput?: any | null, transactionId: string }> };

export type SaveAscReportMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  reportTime: Scalars['Date'];
  lastUpdated: Scalars['Date'];
  reportedBy: Scalars['String'];
  siteId: Scalars['Int'];
  formInput: Scalars['JSON'];
  transactionId: Scalars['String'];
}>;


export type SaveAscReportMutation = { __typename?: 'Mutation', saveASCReport?: { __typename?: 'ASCReport', id: number, reportTime: any, lastUpdated: any, reportedBy: string, siteId?: number | null, formInput?: any | null, transactionId: string } | null };

export type DeleteAscReportsMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteAscReportsMutation = { __typename?: 'Mutation', deleteASCReports: Array<number> };

export type GetSiteAlarmsQueryVariables = Exact<{
  siteId: Scalars['Int'];
  startTime: Scalars['Date'];
  endTime: Scalars['Date'];
}>;


export type GetSiteAlarmsQuery = { __typename?: 'Query', siteAlarms: Array<{ __typename?: 'SiteAlarm', id: number, suspended: boolean, site: { __typename?: 'Site', id: number, name: string }, lastAlert?: { __typename?: 'AlarmStatusCheckRun', id: string, checkedAt: any } | null, lastCheck?: { __typename?: 'AlarmStatusCheckRun', id: string, state: AlarmState, checkedAt: any } | null, alarmGroup: { __typename?: 'AlarmGroup', id: number, name: string, checkRuns: Array<{ __typename?: 'AlarmStatusCheckRun', id: string, state: AlarmState, checkedAt: any }> }, pastNotifications: Array<{ __typename?: 'NotificationEvent', notifiedAt: any }> }> };

export type GetEquipmentOnSiteQueryVariables = Exact<{
  siteId: Scalars['Int'];
}>;


export type GetEquipmentOnSiteQuery = { __typename?: 'Query', site?: { __typename?: 'Site', id: number, equipment: Array<{ __typename?: 'SiteEquipment', id: number, name: string, details: string, sublocation?: string | null, deleted: boolean, equipmentType: { __typename?: 'EquipmentType', id: number, name: string, apiManaged: boolean, states: Array<{ __typename?: 'EquipmentNumberState', id: number, name: string, description: string } | { __typename?: 'EquipmentSelectionState', id: number, name: string, description: string, options: Array<{ __typename?: 'EquipmentSelectionStateOption', id: number, name: string, color: string }> } | { __typename?: 'EquipmentTextState', id: number, name: string, description: string }>, actions: Array<{ __typename?: 'EquipmentAction', id: number, name: string, description: string }> } }> } | null };

export type SaveEventCommentMutationVariables = Exact<{
  eventId: Scalars['Int'];
  comment: Scalars['String'];
  attachmentRefs?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  notify?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SaveEventCommentMutation = { __typename?: 'Mutation', saveEventComment: { __typename?: 'Event', id: number, eventType: string, severity: string, creator: string, created: any, siteId: number, startTime: any, endTime?: any | null, detail?: string | null, subscribed: boolean, hasNew?: boolean | null, comments?: Array<{ __typename?: 'EventComment', id: number, eventId: number, creator: string, comment: string, created: any, attachmentRefs?: Array<string> | null }> | null } };

export type SaveEventMutationVariables = Exact<{
  event: EventInput;
  notify?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SaveEventMutation = { __typename?: 'Mutation', saveEvent: { __typename?: 'Event', id: number, eventType: string, severity: string, creator: string, created: any, siteId: number, startTime: any, endTime?: any | null, trace?: string | null, detail?: string | null, subscribed: boolean, hasNew?: boolean | null, comments?: Array<{ __typename?: 'EventComment', id: number, eventId: number, creator: string, comment: string, created: any, attachmentRefs?: Array<string> | null }> | null } };

export type DeleteEventMutationVariables = Exact<{
  eventId: Scalars['Int'];
}>;


export type DeleteEventMutation = { __typename?: 'Mutation', deleteEvent: boolean };

export type ChartEventsQueryVariables = Exact<{
  siteId: Scalars['Int'];
  startTime?: InputMaybe<Scalars['Date']>;
  endTime?: InputMaybe<Scalars['Date']>;
}>;


export type ChartEventsQuery = { __typename?: 'Query', getEvents?: Array<{ __typename?: 'Event', id: number, eventType: string, startTime: any, endTime?: any | null }> | null };

export type DeleteChartSetMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteChartSetMutation = { __typename?: 'Mutation', deleteChartSet: boolean };

export type GetChartSetsQueryVariables = Exact<{
  projectId: Scalars['Int'];
  isProjectSet: Scalars['Boolean'];
}>;


export type GetChartSetsQuery = { __typename?: 'Query', chartSets: Array<{ __typename?: 'ChartSet', id: number, name: string, description: string, isProjectSet: boolean, timeRange: { __typename?: 'TimeFragment', startTime?: string | null, endTime?: string | null, granularity?: string | null, dateRange?: string | null }, charts: Array<{ __typename?: 'Chart', id: number, chartId: string, settings: any, displayName?: string | null }> }> };

export type SaveChartSetMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['Int'];
  chartSet: ChartSetInput;
}>;


export type SaveChartSetMutation = { __typename?: 'Mutation', saveChartSet: number };

export type SaveHydrographyReportMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  reportTime: Scalars['Date'];
  lastUpdated: Scalars['Date'];
  reportedBy: Scalars['String'];
  siteId: Scalars['Int'];
  formInput: Scalars['JSON'];
  transactionId: Scalars['String'];
}>;


export type SaveHydrographyReportMutation = { __typename?: 'Mutation', saveHydrographyReport?: { __typename?: 'HydrographyReport', id: number, reportTime: any, lastUpdated: any, reportedBy: string, siteId?: number | null, formInput?: any | null, transactionId: string } | null };

export type DeleteHydrographyReportsMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteHydrographyReportsMutation = { __typename?: 'Mutation', deleteHydrographyReports: Array<number> };

export type GetHydrographyReportsQueryVariables = Exact<{
  siteId: Scalars['Int'];
}>;


export type GetHydrographyReportsQuery = { __typename?: 'Query', reports?: Array<{ __typename?: 'HydrographyReport', id: number, reportTime: any, lastUpdated: any, reportedBy: string, siteId?: number | null, formInput?: any | null, transactionId: string }> | null };

export type SavePlanktonReportMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  reportTime: Scalars['Date'];
  lastUpdated: Scalars['Date'];
  reportedBy: Scalars['String'];
  siteId: Scalars['Int'];
  sublocation?: InputMaybe<Scalars['String']>;
  formInput: Scalars['JSON'];
  transactionId: Scalars['String'];
}>;


export type SavePlanktonReportMutation = { __typename?: 'Mutation', savePlanktonReport?: { __typename?: 'PlanktonReport', id: number, reportTime: any, lastUpdated: any, reportedBy: string, siteId?: number | null, sublocation?: string | null, formInput?: any | null, transactionId: string } | null };

export type DeletePlanktonReportsMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeletePlanktonReportsMutation = { __typename?: 'Mutation', deletePlanktonReports: Array<number> };

export type GetPlanktonReportsQueryVariables = Exact<{
  siteId: Scalars['Int'];
}>;


export type GetPlanktonReportsQuery = { __typename?: 'Query', reports?: Array<{ __typename?: 'PlanktonReport', id: number, reportTime: any, lastUpdated: any, reportedBy: string, siteId?: number | null, sublocation?: string | null, formInput?: any | null, transactionId: string }> | null };

export type DeleteSeaLiceReportsMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteSeaLiceReportsMutation = { __typename?: 'Mutation', deleteSeaLiceReports: Array<number> };

export type SaveSeaLiceReportMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  reportTime: Scalars['Date'];
  lastUpdated: Scalars['Date'];
  reportedBy: Scalars['String'];
  siteId: Scalars['Int'];
  sublocation?: InputMaybe<Scalars['String']>;
  formInput: Scalars['JSON'];
  transactionId: Scalars['String'];
}>;


export type SaveSeaLiceReportMutation = { __typename?: 'Mutation', saveSeaLiceReport?: { __typename?: 'SeaLiceReport', id: number, reportTime: any, lastUpdated: any, reportedBy: string, siteId?: number | null, sublocation?: string | null, formInput?: any | null, transactionId: string } | null };

export type GetSeaLiceReportsQueryVariables = Exact<{
  siteId: Scalars['Int'];
}>;


export type GetSeaLiceReportsQuery = { __typename?: 'Query', reports?: Array<{ __typename?: 'SeaLiceReport', id: number, reportTime: any, lastUpdated: any, reportedBy: string, siteId?: number | null, sublocation?: string | null, formInput?: any | null, transactionId: string }> | null };

export type GetProjectQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetProjectQuery = { __typename?: 'Query', project?: { __typename?: 'Project', id: number, name: string, active: boolean, latitude?: number | null, longitude?: number | null, zoomlevel?: number | null, forecasting: boolean, siwi: boolean, hiresParams: Array<string>, dataEntry?: any | null, thresholds: any, timezone: string, tabs: Array<string>, demo: boolean, freeTrial: boolean, mapLayers: Array<string>, mapParamSuffix: string, altBargeHydrography: boolean, region: { __typename?: 'Region', id: number, stations: Array<{ __typename?: 'Station', id: number, name: string, lat: number, lon: number, stationKey: string, type: StationType, owner: string }> }, organization: { __typename?: 'Organization', id: number, name: string }, sites: Array<{ __typename?: 'Site', id: number, smbId: number, name: string, type: string }>, planktonPolicy: Array<{ __typename?: 'PlanktonPolicy', caution: number, danger: number, species?: { __typename?: 'PlanktonSpecies', id: number, key: string, species: string, category: PlanktonCategory, imageUrl: string } | null, generic?: { __typename?: 'PlanktonGeneric', id: number, key: string, name: string, category: PlanktonCategory, imageUrl: string } | null }> } | null };

export type GetEventsQueryVariables = Exact<{
  siteId: Scalars['Int'];
  startTime?: InputMaybe<Scalars['Date']>;
  endTime?: InputMaybe<Scalars['Date']>;
}>;


export type GetEventsQuery = { __typename?: 'Query', getEvents?: Array<{ __typename?: 'Event', id: number, eventType: string, severity: string, creator: string, created: any, siteId: number, startTime: any, endTime?: any | null, trace?: string | null, detail?: string | null, subscribed: boolean, hasNew?: boolean | null, comments?: Array<{ __typename?: 'EventComment', id: number, eventId: number, creator: string, comment: string, created: any, attachmentRefs?: Array<string> | null }> | null }> | null };

export type GetRecentAlarmsQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type GetRecentAlarmsQuery = { __typename?: 'Query', alarmStatusCheckRuns: Array<{ __typename?: 'AlarmStatusCheckRun', id: string, state: AlarmState, checkedAt: any, site: { __typename?: 'Site', id: number, name: string }, alarmGroup: { __typename?: 'AlarmGroup', id: number, name: string } }> };

export type GetDemoSitesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDemoSitesQuery = { __typename?: 'Query', sites: Array<{ __typename?: 'Site', id: number, name: string }> };

export type DeleteEquipmentTypeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteEquipmentTypeMutation = { __typename?: 'Mutation', deleteEquipmentType: boolean };

export type SaveEquipmentTypeMutationVariables = Exact<{
  projectId: Scalars['Int'];
  equipmentType: EquipmentTypeInput;
}>;


export type SaveEquipmentTypeMutation = { __typename?: 'Mutation', saveEquipmentType: boolean };

export type GetEquipmentTypesQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type GetEquipmentTypesQuery = { __typename?: 'Query', equipmentTypes: Array<{ __typename?: 'EquipmentType', id: number, name: string, apiManaged: boolean, states: Array<{ __typename?: 'EquipmentNumberState', id: number, name: string, description: string } | { __typename?: 'EquipmentSelectionState', id: number, name: string, description: string, options: Array<{ __typename?: 'EquipmentSelectionStateOption', id: number, name: string, color: string }> } | { __typename?: 'EquipmentTextState', id: number, name: string, description: string }>, actions: Array<{ __typename?: 'EquipmentAction', id: number, name: string, description: string }> }> };

export type GetEquipmentAssignmentsForProjectQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type GetEquipmentAssignmentsForProjectQuery = { __typename?: 'Query', sites: Array<{ __typename?: 'Site', id: number, name: string, equipment: Array<{ __typename?: 'SiteEquipment', id: number, name: string, details: string, sublocation?: string | null, equipmentType: { __typename?: 'EquipmentType', id: number, name: string, apiManaged: boolean } }> }> };

export type RemoveEquipmentFromSiteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveEquipmentFromSiteMutation = { __typename?: 'Mutation', deleteSiteEquipment: boolean };

export type ProjectUsersQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type ProjectUsersQuery = { __typename?: 'Query', project?: { __typename?: 'Project', users: Array<{ __typename?: 'User', oryId: string, name?: string | null, jobTitle?: string | null, avatarUrl?: string | null, email: string, superuser: boolean, creationTime: string, lastActivity?: any | null, projectAdministrator: Array<number>, alerts: Array<number>, asc: Array<number>, manualHydrography: Array<number>, read: Array<number>, events: Array<number>, equipment: Array<number>, plankton: Array<number>, seaLice: Array<number>, alarmSites: Array<number> }> } | null };

export type DeleteAlarmGroupMutationVariables = Exact<{
  alarmGroupId: Scalars['Int'];
}>;


export type DeleteAlarmGroupMutation = { __typename?: 'Mutation', deleteAlarmGroup: boolean };

export type GetAlarmGroupsQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type GetAlarmGroupsQuery = { __typename?: 'Query', alarmGroups: Array<{ __typename?: 'AlarmGroup', id: number, name: string, description: string, unknownIntervalSetting: UnknownAlarmIntervalSetting, thresholds: Array<{ __typename?: 'AlarmThreshold', id: number, measure: string, value: string, operator: string, timeDimension: string, filters?: any | null } | null>, frequency?: { __typename?: 'AlarmFrequency', interval: number, unit: AlarmFrequencyUnit } | null, siteAlarms: Array<{ __typename?: 'SiteAlarm', id: number, suspended: boolean, notify: Array<{ __typename?: 'AlarmNotifier', id: number, value: string, type: ContactType }>, contactLists: Array<{ __typename?: 'ContactList', id: number, contacts: Array<string> }>, site: { __typename?: 'Site', id: number, name: string, smbId: number, sublocations?: Array<string> | null } }> }> };

export type SetAlarmSuspensionMutationVariables = Exact<{
  siteAlarmId: Scalars['Int'];
  suspended: Scalars['Boolean'];
}>;


export type SetAlarmSuspensionMutation = { __typename?: 'Mutation', setSiteAlarmSuspension: boolean };

export type SaveContactListMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  contactList: ContactListArgs;
}>;


export type SaveContactListMutation = { __typename?: 'Mutation', saveContactList: { __typename?: 'ContactList', id: number, name: string, contacts: Array<string> } };

export type DeleteContactListMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteContactListMutation = { __typename?: 'Mutation', deleteContactList: boolean };


export const UpdateUserDocument = gql`
    mutation UpdateUser($userId: String!, $projectId: Int, $attributes: UserInput!) {
  updateUser(userId: $userId, projectId: $projectId, attributes: $attributes) {
    oryId
    name
    jobTitle
    avatarUrl
    email
    superuser
    creationTime
    lastActivity
    projectAdministrator
    alerts
    asc
    manualHydrography
    read
    events
    equipment
    plankton
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      projectId: // value for 'projectId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($userId: String!, $projectId: Int!) {
  deleteUser(userId: $userId, projectId: $projectId)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const OrderedProjectSitesDocument = gql`
    query OrderedProjectSites($projectId: Int!) {
  sites(
    projectId: $projectId
    order: {field: "name", order: ASC}
    includeArchived: true
  ) {
    id
    name
    siteLabel
    tabs
  }
}
    `;

/**
 * __useOrderedProjectSitesQuery__
 *
 * To run a query within a React component, call `useOrderedProjectSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderedProjectSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderedProjectSitesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useOrderedProjectSitesQuery(baseOptions: Apollo.QueryHookOptions<OrderedProjectSitesQuery, OrderedProjectSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderedProjectSitesQuery, OrderedProjectSitesQueryVariables>(OrderedProjectSitesDocument, options);
      }
export function useOrderedProjectSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderedProjectSitesQuery, OrderedProjectSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderedProjectSitesQuery, OrderedProjectSitesQueryVariables>(OrderedProjectSitesDocument, options);
        }
export type OrderedProjectSitesQueryHookResult = ReturnType<typeof useOrderedProjectSitesQuery>;
export type OrderedProjectSitesLazyQueryHookResult = ReturnType<typeof useOrderedProjectSitesLazyQuery>;
export type OrderedProjectSitesQueryResult = Apollo.QueryResult<OrderedProjectSitesQuery, OrderedProjectSitesQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($email: String!, $projectId: Int!) {
  createUser(email: $email, projectId: $projectId) {
    error
    updated
    user {
      oryId
      name
      jobTitle
      avatarUrl
      email
      superuser
      creationTime
      projectAdministrator
      alerts
      asc
      manualHydrography
      read
      events
      equipment
      plankton
      seaLice
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const ProjectViewingDocument = gql`
    query ProjectViewing($projectId: Int!, $after: Date!) {
  project(id: $projectId) {
    id
    visits(start: $after) {
      path
      count
    }
    usership(start: $after) {
      datetime
      count
    }
  }
}
    `;

/**
 * __useProjectViewingQuery__
 *
 * To run a query within a React component, call `useProjectViewingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectViewingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectViewingQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useProjectViewingQuery(baseOptions: Apollo.QueryHookOptions<ProjectViewingQuery, ProjectViewingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectViewingQuery, ProjectViewingQueryVariables>(ProjectViewingDocument, options);
      }
export function useProjectViewingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectViewingQuery, ProjectViewingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectViewingQuery, ProjectViewingQueryVariables>(ProjectViewingDocument, options);
        }
export type ProjectViewingQueryHookResult = ReturnType<typeof useProjectViewingQuery>;
export type ProjectViewingLazyQueryHookResult = ReturnType<typeof useProjectViewingLazyQuery>;
export type ProjectViewingQueryResult = Apollo.QueryResult<ProjectViewingQuery, ProjectViewingQueryVariables>;
export const GetSiteAlarmDetailsDocument = gql`
    query GetSiteAlarmDetails($alarmGroupId: Int!) {
  siteAlarms(alarmGroupId: $alarmGroupId) {
    id
    lastCheck {
      id
      state
      checkedAt
      errorMessage
    }
    lastAlert {
      id
      checkedAt
    }
  }
}
    `;

/**
 * __useGetSiteAlarmDetailsQuery__
 *
 * To run a query within a React component, call `useGetSiteAlarmDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteAlarmDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteAlarmDetailsQuery({
 *   variables: {
 *      alarmGroupId: // value for 'alarmGroupId'
 *   },
 * });
 */
export function useGetSiteAlarmDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetSiteAlarmDetailsQuery, GetSiteAlarmDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteAlarmDetailsQuery, GetSiteAlarmDetailsQueryVariables>(GetSiteAlarmDetailsDocument, options);
      }
export function useGetSiteAlarmDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteAlarmDetailsQuery, GetSiteAlarmDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteAlarmDetailsQuery, GetSiteAlarmDetailsQueryVariables>(GetSiteAlarmDetailsDocument, options);
        }
export type GetSiteAlarmDetailsQueryHookResult = ReturnType<typeof useGetSiteAlarmDetailsQuery>;
export type GetSiteAlarmDetailsLazyQueryHookResult = ReturnType<typeof useGetSiteAlarmDetailsLazyQuery>;
export type GetSiteAlarmDetailsQueryResult = Apollo.QueryResult<GetSiteAlarmDetailsQuery, GetSiteAlarmDetailsQueryVariables>;
export const ContactListsDocument = gql`
    query ContactLists($projectId: Int!) {
  project(id: $projectId) {
    id
    contactLists {
      id
      name
      contacts
    }
  }
}
    `;

/**
 * __useContactListsQuery__
 *
 * To run a query within a React component, call `useContactListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactListsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useContactListsQuery(baseOptions: Apollo.QueryHookOptions<ContactListsQuery, ContactListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactListsQuery, ContactListsQueryVariables>(ContactListsDocument, options);
      }
export function useContactListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactListsQuery, ContactListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactListsQuery, ContactListsQueryVariables>(ContactListsDocument, options);
        }
export type ContactListsQueryHookResult = ReturnType<typeof useContactListsQuery>;
export type ContactListsLazyQueryHookResult = ReturnType<typeof useContactListsLazyQuery>;
export type ContactListsQueryResult = Apollo.QueryResult<ContactListsQuery, ContactListsQueryVariables>;
export const GetNotificationsSummaryDocument = gql`
    query GetNotificationsSummary($projectId: Int!, $startTime: Date!, $endTime: Date!) {
  sites(projectId: $projectId) {
    id
    alarms {
      id
      pastNotifications(startTime: $startTime, endTime: $endTime) {
        notifiedAt
      }
    }
  }
}
    `;

/**
 * __useGetNotificationsSummaryQuery__
 *
 * To run a query within a React component, call `useGetNotificationsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsSummaryQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useGetNotificationsSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationsSummaryQuery, GetNotificationsSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsSummaryQuery, GetNotificationsSummaryQueryVariables>(GetNotificationsSummaryDocument, options);
      }
export function useGetNotificationsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsSummaryQuery, GetNotificationsSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsSummaryQuery, GetNotificationsSummaryQueryVariables>(GetNotificationsSummaryDocument, options);
        }
export type GetNotificationsSummaryQueryHookResult = ReturnType<typeof useGetNotificationsSummaryQuery>;
export type GetNotificationsSummaryLazyQueryHookResult = ReturnType<typeof useGetNotificationsSummaryLazyQuery>;
export type GetNotificationsSummaryQueryResult = Apollo.QueryResult<GetNotificationsSummaryQuery, GetNotificationsSummaryQueryVariables>;
export const GetAlarmChecksSummaryDocument = gql`
    query GetAlarmChecksSummary($projectId: Int!, $startTime: Date!, $endTime: Date!) {
  alarmChecksSummary(
    projectId: $projectId
    startTime: $startTime
    endTime: $endTime
  ) {
    state
    count
  }
}
    `;

/**
 * __useGetAlarmChecksSummaryQuery__
 *
 * To run a query within a React component, call `useGetAlarmChecksSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlarmChecksSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlarmChecksSummaryQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useGetAlarmChecksSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetAlarmChecksSummaryQuery, GetAlarmChecksSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlarmChecksSummaryQuery, GetAlarmChecksSummaryQueryVariables>(GetAlarmChecksSummaryDocument, options);
      }
export function useGetAlarmChecksSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAlarmChecksSummaryQuery, GetAlarmChecksSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlarmChecksSummaryQuery, GetAlarmChecksSummaryQueryVariables>(GetAlarmChecksSummaryDocument, options);
        }
export type GetAlarmChecksSummaryQueryHookResult = ReturnType<typeof useGetAlarmChecksSummaryQuery>;
export type GetAlarmChecksSummaryLazyQueryHookResult = ReturnType<typeof useGetAlarmChecksSummaryLazyQuery>;
export type GetAlarmChecksSummaryQueryResult = Apollo.QueryResult<GetAlarmChecksSummaryQuery, GetAlarmChecksSummaryQueryVariables>;
export const ProjectSitesDocument = gql`
    query ProjectSites($projectId: Int!) {
  sites(projectId: $projectId, order: {field: "name", order: ASC}) {
    id
    name
    sublocations
    smbId
    siteLabel
  }
}
    `;

/**
 * __useProjectSitesQuery__
 *
 * To run a query within a React component, call `useProjectSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectSitesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectSitesQuery(baseOptions: Apollo.QueryHookOptions<ProjectSitesQuery, ProjectSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectSitesQuery, ProjectSitesQueryVariables>(ProjectSitesDocument, options);
      }
export function useProjectSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectSitesQuery, ProjectSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectSitesQuery, ProjectSitesQueryVariables>(ProjectSitesDocument, options);
        }
export type ProjectSitesQueryHookResult = ReturnType<typeof useProjectSitesQuery>;
export type ProjectSitesLazyQueryHookResult = ReturnType<typeof useProjectSitesLazyQuery>;
export type ProjectSitesQueryResult = Apollo.QueryResult<ProjectSitesQuery, ProjectSitesQueryVariables>;
export const SaveAlarmGroupDocument = gql`
    mutation SaveAlarmGroup($projectId: Int!, $alarmGroup: AlarmGroupInput!, $siteAlarms: [SiteAlarmInput!]!) {
  saveAlarmGroup(
    projectId: $projectId
    alarmGroup: $alarmGroup
    siteAlarms: $siteAlarms
  )
}
    `;
export type SaveAlarmGroupMutationFn = Apollo.MutationFunction<SaveAlarmGroupMutation, SaveAlarmGroupMutationVariables>;

/**
 * __useSaveAlarmGroupMutation__
 *
 * To run a mutation, you first call `useSaveAlarmGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAlarmGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAlarmGroupMutation, { data, loading, error }] = useSaveAlarmGroupMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      alarmGroup: // value for 'alarmGroup'
 *      siteAlarms: // value for 'siteAlarms'
 *   },
 * });
 */
export function useSaveAlarmGroupMutation(baseOptions?: Apollo.MutationHookOptions<SaveAlarmGroupMutation, SaveAlarmGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveAlarmGroupMutation, SaveAlarmGroupMutationVariables>(SaveAlarmGroupDocument, options);
      }
export type SaveAlarmGroupMutationHookResult = ReturnType<typeof useSaveAlarmGroupMutation>;
export type SaveAlarmGroupMutationResult = Apollo.MutationResult<SaveAlarmGroupMutation>;
export type SaveAlarmGroupMutationOptions = Apollo.BaseMutationOptions<SaveAlarmGroupMutation, SaveAlarmGroupMutationVariables>;
export const GetRecentProjectAlertsDocument = gql`
    query GetRecentProjectAlerts($projectId: Int!, $startTime: String!) {
  alarmGroups(projectId: $projectId) {
    id
    name
    siteAlarms {
      id
      site {
        id
        name
      }
      lastAlert(startTime: $startTime) {
        id
        checkedAt
        alarmGroup {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetRecentProjectAlertsQuery__
 *
 * To run a query within a React component, call `useGetRecentProjectAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentProjectAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentProjectAlertsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      startTime: // value for 'startTime'
 *   },
 * });
 */
export function useGetRecentProjectAlertsQuery(baseOptions: Apollo.QueryHookOptions<GetRecentProjectAlertsQuery, GetRecentProjectAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecentProjectAlertsQuery, GetRecentProjectAlertsQueryVariables>(GetRecentProjectAlertsDocument, options);
      }
export function useGetRecentProjectAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentProjectAlertsQuery, GetRecentProjectAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecentProjectAlertsQuery, GetRecentProjectAlertsQueryVariables>(GetRecentProjectAlertsDocument, options);
        }
export type GetRecentProjectAlertsQueryHookResult = ReturnType<typeof useGetRecentProjectAlertsQuery>;
export type GetRecentProjectAlertsLazyQueryHookResult = ReturnType<typeof useGetRecentProjectAlertsLazyQuery>;
export type GetRecentProjectAlertsQueryResult = Apollo.QueryResult<GetRecentProjectAlertsQuery, GetRecentProjectAlertsQueryVariables>;
export const TestEmailDocument = gql`
    mutation TestEmail($emails: [String!]!) {
  sendTestEmail(emails: $emails)
}
    `;
export type TestEmailMutationFn = Apollo.MutationFunction<TestEmailMutation, TestEmailMutationVariables>;

/**
 * __useTestEmailMutation__
 *
 * To run a mutation, you first call `useTestEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testEmailMutation, { data, loading, error }] = useTestEmailMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useTestEmailMutation(baseOptions?: Apollo.MutationHookOptions<TestEmailMutation, TestEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestEmailMutation, TestEmailMutationVariables>(TestEmailDocument, options);
      }
export type TestEmailMutationHookResult = ReturnType<typeof useTestEmailMutation>;
export type TestEmailMutationResult = Apollo.MutationResult<TestEmailMutation>;
export type TestEmailMutationOptions = Apollo.BaseMutationOptions<TestEmailMutation, TestEmailMutationVariables>;
export const GetChecksDocument = gql`
    query GetChecks($id: Int!, $siteId: Int!, $startTime: Date!, $endTime: Date!) {
  siteAlarm(id: $id) {
    id
    site {
      name
    }
    pastNotifications(startTime: $startTime, endTime: $endTime) {
      notifiedAt
      receipts {
        destination
        type
        status
      }
    }
    alarmGroup {
      id
      name
      checkRuns(startTime: $startTime, endTime: $endTime, siteId: $siteId) {
        id
        state
        checkedAt
      }
    }
  }
}
    `;

/**
 * __useGetChecksQuery__
 *
 * To run a query within a React component, call `useGetChecksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChecksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChecksQuery({
 *   variables: {
 *      id: // value for 'id'
 *      siteId: // value for 'siteId'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useGetChecksQuery(baseOptions: Apollo.QueryHookOptions<GetChecksQuery, GetChecksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChecksQuery, GetChecksQueryVariables>(GetChecksDocument, options);
      }
export function useGetChecksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChecksQuery, GetChecksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChecksQuery, GetChecksQueryVariables>(GetChecksDocument, options);
        }
export type GetChecksQueryHookResult = ReturnType<typeof useGetChecksQuery>;
export type GetChecksLazyQueryHookResult = ReturnType<typeof useGetChecksLazyQuery>;
export type GetChecksQueryResult = Apollo.QueryResult<GetChecksQuery, GetChecksQueryVariables>;
export const GetEquipmentTypesForAssignmentDocument = gql`
    query GetEquipmentTypesForAssignment($projectId: Int!) {
  equipmentTypes(projectId: $projectId, includeApiManaged: false) {
    id
    name
  }
}
    `;

/**
 * __useGetEquipmentTypesForAssignmentQuery__
 *
 * To run a query within a React component, call `useGetEquipmentTypesForAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEquipmentTypesForAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEquipmentTypesForAssignmentQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetEquipmentTypesForAssignmentQuery(baseOptions: Apollo.QueryHookOptions<GetEquipmentTypesForAssignmentQuery, GetEquipmentTypesForAssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEquipmentTypesForAssignmentQuery, GetEquipmentTypesForAssignmentQueryVariables>(GetEquipmentTypesForAssignmentDocument, options);
      }
export function useGetEquipmentTypesForAssignmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEquipmentTypesForAssignmentQuery, GetEquipmentTypesForAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEquipmentTypesForAssignmentQuery, GetEquipmentTypesForAssignmentQueryVariables>(GetEquipmentTypesForAssignmentDocument, options);
        }
export type GetEquipmentTypesForAssignmentQueryHookResult = ReturnType<typeof useGetEquipmentTypesForAssignmentQuery>;
export type GetEquipmentTypesForAssignmentLazyQueryHookResult = ReturnType<typeof useGetEquipmentTypesForAssignmentLazyQuery>;
export type GetEquipmentTypesForAssignmentQueryResult = Apollo.QueryResult<GetEquipmentTypesForAssignmentQuery, GetEquipmentTypesForAssignmentQueryVariables>;
export const GetSitesForEquipmentAssignmentDocument = gql`
    query GetSitesForEquipmentAssignment($projectId: Int!) {
  sites(projectId: $projectId, order: {field: "name", order: ASC}) {
    id
    name
    sublocations
  }
}
    `;

/**
 * __useGetSitesForEquipmentAssignmentQuery__
 *
 * To run a query within a React component, call `useGetSitesForEquipmentAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesForEquipmentAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesForEquipmentAssignmentQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetSitesForEquipmentAssignmentQuery(baseOptions: Apollo.QueryHookOptions<GetSitesForEquipmentAssignmentQuery, GetSitesForEquipmentAssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSitesForEquipmentAssignmentQuery, GetSitesForEquipmentAssignmentQueryVariables>(GetSitesForEquipmentAssignmentDocument, options);
      }
export function useGetSitesForEquipmentAssignmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSitesForEquipmentAssignmentQuery, GetSitesForEquipmentAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSitesForEquipmentAssignmentQuery, GetSitesForEquipmentAssignmentQueryVariables>(GetSitesForEquipmentAssignmentDocument, options);
        }
export type GetSitesForEquipmentAssignmentQueryHookResult = ReturnType<typeof useGetSitesForEquipmentAssignmentQuery>;
export type GetSitesForEquipmentAssignmentLazyQueryHookResult = ReturnType<typeof useGetSitesForEquipmentAssignmentLazyQuery>;
export type GetSitesForEquipmentAssignmentQueryResult = Apollo.QueryResult<GetSitesForEquipmentAssignmentQuery, GetSitesForEquipmentAssignmentQueryVariables>;
export const SaveSiteEquipmentDocument = gql`
    mutation SaveSiteEquipment($siteEquipment: SiteEquipmentInput!) {
  saveSiteEquipment(siteEquipment: $siteEquipment)
}
    `;
export type SaveSiteEquipmentMutationFn = Apollo.MutationFunction<SaveSiteEquipmentMutation, SaveSiteEquipmentMutationVariables>;

/**
 * __useSaveSiteEquipmentMutation__
 *
 * To run a mutation, you first call `useSaveSiteEquipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSiteEquipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSiteEquipmentMutation, { data, loading, error }] = useSaveSiteEquipmentMutation({
 *   variables: {
 *      siteEquipment: // value for 'siteEquipment'
 *   },
 * });
 */
export function useSaveSiteEquipmentMutation(baseOptions?: Apollo.MutationHookOptions<SaveSiteEquipmentMutation, SaveSiteEquipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSiteEquipmentMutation, SaveSiteEquipmentMutationVariables>(SaveSiteEquipmentDocument, options);
      }
export type SaveSiteEquipmentMutationHookResult = ReturnType<typeof useSaveSiteEquipmentMutation>;
export type SaveSiteEquipmentMutationResult = Apollo.MutationResult<SaveSiteEquipmentMutation>;
export type SaveSiteEquipmentMutationOptions = Apollo.BaseMutationOptions<SaveSiteEquipmentMutation, SaveSiteEquipmentMutationVariables>;
export const CreateEquipmentEntryDocument = gql`
    mutation CreateEquipmentEntry($equipmentUpdate: SiteEquipmentEntryInput!) {
  createSiteEquipmentEntry(equipmentUpdate: $equipmentUpdate)
}
    `;
export type CreateEquipmentEntryMutationFn = Apollo.MutationFunction<CreateEquipmentEntryMutation, CreateEquipmentEntryMutationVariables>;

/**
 * __useCreateEquipmentEntryMutation__
 *
 * To run a mutation, you first call `useCreateEquipmentEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEquipmentEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEquipmentEntryMutation, { data, loading, error }] = useCreateEquipmentEntryMutation({
 *   variables: {
 *      equipmentUpdate: // value for 'equipmentUpdate'
 *   },
 * });
 */
export function useCreateEquipmentEntryMutation(baseOptions?: Apollo.MutationHookOptions<CreateEquipmentEntryMutation, CreateEquipmentEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEquipmentEntryMutation, CreateEquipmentEntryMutationVariables>(CreateEquipmentEntryDocument, options);
      }
export type CreateEquipmentEntryMutationHookResult = ReturnType<typeof useCreateEquipmentEntryMutation>;
export type CreateEquipmentEntryMutationResult = Apollo.MutationResult<CreateEquipmentEntryMutation>;
export type CreateEquipmentEntryMutationOptions = Apollo.BaseMutationOptions<CreateEquipmentEntryMutation, CreateEquipmentEntryMutationVariables>;
export const ProjectUsersByNameDocument = gql`
    query ProjectUsersByName($projectId: Int!, $name: String!) {
  project(id: $projectId) {
    users(search: $name) {
      oryId
      email
      name
    }
  }
}
    `;

/**
 * __useProjectUsersByNameQuery__
 *
 * To run a query within a React component, call `useProjectUsersByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectUsersByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectUsersByNameQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useProjectUsersByNameQuery(baseOptions: Apollo.QueryHookOptions<ProjectUsersByNameQuery, ProjectUsersByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectUsersByNameQuery, ProjectUsersByNameQueryVariables>(ProjectUsersByNameDocument, options);
      }
export function useProjectUsersByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectUsersByNameQuery, ProjectUsersByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectUsersByNameQuery, ProjectUsersByNameQueryVariables>(ProjectUsersByNameDocument, options);
        }
export type ProjectUsersByNameQueryHookResult = ReturnType<typeof useProjectUsersByNameQuery>;
export type ProjectUsersByNameLazyQueryHookResult = ReturnType<typeof useProjectUsersByNameLazyQuery>;
export type ProjectUsersByNameQueryResult = Apollo.QueryResult<ProjectUsersByNameQuery, ProjectUsersByNameQueryVariables>;
export const AknowledgeVersionDocument = gql`
    mutation AknowledgeVersion($userId: String!, $version: String!) {
  updateUser(userId: $userId, attributes: {lastVersion: $version}) {
    oryId
    lastVersion
  }
}
    `;
export type AknowledgeVersionMutationFn = Apollo.MutationFunction<AknowledgeVersionMutation, AknowledgeVersionMutationVariables>;

/**
 * __useAknowledgeVersionMutation__
 *
 * To run a mutation, you first call `useAknowledgeVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAknowledgeVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aknowledgeVersionMutation, { data, loading, error }] = useAknowledgeVersionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useAknowledgeVersionMutation(baseOptions?: Apollo.MutationHookOptions<AknowledgeVersionMutation, AknowledgeVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AknowledgeVersionMutation, AknowledgeVersionMutationVariables>(AknowledgeVersionDocument, options);
      }
export type AknowledgeVersionMutationHookResult = ReturnType<typeof useAknowledgeVersionMutation>;
export type AknowledgeVersionMutationResult = Apollo.MutationResult<AknowledgeVersionMutation>;
export type AknowledgeVersionMutationOptions = Apollo.BaseMutationOptions<AknowledgeVersionMutation, AknowledgeVersionMutationVariables>;
export const ReleaseNotesDocument = gql`
    query ReleaseNotes {
  releaseNotes {
    version
    content
  }
}
    `;

/**
 * __useReleaseNotesQuery__
 *
 * To run a query within a React component, call `useReleaseNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleaseNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleaseNotesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReleaseNotesQuery(baseOptions?: Apollo.QueryHookOptions<ReleaseNotesQuery, ReleaseNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReleaseNotesQuery, ReleaseNotesQueryVariables>(ReleaseNotesDocument, options);
      }
export function useReleaseNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReleaseNotesQuery, ReleaseNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReleaseNotesQuery, ReleaseNotesQueryVariables>(ReleaseNotesDocument, options);
        }
export type ReleaseNotesQueryHookResult = ReturnType<typeof useReleaseNotesQuery>;
export type ReleaseNotesLazyQueryHookResult = ReturnType<typeof useReleaseNotesLazyQuery>;
export type ReleaseNotesQueryResult = Apollo.QueryResult<ReleaseNotesQuery, ReleaseNotesQueryVariables>;
export const GetUserActivityDocument = gql`
    query GetUserActivity($projectId: Int!, $userId: String!, $after: Date!) {
  project(id: $projectId) {
    usership(userId: $userId, start: $after) {
      datetime
      count
    }
  }
}
    `;

/**
 * __useGetUserActivityQuery__
 *
 * To run a query within a React component, call `useGetUserActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserActivityQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetUserActivityQuery(baseOptions: Apollo.QueryHookOptions<GetUserActivityQuery, GetUserActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserActivityQuery, GetUserActivityQueryVariables>(GetUserActivityDocument, options);
      }
export function useGetUserActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserActivityQuery, GetUserActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserActivityQuery, GetUserActivityQueryVariables>(GetUserActivityDocument, options);
        }
export type GetUserActivityQueryHookResult = ReturnType<typeof useGetUserActivityQuery>;
export type GetUserActivityLazyQueryHookResult = ReturnType<typeof useGetUserActivityLazyQuery>;
export type GetUserActivityQueryResult = Apollo.QueryResult<GetUserActivityQuery, GetUserActivityQueryVariables>;
export const GetAscReportsDocument = gql`
    query getASCReports($siteId: Int!) {
  reports: getASCReports(siteId: $siteId) {
    id
    reportTime
    lastUpdated
    reportedBy
    siteId
    formInput
    transactionId
  }
}
    `;

/**
 * __useGetAscReportsQuery__
 *
 * To run a query within a React component, call `useGetAscReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAscReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAscReportsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetAscReportsQuery(baseOptions: Apollo.QueryHookOptions<GetAscReportsQuery, GetAscReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAscReportsQuery, GetAscReportsQueryVariables>(GetAscReportsDocument, options);
      }
export function useGetAscReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAscReportsQuery, GetAscReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAscReportsQuery, GetAscReportsQueryVariables>(GetAscReportsDocument, options);
        }
export type GetAscReportsQueryHookResult = ReturnType<typeof useGetAscReportsQuery>;
export type GetAscReportsLazyQueryHookResult = ReturnType<typeof useGetAscReportsLazyQuery>;
export type GetAscReportsQueryResult = Apollo.QueryResult<GetAscReportsQuery, GetAscReportsQueryVariables>;
export const SaveAscReportDocument = gql`
    mutation saveASCReport($id: Int, $reportTime: Date!, $lastUpdated: Date!, $reportedBy: String!, $siteId: Int!, $formInput: JSON!, $transactionId: String!) {
  saveASCReport(
    id: $id
    reportTime: $reportTime
    lastUpdated: $lastUpdated
    reportedBy: $reportedBy
    siteId: $siteId
    formInput: $formInput
    transactionId: $transactionId
  ) {
    id
    reportTime
    lastUpdated
    reportedBy
    siteId
    formInput
    transactionId
  }
}
    `;
export type SaveAscReportMutationFn = Apollo.MutationFunction<SaveAscReportMutation, SaveAscReportMutationVariables>;

/**
 * __useSaveAscReportMutation__
 *
 * To run a mutation, you first call `useSaveAscReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAscReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAscReportMutation, { data, loading, error }] = useSaveAscReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reportTime: // value for 'reportTime'
 *      lastUpdated: // value for 'lastUpdated'
 *      reportedBy: // value for 'reportedBy'
 *      siteId: // value for 'siteId'
 *      formInput: // value for 'formInput'
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useSaveAscReportMutation(baseOptions?: Apollo.MutationHookOptions<SaveAscReportMutation, SaveAscReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveAscReportMutation, SaveAscReportMutationVariables>(SaveAscReportDocument, options);
      }
export type SaveAscReportMutationHookResult = ReturnType<typeof useSaveAscReportMutation>;
export type SaveAscReportMutationResult = Apollo.MutationResult<SaveAscReportMutation>;
export type SaveAscReportMutationOptions = Apollo.BaseMutationOptions<SaveAscReportMutation, SaveAscReportMutationVariables>;
export const DeleteAscReportsDocument = gql`
    mutation deleteASCReports($ids: [Int!]!) {
  deleteASCReports(ids: $ids)
}
    `;
export type DeleteAscReportsMutationFn = Apollo.MutationFunction<DeleteAscReportsMutation, DeleteAscReportsMutationVariables>;

/**
 * __useDeleteAscReportsMutation__
 *
 * To run a mutation, you first call `useDeleteAscReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAscReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAscReportsMutation, { data, loading, error }] = useDeleteAscReportsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteAscReportsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAscReportsMutation, DeleteAscReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAscReportsMutation, DeleteAscReportsMutationVariables>(DeleteAscReportsDocument, options);
      }
export type DeleteAscReportsMutationHookResult = ReturnType<typeof useDeleteAscReportsMutation>;
export type DeleteAscReportsMutationResult = Apollo.MutationResult<DeleteAscReportsMutation>;
export type DeleteAscReportsMutationOptions = Apollo.BaseMutationOptions<DeleteAscReportsMutation, DeleteAscReportsMutationVariables>;
export const GetSiteAlarmsDocument = gql`
    query GetSiteAlarms($siteId: Int!, $startTime: Date!, $endTime: Date!) {
  siteAlarms(siteId: $siteId) {
    id
    suspended
    site {
      id
      name
    }
    lastAlert {
      id
      checkedAt
    }
    lastCheck {
      id
      state
      checkedAt
    }
    alarmGroup {
      id
      name
      checkRuns(startTime: $startTime, endTime: $endTime, siteId: $siteId) {
        id
        state
        checkedAt
      }
    }
    pastNotifications(startTime: $startTime, endTime: $endTime) {
      notifiedAt
    }
  }
}
    `;

/**
 * __useGetSiteAlarmsQuery__
 *
 * To run a query within a React component, call `useGetSiteAlarmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteAlarmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteAlarmsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useGetSiteAlarmsQuery(baseOptions: Apollo.QueryHookOptions<GetSiteAlarmsQuery, GetSiteAlarmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteAlarmsQuery, GetSiteAlarmsQueryVariables>(GetSiteAlarmsDocument, options);
      }
export function useGetSiteAlarmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteAlarmsQuery, GetSiteAlarmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteAlarmsQuery, GetSiteAlarmsQueryVariables>(GetSiteAlarmsDocument, options);
        }
export type GetSiteAlarmsQueryHookResult = ReturnType<typeof useGetSiteAlarmsQuery>;
export type GetSiteAlarmsLazyQueryHookResult = ReturnType<typeof useGetSiteAlarmsLazyQuery>;
export type GetSiteAlarmsQueryResult = Apollo.QueryResult<GetSiteAlarmsQuery, GetSiteAlarmsQueryVariables>;
export const GetEquipmentOnSiteDocument = gql`
    query GetEquipmentOnSite($siteId: Int!) {
  site(id: $siteId) {
    id
    equipment {
      id
      name
      details
      sublocation
      deleted
      equipmentType {
        id
        name
        apiManaged
        states {
          ... on EquipmentSelectionState {
            id
            name
            description
            options {
              id
              name
              color
            }
          }
          ... on EquipmentNumberState {
            id
            name
            description
          }
          ... on EquipmentTextState {
            id
            name
            description
          }
        }
        actions {
          id
          name
          description
        }
      }
    }
  }
}
    `;

/**
 * __useGetEquipmentOnSiteQuery__
 *
 * To run a query within a React component, call `useGetEquipmentOnSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEquipmentOnSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEquipmentOnSiteQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetEquipmentOnSiteQuery(baseOptions: Apollo.QueryHookOptions<GetEquipmentOnSiteQuery, GetEquipmentOnSiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEquipmentOnSiteQuery, GetEquipmentOnSiteQueryVariables>(GetEquipmentOnSiteDocument, options);
      }
export function useGetEquipmentOnSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEquipmentOnSiteQuery, GetEquipmentOnSiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEquipmentOnSiteQuery, GetEquipmentOnSiteQueryVariables>(GetEquipmentOnSiteDocument, options);
        }
export type GetEquipmentOnSiteQueryHookResult = ReturnType<typeof useGetEquipmentOnSiteQuery>;
export type GetEquipmentOnSiteLazyQueryHookResult = ReturnType<typeof useGetEquipmentOnSiteLazyQuery>;
export type GetEquipmentOnSiteQueryResult = Apollo.QueryResult<GetEquipmentOnSiteQuery, GetEquipmentOnSiteQueryVariables>;
export const SaveEventCommentDocument = gql`
    mutation saveEventComment($eventId: Int!, $comment: String!, $attachmentRefs: [String!], $notify: [String!]) {
  saveEventComment(
    eventId: $eventId
    comment: $comment
    attachmentRefs: $attachmentRefs
    notify: $notify
  ) {
    id
    eventType
    severity
    creator
    created
    siteId
    startTime
    endTime
    detail
    comments {
      id
      eventId
      creator
      comment
      created
      attachmentRefs
    }
    subscribed
    hasNew
  }
}
    `;
export type SaveEventCommentMutationFn = Apollo.MutationFunction<SaveEventCommentMutation, SaveEventCommentMutationVariables>;

/**
 * __useSaveEventCommentMutation__
 *
 * To run a mutation, you first call `useSaveEventCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEventCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEventCommentMutation, { data, loading, error }] = useSaveEventCommentMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      comment: // value for 'comment'
 *      attachmentRefs: // value for 'attachmentRefs'
 *      notify: // value for 'notify'
 *   },
 * });
 */
export function useSaveEventCommentMutation(baseOptions?: Apollo.MutationHookOptions<SaveEventCommentMutation, SaveEventCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveEventCommentMutation, SaveEventCommentMutationVariables>(SaveEventCommentDocument, options);
      }
export type SaveEventCommentMutationHookResult = ReturnType<typeof useSaveEventCommentMutation>;
export type SaveEventCommentMutationResult = Apollo.MutationResult<SaveEventCommentMutation>;
export type SaveEventCommentMutationOptions = Apollo.BaseMutationOptions<SaveEventCommentMutation, SaveEventCommentMutationVariables>;
export const SaveEventDocument = gql`
    mutation saveEvent($event: EventInput!, $notify: [String!]) {
  saveEvent(event: $event, notify: $notify) {
    id
    eventType
    severity
    creator
    created
    siteId
    startTime
    endTime
    trace
    detail
    comments {
      id
      eventId
      creator
      comment
      created
      attachmentRefs
    }
    subscribed
    hasNew
  }
}
    `;
export type SaveEventMutationFn = Apollo.MutationFunction<SaveEventMutation, SaveEventMutationVariables>;

/**
 * __useSaveEventMutation__
 *
 * To run a mutation, you first call `useSaveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEventMutation, { data, loading, error }] = useSaveEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *      notify: // value for 'notify'
 *   },
 * });
 */
export function useSaveEventMutation(baseOptions?: Apollo.MutationHookOptions<SaveEventMutation, SaveEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveEventMutation, SaveEventMutationVariables>(SaveEventDocument, options);
      }
export type SaveEventMutationHookResult = ReturnType<typeof useSaveEventMutation>;
export type SaveEventMutationResult = Apollo.MutationResult<SaveEventMutation>;
export type SaveEventMutationOptions = Apollo.BaseMutationOptions<SaveEventMutation, SaveEventMutationVariables>;
export const DeleteEventDocument = gql`
    mutation deleteEvent($eventId: Int!) {
  deleteEvent(eventId: $eventId)
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const ChartEventsDocument = gql`
    query ChartEvents($siteId: Int!, $startTime: Date, $endTime: Date) {
  getEvents(siteId: $siteId, startTime: $startTime, endTime: $endTime) {
    id
    eventType
    startTime
    endTime
  }
}
    `;

/**
 * __useChartEventsQuery__
 *
 * To run a query within a React component, call `useChartEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartEventsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useChartEventsQuery(baseOptions: Apollo.QueryHookOptions<ChartEventsQuery, ChartEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartEventsQuery, ChartEventsQueryVariables>(ChartEventsDocument, options);
      }
export function useChartEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartEventsQuery, ChartEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartEventsQuery, ChartEventsQueryVariables>(ChartEventsDocument, options);
        }
export type ChartEventsQueryHookResult = ReturnType<typeof useChartEventsQuery>;
export type ChartEventsLazyQueryHookResult = ReturnType<typeof useChartEventsLazyQuery>;
export type ChartEventsQueryResult = Apollo.QueryResult<ChartEventsQuery, ChartEventsQueryVariables>;
export const DeleteChartSetDocument = gql`
    mutation DeleteChartSet($id: Int!) {
  deleteChartSet(id: $id)
}
    `;
export type DeleteChartSetMutationFn = Apollo.MutationFunction<DeleteChartSetMutation, DeleteChartSetMutationVariables>;

/**
 * __useDeleteChartSetMutation__
 *
 * To run a mutation, you first call `useDeleteChartSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChartSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChartSetMutation, { data, loading, error }] = useDeleteChartSetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChartSetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChartSetMutation, DeleteChartSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChartSetMutation, DeleteChartSetMutationVariables>(DeleteChartSetDocument, options);
      }
export type DeleteChartSetMutationHookResult = ReturnType<typeof useDeleteChartSetMutation>;
export type DeleteChartSetMutationResult = Apollo.MutationResult<DeleteChartSetMutation>;
export type DeleteChartSetMutationOptions = Apollo.BaseMutationOptions<DeleteChartSetMutation, DeleteChartSetMutationVariables>;
export const GetChartSetsDocument = gql`
    query GetChartSets($projectId: Int!, $isProjectSet: Boolean!) {
  chartSets(projectId: $projectId, isProjectSet: $isProjectSet) {
    id
    name
    description
    isProjectSet
    timeRange {
      startTime
      endTime
      granularity
      dateRange
    }
    charts {
      id
      chartId
      settings
      displayName
    }
  }
}
    `;

/**
 * __useGetChartSetsQuery__
 *
 * To run a query within a React component, call `useGetChartSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChartSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartSetsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      isProjectSet: // value for 'isProjectSet'
 *   },
 * });
 */
export function useGetChartSetsQuery(baseOptions: Apollo.QueryHookOptions<GetChartSetsQuery, GetChartSetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChartSetsQuery, GetChartSetsQueryVariables>(GetChartSetsDocument, options);
      }
export function useGetChartSetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChartSetsQuery, GetChartSetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChartSetsQuery, GetChartSetsQueryVariables>(GetChartSetsDocument, options);
        }
export type GetChartSetsQueryHookResult = ReturnType<typeof useGetChartSetsQuery>;
export type GetChartSetsLazyQueryHookResult = ReturnType<typeof useGetChartSetsLazyQuery>;
export type GetChartSetsQueryResult = Apollo.QueryResult<GetChartSetsQuery, GetChartSetsQueryVariables>;
export const SaveChartSetDocument = gql`
    mutation SaveChartSet($id: Int, $projectId: Int!, $chartSet: ChartSetInput!) {
  saveChartSet(id: $id, projectId: $projectId, chartSet: $chartSet)
}
    `;
export type SaveChartSetMutationFn = Apollo.MutationFunction<SaveChartSetMutation, SaveChartSetMutationVariables>;

/**
 * __useSaveChartSetMutation__
 *
 * To run a mutation, you first call `useSaveChartSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveChartSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveChartSetMutation, { data, loading, error }] = useSaveChartSetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      projectId: // value for 'projectId'
 *      chartSet: // value for 'chartSet'
 *   },
 * });
 */
export function useSaveChartSetMutation(baseOptions?: Apollo.MutationHookOptions<SaveChartSetMutation, SaveChartSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveChartSetMutation, SaveChartSetMutationVariables>(SaveChartSetDocument, options);
      }
export type SaveChartSetMutationHookResult = ReturnType<typeof useSaveChartSetMutation>;
export type SaveChartSetMutationResult = Apollo.MutationResult<SaveChartSetMutation>;
export type SaveChartSetMutationOptions = Apollo.BaseMutationOptions<SaveChartSetMutation, SaveChartSetMutationVariables>;
export const SaveHydrographyReportDocument = gql`
    mutation saveHydrographyReport($id: Int, $reportTime: Date!, $lastUpdated: Date!, $reportedBy: String!, $siteId: Int!, $formInput: JSON!, $transactionId: String!) {
  saveHydrographyReport(
    id: $id
    reportTime: $reportTime
    lastUpdated: $lastUpdated
    reportedBy: $reportedBy
    siteId: $siteId
    formInput: $formInput
    transactionId: $transactionId
  ) {
    id
    reportTime
    lastUpdated
    reportedBy
    siteId
    formInput
    transactionId
  }
}
    `;
export type SaveHydrographyReportMutationFn = Apollo.MutationFunction<SaveHydrographyReportMutation, SaveHydrographyReportMutationVariables>;

/**
 * __useSaveHydrographyReportMutation__
 *
 * To run a mutation, you first call `useSaveHydrographyReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveHydrographyReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveHydrographyReportMutation, { data, loading, error }] = useSaveHydrographyReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reportTime: // value for 'reportTime'
 *      lastUpdated: // value for 'lastUpdated'
 *      reportedBy: // value for 'reportedBy'
 *      siteId: // value for 'siteId'
 *      formInput: // value for 'formInput'
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useSaveHydrographyReportMutation(baseOptions?: Apollo.MutationHookOptions<SaveHydrographyReportMutation, SaveHydrographyReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveHydrographyReportMutation, SaveHydrographyReportMutationVariables>(SaveHydrographyReportDocument, options);
      }
export type SaveHydrographyReportMutationHookResult = ReturnType<typeof useSaveHydrographyReportMutation>;
export type SaveHydrographyReportMutationResult = Apollo.MutationResult<SaveHydrographyReportMutation>;
export type SaveHydrographyReportMutationOptions = Apollo.BaseMutationOptions<SaveHydrographyReportMutation, SaveHydrographyReportMutationVariables>;
export const DeleteHydrographyReportsDocument = gql`
    mutation deleteHydrographyReports($ids: [Int!]!) {
  deleteHydrographyReports(ids: $ids)
}
    `;
export type DeleteHydrographyReportsMutationFn = Apollo.MutationFunction<DeleteHydrographyReportsMutation, DeleteHydrographyReportsMutationVariables>;

/**
 * __useDeleteHydrographyReportsMutation__
 *
 * To run a mutation, you first call `useDeleteHydrographyReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHydrographyReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHydrographyReportsMutation, { data, loading, error }] = useDeleteHydrographyReportsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteHydrographyReportsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHydrographyReportsMutation, DeleteHydrographyReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHydrographyReportsMutation, DeleteHydrographyReportsMutationVariables>(DeleteHydrographyReportsDocument, options);
      }
export type DeleteHydrographyReportsMutationHookResult = ReturnType<typeof useDeleteHydrographyReportsMutation>;
export type DeleteHydrographyReportsMutationResult = Apollo.MutationResult<DeleteHydrographyReportsMutation>;
export type DeleteHydrographyReportsMutationOptions = Apollo.BaseMutationOptions<DeleteHydrographyReportsMutation, DeleteHydrographyReportsMutationVariables>;
export const GetHydrographyReportsDocument = gql`
    query getHydrographyReports($siteId: Int!) {
  reports: getHydrographyReports(siteId: $siteId) {
    id
    reportTime
    lastUpdated
    reportedBy
    siteId
    formInput
    transactionId
  }
}
    `;

/**
 * __useGetHydrographyReportsQuery__
 *
 * To run a query within a React component, call `useGetHydrographyReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHydrographyReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHydrographyReportsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetHydrographyReportsQuery(baseOptions: Apollo.QueryHookOptions<GetHydrographyReportsQuery, GetHydrographyReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHydrographyReportsQuery, GetHydrographyReportsQueryVariables>(GetHydrographyReportsDocument, options);
      }
export function useGetHydrographyReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHydrographyReportsQuery, GetHydrographyReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHydrographyReportsQuery, GetHydrographyReportsQueryVariables>(GetHydrographyReportsDocument, options);
        }
export type GetHydrographyReportsQueryHookResult = ReturnType<typeof useGetHydrographyReportsQuery>;
export type GetHydrographyReportsLazyQueryHookResult = ReturnType<typeof useGetHydrographyReportsLazyQuery>;
export type GetHydrographyReportsQueryResult = Apollo.QueryResult<GetHydrographyReportsQuery, GetHydrographyReportsQueryVariables>;
export const SavePlanktonReportDocument = gql`
    mutation savePlanktonReport($id: Int, $reportTime: Date!, $lastUpdated: Date!, $reportedBy: String!, $siteId: Int!, $sublocation: String, $formInput: JSON!, $transactionId: String!) {
  savePlanktonReport(
    id: $id
    reportTime: $reportTime
    lastUpdated: $lastUpdated
    reportedBy: $reportedBy
    siteId: $siteId
    sublocation: $sublocation
    formInput: $formInput
    transactionId: $transactionId
  ) {
    id
    reportTime
    lastUpdated
    reportedBy
    siteId
    sublocation
    formInput
    transactionId
  }
}
    `;
export type SavePlanktonReportMutationFn = Apollo.MutationFunction<SavePlanktonReportMutation, SavePlanktonReportMutationVariables>;

/**
 * __useSavePlanktonReportMutation__
 *
 * To run a mutation, you first call `useSavePlanktonReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePlanktonReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePlanktonReportMutation, { data, loading, error }] = useSavePlanktonReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reportTime: // value for 'reportTime'
 *      lastUpdated: // value for 'lastUpdated'
 *      reportedBy: // value for 'reportedBy'
 *      siteId: // value for 'siteId'
 *      sublocation: // value for 'sublocation'
 *      formInput: // value for 'formInput'
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useSavePlanktonReportMutation(baseOptions?: Apollo.MutationHookOptions<SavePlanktonReportMutation, SavePlanktonReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SavePlanktonReportMutation, SavePlanktonReportMutationVariables>(SavePlanktonReportDocument, options);
      }
export type SavePlanktonReportMutationHookResult = ReturnType<typeof useSavePlanktonReportMutation>;
export type SavePlanktonReportMutationResult = Apollo.MutationResult<SavePlanktonReportMutation>;
export type SavePlanktonReportMutationOptions = Apollo.BaseMutationOptions<SavePlanktonReportMutation, SavePlanktonReportMutationVariables>;
export const DeletePlanktonReportsDocument = gql`
    mutation deletePlanktonReports($ids: [Int!]!) {
  deletePlanktonReports(ids: $ids)
}
    `;
export type DeletePlanktonReportsMutationFn = Apollo.MutationFunction<DeletePlanktonReportsMutation, DeletePlanktonReportsMutationVariables>;

/**
 * __useDeletePlanktonReportsMutation__
 *
 * To run a mutation, you first call `useDeletePlanktonReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanktonReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanktonReportsMutation, { data, loading, error }] = useDeletePlanktonReportsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeletePlanktonReportsMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlanktonReportsMutation, DeletePlanktonReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlanktonReportsMutation, DeletePlanktonReportsMutationVariables>(DeletePlanktonReportsDocument, options);
      }
export type DeletePlanktonReportsMutationHookResult = ReturnType<typeof useDeletePlanktonReportsMutation>;
export type DeletePlanktonReportsMutationResult = Apollo.MutationResult<DeletePlanktonReportsMutation>;
export type DeletePlanktonReportsMutationOptions = Apollo.BaseMutationOptions<DeletePlanktonReportsMutation, DeletePlanktonReportsMutationVariables>;
export const GetPlanktonReportsDocument = gql`
    query getPlanktonReports($siteId: Int!) {
  reports: getPlanktonReports(siteId: $siteId) {
    id
    reportTime
    lastUpdated
    reportedBy
    siteId
    sublocation
    formInput
    transactionId
  }
}
    `;

/**
 * __useGetPlanktonReportsQuery__
 *
 * To run a query within a React component, call `useGetPlanktonReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanktonReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanktonReportsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetPlanktonReportsQuery(baseOptions: Apollo.QueryHookOptions<GetPlanktonReportsQuery, GetPlanktonReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlanktonReportsQuery, GetPlanktonReportsQueryVariables>(GetPlanktonReportsDocument, options);
      }
export function useGetPlanktonReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanktonReportsQuery, GetPlanktonReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlanktonReportsQuery, GetPlanktonReportsQueryVariables>(GetPlanktonReportsDocument, options);
        }
export type GetPlanktonReportsQueryHookResult = ReturnType<typeof useGetPlanktonReportsQuery>;
export type GetPlanktonReportsLazyQueryHookResult = ReturnType<typeof useGetPlanktonReportsLazyQuery>;
export type GetPlanktonReportsQueryResult = Apollo.QueryResult<GetPlanktonReportsQuery, GetPlanktonReportsQueryVariables>;
export const DeleteSeaLiceReportsDocument = gql`
    mutation deleteSeaLiceReports($ids: [Int!]!) {
  deleteSeaLiceReports(ids: $ids)
}
    `;
export type DeleteSeaLiceReportsMutationFn = Apollo.MutationFunction<DeleteSeaLiceReportsMutation, DeleteSeaLiceReportsMutationVariables>;

/**
 * __useDeleteSeaLiceReportsMutation__
 *
 * To run a mutation, you first call `useDeleteSeaLiceReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeaLiceReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeaLiceReportsMutation, { data, loading, error }] = useDeleteSeaLiceReportsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteSeaLiceReportsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSeaLiceReportsMutation, DeleteSeaLiceReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSeaLiceReportsMutation, DeleteSeaLiceReportsMutationVariables>(DeleteSeaLiceReportsDocument, options);
      }
export type DeleteSeaLiceReportsMutationHookResult = ReturnType<typeof useDeleteSeaLiceReportsMutation>;
export type DeleteSeaLiceReportsMutationResult = Apollo.MutationResult<DeleteSeaLiceReportsMutation>;
export type DeleteSeaLiceReportsMutationOptions = Apollo.BaseMutationOptions<DeleteSeaLiceReportsMutation, DeleteSeaLiceReportsMutationVariables>;
export const SaveSeaLiceReportDocument = gql`
    mutation saveSeaLiceReport($id: Int, $reportTime: Date!, $lastUpdated: Date!, $reportedBy: String!, $siteId: Int!, $sublocation: String, $formInput: JSON!, $transactionId: String!) {
  saveSeaLiceReport(
    id: $id
    reportTime: $reportTime
    lastUpdated: $lastUpdated
    reportedBy: $reportedBy
    siteId: $siteId
    sublocation: $sublocation
    formInput: $formInput
    transactionId: $transactionId
  ) {
    id
    reportTime
    lastUpdated
    reportedBy
    siteId
    sublocation
    formInput
    transactionId
  }
}
    `;
export type SaveSeaLiceReportMutationFn = Apollo.MutationFunction<SaveSeaLiceReportMutation, SaveSeaLiceReportMutationVariables>;

/**
 * __useSaveSeaLiceReportMutation__
 *
 * To run a mutation, you first call `useSaveSeaLiceReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSeaLiceReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSeaLiceReportMutation, { data, loading, error }] = useSaveSeaLiceReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reportTime: // value for 'reportTime'
 *      lastUpdated: // value for 'lastUpdated'
 *      reportedBy: // value for 'reportedBy'
 *      siteId: // value for 'siteId'
 *      sublocation: // value for 'sublocation'
 *      formInput: // value for 'formInput'
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useSaveSeaLiceReportMutation(baseOptions?: Apollo.MutationHookOptions<SaveSeaLiceReportMutation, SaveSeaLiceReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSeaLiceReportMutation, SaveSeaLiceReportMutationVariables>(SaveSeaLiceReportDocument, options);
      }
export type SaveSeaLiceReportMutationHookResult = ReturnType<typeof useSaveSeaLiceReportMutation>;
export type SaveSeaLiceReportMutationResult = Apollo.MutationResult<SaveSeaLiceReportMutation>;
export type SaveSeaLiceReportMutationOptions = Apollo.BaseMutationOptions<SaveSeaLiceReportMutation, SaveSeaLiceReportMutationVariables>;
export const GetSeaLiceReportsDocument = gql`
    query getSeaLiceReports($siteId: Int!) {
  reports: getSeaLiceReports(siteId: $siteId) {
    id
    reportTime
    lastUpdated
    reportedBy
    siteId
    sublocation
    formInput
    transactionId
  }
}
    `;

/**
 * __useGetSeaLiceReportsQuery__
 *
 * To run a query within a React component, call `useGetSeaLiceReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeaLiceReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeaLiceReportsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useGetSeaLiceReportsQuery(baseOptions: Apollo.QueryHookOptions<GetSeaLiceReportsQuery, GetSeaLiceReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeaLiceReportsQuery, GetSeaLiceReportsQueryVariables>(GetSeaLiceReportsDocument, options);
      }
export function useGetSeaLiceReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeaLiceReportsQuery, GetSeaLiceReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeaLiceReportsQuery, GetSeaLiceReportsQueryVariables>(GetSeaLiceReportsDocument, options);
        }
export type GetSeaLiceReportsQueryHookResult = ReturnType<typeof useGetSeaLiceReportsQuery>;
export type GetSeaLiceReportsLazyQueryHookResult = ReturnType<typeof useGetSeaLiceReportsLazyQuery>;
export type GetSeaLiceReportsQueryResult = Apollo.QueryResult<GetSeaLiceReportsQuery, GetSeaLiceReportsQueryVariables>;
export const GetProjectDocument = gql`
    query GetProject($id: Int!) {
  project(id: $id) {
    id
    name
    active
    latitude
    longitude
    zoomlevel
    forecasting
    siwi
    hiresParams
    dataEntry
    thresholds
    timezone
    tabs
    demo
    freeTrial
    mapLayers
    mapParamSuffix
    altBargeHydrography
    region {
      id
      stations {
        id
        name
        lat
        lon
        stationKey
        type
        owner
      }
    }
    organization {
      id
      name
    }
    sites(includeArchived: true) {
      id
      smbId
      name
      type
    }
    planktonPolicy {
      species {
        id
        key
        species
        category
        imageUrl
      }
      generic {
        id
        key
        name
        category
        imageUrl
      }
      caution
      danger
    }
  }
}
    `;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions: Apollo.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
      }
export function useGetProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = Apollo.QueryResult<GetProjectQuery, GetProjectQueryVariables>;
export const GetEventsDocument = gql`
    query getEvents($siteId: Int!, $startTime: Date, $endTime: Date) {
  getEvents(siteId: $siteId, startTime: $startTime, endTime: $endTime) {
    id
    eventType
    severity
    creator
    created
    siteId
    startTime
    endTime
    trace
    detail
    comments {
      id
      eventId
      creator
      comment
      created
      attachmentRefs
    }
    subscribed
    hasNew
  }
}
    `;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export const GetRecentAlarmsDocument = gql`
    query GetRecentAlarms($projectId: Int!) {
  alarmStatusCheckRuns(projectId: $projectId, uiFilter: true) {
    id
    state
    checkedAt
    site {
      id
      name
    }
    alarmGroup {
      id
      name
    }
  }
}
    `;

/**
 * __useGetRecentAlarmsQuery__
 *
 * To run a query within a React component, call `useGetRecentAlarmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentAlarmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentAlarmsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetRecentAlarmsQuery(baseOptions: Apollo.QueryHookOptions<GetRecentAlarmsQuery, GetRecentAlarmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecentAlarmsQuery, GetRecentAlarmsQueryVariables>(GetRecentAlarmsDocument, options);
      }
export function useGetRecentAlarmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentAlarmsQuery, GetRecentAlarmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecentAlarmsQuery, GetRecentAlarmsQueryVariables>(GetRecentAlarmsDocument, options);
        }
export type GetRecentAlarmsQueryHookResult = ReturnType<typeof useGetRecentAlarmsQuery>;
export type GetRecentAlarmsLazyQueryHookResult = ReturnType<typeof useGetRecentAlarmsLazyQuery>;
export type GetRecentAlarmsQueryResult = Apollo.QueryResult<GetRecentAlarmsQuery, GetRecentAlarmsQueryVariables>;
export const GetDemoSitesDocument = gql`
    query GetDemoSites {
  sites(projectId: 8) {
    id
    name
  }
}
    `;

/**
 * __useGetDemoSitesQuery__
 *
 * To run a query within a React component, call `useGetDemoSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDemoSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDemoSitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDemoSitesQuery(baseOptions?: Apollo.QueryHookOptions<GetDemoSitesQuery, GetDemoSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDemoSitesQuery, GetDemoSitesQueryVariables>(GetDemoSitesDocument, options);
      }
export function useGetDemoSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDemoSitesQuery, GetDemoSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDemoSitesQuery, GetDemoSitesQueryVariables>(GetDemoSitesDocument, options);
        }
export type GetDemoSitesQueryHookResult = ReturnType<typeof useGetDemoSitesQuery>;
export type GetDemoSitesLazyQueryHookResult = ReturnType<typeof useGetDemoSitesLazyQuery>;
export type GetDemoSitesQueryResult = Apollo.QueryResult<GetDemoSitesQuery, GetDemoSitesQueryVariables>;
export const DeleteEquipmentTypeDocument = gql`
    mutation DeleteEquipmentType($id: Int!) {
  deleteEquipmentType(equipmentTypeId: $id)
}
    `;
export type DeleteEquipmentTypeMutationFn = Apollo.MutationFunction<DeleteEquipmentTypeMutation, DeleteEquipmentTypeMutationVariables>;

/**
 * __useDeleteEquipmentTypeMutation__
 *
 * To run a mutation, you first call `useDeleteEquipmentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEquipmentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEquipmentTypeMutation, { data, loading, error }] = useDeleteEquipmentTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEquipmentTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEquipmentTypeMutation, DeleteEquipmentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEquipmentTypeMutation, DeleteEquipmentTypeMutationVariables>(DeleteEquipmentTypeDocument, options);
      }
export type DeleteEquipmentTypeMutationHookResult = ReturnType<typeof useDeleteEquipmentTypeMutation>;
export type DeleteEquipmentTypeMutationResult = Apollo.MutationResult<DeleteEquipmentTypeMutation>;
export type DeleteEquipmentTypeMutationOptions = Apollo.BaseMutationOptions<DeleteEquipmentTypeMutation, DeleteEquipmentTypeMutationVariables>;
export const SaveEquipmentTypeDocument = gql`
    mutation SaveEquipmentType($projectId: Int!, $equipmentType: EquipmentTypeInput!) {
  saveEquipmentType(projectId: $projectId, equipmentType: $equipmentType)
}
    `;
export type SaveEquipmentTypeMutationFn = Apollo.MutationFunction<SaveEquipmentTypeMutation, SaveEquipmentTypeMutationVariables>;

/**
 * __useSaveEquipmentTypeMutation__
 *
 * To run a mutation, you first call `useSaveEquipmentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEquipmentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEquipmentTypeMutation, { data, loading, error }] = useSaveEquipmentTypeMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      equipmentType: // value for 'equipmentType'
 *   },
 * });
 */
export function useSaveEquipmentTypeMutation(baseOptions?: Apollo.MutationHookOptions<SaveEquipmentTypeMutation, SaveEquipmentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveEquipmentTypeMutation, SaveEquipmentTypeMutationVariables>(SaveEquipmentTypeDocument, options);
      }
export type SaveEquipmentTypeMutationHookResult = ReturnType<typeof useSaveEquipmentTypeMutation>;
export type SaveEquipmentTypeMutationResult = Apollo.MutationResult<SaveEquipmentTypeMutation>;
export type SaveEquipmentTypeMutationOptions = Apollo.BaseMutationOptions<SaveEquipmentTypeMutation, SaveEquipmentTypeMutationVariables>;
export const GetEquipmentTypesDocument = gql`
    query GetEquipmentTypes($projectId: Int!) {
  equipmentTypes(projectId: $projectId) {
    id
    name
    apiManaged
    states {
      ... on EquipmentSelectionState {
        id
        name
        description
        options {
          id
          name
          color
        }
      }
      ... on EquipmentNumberState {
        id
        name
        description
      }
      ... on EquipmentTextState {
        id
        name
        description
      }
    }
    actions {
      id
      name
      description
    }
  }
}
    `;

/**
 * __useGetEquipmentTypesQuery__
 *
 * To run a query within a React component, call `useGetEquipmentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEquipmentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEquipmentTypesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetEquipmentTypesQuery(baseOptions: Apollo.QueryHookOptions<GetEquipmentTypesQuery, GetEquipmentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEquipmentTypesQuery, GetEquipmentTypesQueryVariables>(GetEquipmentTypesDocument, options);
      }
export function useGetEquipmentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEquipmentTypesQuery, GetEquipmentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEquipmentTypesQuery, GetEquipmentTypesQueryVariables>(GetEquipmentTypesDocument, options);
        }
export type GetEquipmentTypesQueryHookResult = ReturnType<typeof useGetEquipmentTypesQuery>;
export type GetEquipmentTypesLazyQueryHookResult = ReturnType<typeof useGetEquipmentTypesLazyQuery>;
export type GetEquipmentTypesQueryResult = Apollo.QueryResult<GetEquipmentTypesQuery, GetEquipmentTypesQueryVariables>;
export const GetEquipmentAssignmentsForProjectDocument = gql`
    query GetEquipmentAssignmentsForProject($projectId: Int!) {
  sites(projectId: $projectId) {
    id
    name
    equipment {
      id
      name
      details
      sublocation
      equipmentType {
        id
        name
        apiManaged
      }
    }
  }
}
    `;

/**
 * __useGetEquipmentAssignmentsForProjectQuery__
 *
 * To run a query within a React component, call `useGetEquipmentAssignmentsForProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEquipmentAssignmentsForProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEquipmentAssignmentsForProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetEquipmentAssignmentsForProjectQuery(baseOptions: Apollo.QueryHookOptions<GetEquipmentAssignmentsForProjectQuery, GetEquipmentAssignmentsForProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEquipmentAssignmentsForProjectQuery, GetEquipmentAssignmentsForProjectQueryVariables>(GetEquipmentAssignmentsForProjectDocument, options);
      }
export function useGetEquipmentAssignmentsForProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEquipmentAssignmentsForProjectQuery, GetEquipmentAssignmentsForProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEquipmentAssignmentsForProjectQuery, GetEquipmentAssignmentsForProjectQueryVariables>(GetEquipmentAssignmentsForProjectDocument, options);
        }
export type GetEquipmentAssignmentsForProjectQueryHookResult = ReturnType<typeof useGetEquipmentAssignmentsForProjectQuery>;
export type GetEquipmentAssignmentsForProjectLazyQueryHookResult = ReturnType<typeof useGetEquipmentAssignmentsForProjectLazyQuery>;
export type GetEquipmentAssignmentsForProjectQueryResult = Apollo.QueryResult<GetEquipmentAssignmentsForProjectQuery, GetEquipmentAssignmentsForProjectQueryVariables>;
export const RemoveEquipmentFromSiteDocument = gql`
    mutation RemoveEquipmentFromSite($id: Int!) {
  deleteSiteEquipment(siteEquipmentId: $id)
}
    `;
export type RemoveEquipmentFromSiteMutationFn = Apollo.MutationFunction<RemoveEquipmentFromSiteMutation, RemoveEquipmentFromSiteMutationVariables>;

/**
 * __useRemoveEquipmentFromSiteMutation__
 *
 * To run a mutation, you first call `useRemoveEquipmentFromSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEquipmentFromSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEquipmentFromSiteMutation, { data, loading, error }] = useRemoveEquipmentFromSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveEquipmentFromSiteMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEquipmentFromSiteMutation, RemoveEquipmentFromSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEquipmentFromSiteMutation, RemoveEquipmentFromSiteMutationVariables>(RemoveEquipmentFromSiteDocument, options);
      }
export type RemoveEquipmentFromSiteMutationHookResult = ReturnType<typeof useRemoveEquipmentFromSiteMutation>;
export type RemoveEquipmentFromSiteMutationResult = Apollo.MutationResult<RemoveEquipmentFromSiteMutation>;
export type RemoveEquipmentFromSiteMutationOptions = Apollo.BaseMutationOptions<RemoveEquipmentFromSiteMutation, RemoveEquipmentFromSiteMutationVariables>;
export const ProjectUsersDocument = gql`
    query projectUsers($projectId: Int!) {
  project(id: $projectId) {
    users {
      oryId
      name
      jobTitle
      avatarUrl
      email
      superuser
      creationTime
      lastActivity
      projectAdministrator
      alerts
      asc
      manualHydrography
      read
      events
      equipment
      plankton
      seaLice
      alarmSites
    }
  }
}
    `;

/**
 * __useProjectUsersQuery__
 *
 * To run a query within a React component, call `useProjectUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectUsersQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectUsersQuery(baseOptions: Apollo.QueryHookOptions<ProjectUsersQuery, ProjectUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectUsersQuery, ProjectUsersQueryVariables>(ProjectUsersDocument, options);
      }
export function useProjectUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectUsersQuery, ProjectUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectUsersQuery, ProjectUsersQueryVariables>(ProjectUsersDocument, options);
        }
export type ProjectUsersQueryHookResult = ReturnType<typeof useProjectUsersQuery>;
export type ProjectUsersLazyQueryHookResult = ReturnType<typeof useProjectUsersLazyQuery>;
export type ProjectUsersQueryResult = Apollo.QueryResult<ProjectUsersQuery, ProjectUsersQueryVariables>;
export const DeleteAlarmGroupDocument = gql`
    mutation DeleteAlarmGroup($alarmGroupId: Int!) {
  deleteAlarmGroup(alarmGroupId: $alarmGroupId)
}
    `;
export type DeleteAlarmGroupMutationFn = Apollo.MutationFunction<DeleteAlarmGroupMutation, DeleteAlarmGroupMutationVariables>;

/**
 * __useDeleteAlarmGroupMutation__
 *
 * To run a mutation, you first call `useDeleteAlarmGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlarmGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlarmGroupMutation, { data, loading, error }] = useDeleteAlarmGroupMutation({
 *   variables: {
 *      alarmGroupId: // value for 'alarmGroupId'
 *   },
 * });
 */
export function useDeleteAlarmGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAlarmGroupMutation, DeleteAlarmGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAlarmGroupMutation, DeleteAlarmGroupMutationVariables>(DeleteAlarmGroupDocument, options);
      }
export type DeleteAlarmGroupMutationHookResult = ReturnType<typeof useDeleteAlarmGroupMutation>;
export type DeleteAlarmGroupMutationResult = Apollo.MutationResult<DeleteAlarmGroupMutation>;
export type DeleteAlarmGroupMutationOptions = Apollo.BaseMutationOptions<DeleteAlarmGroupMutation, DeleteAlarmGroupMutationVariables>;
export const GetAlarmGroupsDocument = gql`
    query GetAlarmGroups($projectId: Int!) {
  alarmGroups(projectId: $projectId) {
    id
    name
    description
    unknownIntervalSetting
    thresholds {
      id
      measure
      value
      operator
      timeDimension
      filters
    }
    frequency {
      interval
      unit
    }
    siteAlarms {
      id
      suspended
      notify {
        id
        value
        type
      }
      contactLists {
        id
        contacts
      }
      site {
        id
        name
        smbId
        sublocations
      }
    }
  }
}
    `;

/**
 * __useGetAlarmGroupsQuery__
 *
 * To run a query within a React component, call `useGetAlarmGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlarmGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlarmGroupsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetAlarmGroupsQuery(baseOptions: Apollo.QueryHookOptions<GetAlarmGroupsQuery, GetAlarmGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlarmGroupsQuery, GetAlarmGroupsQueryVariables>(GetAlarmGroupsDocument, options);
      }
export function useGetAlarmGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAlarmGroupsQuery, GetAlarmGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlarmGroupsQuery, GetAlarmGroupsQueryVariables>(GetAlarmGroupsDocument, options);
        }
export type GetAlarmGroupsQueryHookResult = ReturnType<typeof useGetAlarmGroupsQuery>;
export type GetAlarmGroupsLazyQueryHookResult = ReturnType<typeof useGetAlarmGroupsLazyQuery>;
export type GetAlarmGroupsQueryResult = Apollo.QueryResult<GetAlarmGroupsQuery, GetAlarmGroupsQueryVariables>;
export const SetAlarmSuspensionDocument = gql`
    mutation SetAlarmSuspension($siteAlarmId: Int!, $suspended: Boolean!) {
  setSiteAlarmSuspension(siteAlarmId: $siteAlarmId, suspended: $suspended)
}
    `;
export type SetAlarmSuspensionMutationFn = Apollo.MutationFunction<SetAlarmSuspensionMutation, SetAlarmSuspensionMutationVariables>;

/**
 * __useSetAlarmSuspensionMutation__
 *
 * To run a mutation, you first call `useSetAlarmSuspensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAlarmSuspensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAlarmSuspensionMutation, { data, loading, error }] = useSetAlarmSuspensionMutation({
 *   variables: {
 *      siteAlarmId: // value for 'siteAlarmId'
 *      suspended: // value for 'suspended'
 *   },
 * });
 */
export function useSetAlarmSuspensionMutation(baseOptions?: Apollo.MutationHookOptions<SetAlarmSuspensionMutation, SetAlarmSuspensionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAlarmSuspensionMutation, SetAlarmSuspensionMutationVariables>(SetAlarmSuspensionDocument, options);
      }
export type SetAlarmSuspensionMutationHookResult = ReturnType<typeof useSetAlarmSuspensionMutation>;
export type SetAlarmSuspensionMutationResult = Apollo.MutationResult<SetAlarmSuspensionMutation>;
export type SetAlarmSuspensionMutationOptions = Apollo.BaseMutationOptions<SetAlarmSuspensionMutation, SetAlarmSuspensionMutationVariables>;
export const SaveContactListDocument = gql`
    mutation SaveContactList($id: Int, $contactList: ContactListArgs!) {
  saveContactList(id: $id, contactListArgs: $contactList) {
    id
    name
    contacts
  }
}
    `;
export type SaveContactListMutationFn = Apollo.MutationFunction<SaveContactListMutation, SaveContactListMutationVariables>;

/**
 * __useSaveContactListMutation__
 *
 * To run a mutation, you first call `useSaveContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveContactListMutation, { data, loading, error }] = useSaveContactListMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contactList: // value for 'contactList'
 *   },
 * });
 */
export function useSaveContactListMutation(baseOptions?: Apollo.MutationHookOptions<SaveContactListMutation, SaveContactListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveContactListMutation, SaveContactListMutationVariables>(SaveContactListDocument, options);
      }
export type SaveContactListMutationHookResult = ReturnType<typeof useSaveContactListMutation>;
export type SaveContactListMutationResult = Apollo.MutationResult<SaveContactListMutation>;
export type SaveContactListMutationOptions = Apollo.BaseMutationOptions<SaveContactListMutation, SaveContactListMutationVariables>;
export const DeleteContactListDocument = gql`
    mutation DeleteContactList($id: Int!) {
  deleteContactList(id: $id)
}
    `;
export type DeleteContactListMutationFn = Apollo.MutationFunction<DeleteContactListMutation, DeleteContactListMutationVariables>;

/**
 * __useDeleteContactListMutation__
 *
 * To run a mutation, you first call `useDeleteContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactListMutation, { data, loading, error }] = useDeleteContactListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactListMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactListMutation, DeleteContactListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactListMutation, DeleteContactListMutationVariables>(DeleteContactListDocument, options);
      }
export type DeleteContactListMutationHookResult = ReturnType<typeof useDeleteContactListMutation>;
export type DeleteContactListMutationResult = Apollo.MutationResult<DeleteContactListMutation>;
export type DeleteContactListMutationOptions = Apollo.BaseMutationOptions<DeleteContactListMutation, DeleteContactListMutationVariables>;