import { default as AverageWeight } from 'components/Charts/AverageWeight/Chart';
import { default as SiteConditions } from 'components/Charts/Conditions/Chart';
import { ProjectContextType } from 'contexts/ProjectContext';
import subHours from 'date-fns/subHours';
import { Site } from 'graphql/generated';
import { default as Mortality } from '../AbsoluteMortality/Chart';
import { default as AllLicePerFish } from '../AllLicePerFish/Chart';
import { default as Hydrography } from '../Hydrography/Chart';
import { default as MotileLicePerFish } from '../MotileLicePerFish/Chart';
import NotIncluded from '../NotIncluded';
import { default as PercentageMortality } from '../PercentageMortality/Chart';
import DiscreteSampleCombo from '../Plankton/DiscreteSampleCombo';
import { default as PlanktonSpeciesPresent } from '../PlanktonSpeciesPresent/Chart';
import SelectPlot from '../SelectPlot';
import { default as SFR } from '../SpecificFeedingRate/Chart';
import { default as TotalFish } from '../TotalFish/Chart';
import { default as Treatments } from '../Treatments/Chart';
import { BaseChartProps } from '../types';
import { useMemo } from 'react';

const rawDataOptions = [
  { value: 'mortalityCount', label: 'Mortality - Count' },
  { value: 'mortalityPercent', label: 'Mortality - Percent' },
  { value: 'oxygenConcentration', label: 'Oxygen - mgl' },
  { value: 'oxygenSat', label: 'Oxygen - Saturation' },
  { value: 'temp', label: 'Temperature' },
  { value: 'planktonDiscrete', label: 'Plankton - Discrete' },
  { value: 'planktonTow', label: 'Plankton - Tow' },
  { value: 'liceMotile', label: 'Lice - Motile Stages' },
  { value: 'liceAll', label: 'Lice - All Stages' },
  { value: 'salinity', label: 'Salinity' },
  { value: 'sfr', label: 'Appetite - SFR' },
  { value: 'treatment', label: 'Disturbance - Treatments' },
  { value: 'totalFish', label: 'Stocking - Total Fish' },
  { value: 'avgWeight', label: 'Stocking - Average Weight' }
];
const tuple = <T extends [any] | any[]>(args: T): T => args;

const SIWIRawData = ({
  site,
  project,
  skip,
  selection
}: {
  site: Site;
  project: ProjectContextType;
  skip?: boolean;
  selection: string;
}) => {
  const params: BaseChartProps = {
    settings: {
      site,
      project
    },
    skip: skip,
    // plotOverrides: { layout: { height: 450 } },
    dateRange: 'Last 336 hours',
    chartRange: tuple([subHours(new Date(), 336), new Date()])
  };

  const chartMap = useMemo(() => {
    return {
      none: { component: SelectPlot, params: { minH: '450px' } },
      mortalityCount: { component: Mortality, params },
      mortalityPercent: { component: PercentageMortality, params },
      liceMotile: { component: MotileLicePerFish, params },
      liceAll: {
        component: AllLicePerFish,
        params: {
          ...params,
          settings: { selectedSublocation: 'All', ...params.settings }
        }
      },
      sfr: { component: SFR, params },
      treatment: {
        component: Treatments,
        params: {
          ...params,
          settings: { selectedSublocation: 'All', ...params.settings }
        }
      },
      totalFish: { component: TotalFish, params },
      avgWeight: { component: AverageWeight, params },
      temp: {
        component: SiteConditions,
        params: {
          ...params,
          settings: {
            ...params.settings,
            measures: ['waterTempAvg']
          }
        }
      },
      oxygenConcentration: {
        component: SiteConditions,
        params: {
          ...params,
          settings: {
            ...params.settings,
            measures: ['oxygenConcentrationAvg']
          }
        }
      },
      oxygenSat: {
        component: SiteConditions,
        params: {
          ...params,
          settings: {
            ...params.settings,
            measures: ['oxygenSaturationAvg']
          }
        }
      },
      planktonDiscrete: { component: DiscreteSampleCombo, params },
      planktonTow: { component: PlanktonSpeciesPresent, params },
      salinity: {
        component: Hydrography,
        params: {
          ...params,
          settings: {
            ...params.settings,
            useLocalMinMax: true,
            useSensor: false,
            skipForecasting: true,
            parameters: ['salinityPsu']
          }
        }
      }
    };
  }, []);

  if (project.freeTrial) {
    return <NotIncluded minH="450px" />;
  }

  const selectedChart = chartMap[selection];

  return <selectedChart.component {...selectedChart.params} />;
};

export { SIWIRawData, rawDataOptions };
