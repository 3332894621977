import { Box, HStack, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import ChartTile from 'components/Charts/ChartTile';
import SiteConditions from 'components/Charts/Conditions/Chart';
import ConditionsNow from 'components/Charts/Conditions/Now';
import BargeHydrographyTile from 'components/Charts/Hydrography/Tile';
import Rivers from 'components/Charts/Rivers/Chart';
import RiverController from 'components/Charts/Rivers/Controller';
import { UseRiverTraceParams } from 'components/Charts/Rivers/useRiversTrace';
import { UseTideTraceParams } from 'components/Charts/Tides/useTideTrace';
import Tides from 'components/Charts/Tides/Chart';
import TideController from 'components/Charts/Tides/Controller';
import { Site } from 'graphql/generated';
import { useState } from 'react';
import { ProjectContextType } from 'contexts/ProjectContext';

type EnvironmentProps = {
  site?: Site;
  project?: ProjectContextType;
};

const Environment = (props: EnvironmentProps) => {
  const site = props.site ? props.site : null;
  const project = props.project ? props.project : null;
  const [useRiverTraceParams, setRiverTraceParams] = useState<UseRiverTraceParams>({
    selectedRiverStations: [],
    searchRadius: { value: '50', label: '50' },
    isWaterLevel: false,
    site: site,
    project
  });
  const [useTideTraceParams, setTideTraceParams] = useState<UseTideTraceParams>({
    showNow: true,
    showAxisTitle: true,
    selectedTideStations: [],
    searchRadius: { label: '5000', value: '5000' },
    lunarParameter: { label: 'Water Level (m)', value: 'TessTides.waterlevelAvg' },
    site: site,
    project
  });
  return (
    <Box>
      <HStack align="start" w="100%">
        <ChartTile
          w="70%"
          heading="Site Conditions"
          site={site}
          project={project}
          tooltip={
            <UnorderedList>
              <ListItem>
                The Site Conditions line chart displays the most recent 6 hours at high resolution
                to focus on short term variability (5 minute aggregated). Data older than 6 hours is
                displayed at a lower resolution to capture general trends (hour scale).
              </ListItem>
              <ListItem>
                Data: Continuous sensor data from the barge, in pens, and other locations. Data is
                from a fixed depth, unless specified.
              </ListItem>
              <ListItem>Data: Forecasted tide data on the right hand y-axis</ListItem>
              <ListItem>Oxygen SOP thresholds indicate Caution or Danger levels</ListItem>
              <ListItem>
                <b>*</b> An asterisk next to the location name (e.g., cage-1*) indicates data is
                ingested via Scoot&apos;s GDB data broker.
              </ListItem>
            </UnorderedList>
          }>
          <SiteConditions skip={false} settings={{ site, project, useHires: true }} />
        </ChartTile>
        <ChartTile
          w="30%"
          ml="0"
          heading="Conditions Now"
          site={site}
          project={project}
          tooltip={
            <UnorderedList>
              <ListItem>
                The Conditions Now bar chart displays the most recent sensor values. Data that
                hasn&apos;t updated in at least 1 hour is displayed with a gray border and a
                transparent color. Sensors that have not updated in the past 3 days are excluded
                from the chart.
              </ListItem>
              <ListItem>
                Data: Continuous sensor data from the barge, in pens, and other locations. Data is
                from a fixed depth, unless specified.
              </ListItem>
              <ListItem>Oxygen SOP thresholds indicate Caution or Danger level.</ListItem>
            </UnorderedList>
          }>
          <ConditionsNow skip={false} settings={{ project, site }} />
        </ChartTile>
      </HStack>

      <BargeHydrographyTile site={site} project={project} />

      {site?.smbId && (
        <ChartTile
          hash="tides"
          heading="Tides"
          site={site}
          project={project}
          tooltip={
            <>
              <UnorderedList>
                <ListItem>
                  <Text>
                    <b>Tidal Water Level (m)</b>: is the predicted water level at the tide gauge.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    <b>Diurnal Inequality Index (DII)</b>: measures differences in tidal waterlevel
                    range across daily tide cycles. When consecutive tidal ranges are most
                    different, DII is maximized (that is, near 1) and tidal mixing is relativley
                    low. When DII is close to zero, tidal ranges are similar across consective tides
                    and tidal mixing is relatively strong.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    <b>Lunar Index (LI)</b>: measures the intensity of tidal amplitudes across the
                    spring-neap cycle. Spring tides as associated with full moons (LI = 1) and new
                    moons (LI = 0) and can mean greater tidal mixing. Neap tides are associated with
                    quarter moons (LI = 0.5) and can mean reduced tidal mixing.
                  </Text>
                </ListItem>
              </UnorderedList>
            </>
          }>
          <Tides
            settings={useTideTraceParams}
            skip={false}
            control={({ lunarParameter }) => (
              <TideController
                chartSettings={{ ...useTideTraceParams, lunarParameter: lunarParameter }}
                setChartSettings={setTideTraceParams}
              />
            )}
          />
        </ChartTile>
      )}
      {site?.smbId && (
        <ChartTile
          hash="rivers"
          heading="Nearby River Gauges"
          site={site}
          project={project}
          tooltip={
            <UnorderedList>
              <ListItem>
                River gauges within the selected radius in kilometers are included
              </ListItem>
              <ListItem>
                These gauges (*marked with an asterisk in the chart key) have been configured for
                inclusion at this site:{' '}
                {site.riverStations?.include?.map((d, idx) => (
                  <Text style={{ textIndent: 20, fontSize: 12 }} key={idx}>
                    {d}{' '}
                  </Text>
                ))}
              </ListItem>
              <ListItem>
                These gauges have been configured for exclusion from this site:{' '}
                {site.riverStations?.exclude?.map((d, idx) => (
                  <Text style={{ textIndent: 20, fontSize: 12 }} key={idx}>
                    {d}{' '}
                  </Text>
                ))}
              </ListItem>
              <ListItem>
                Contact your SeaState administrator or submit a Feedback ticket to modify the river
                gauge inclusion/exclusion lists for individual sites.
              </ListItem>
            </UnorderedList>
          }>
          <Rivers
            skip={false}
            settings={useRiverTraceParams}
            control={({ allRiverStations }) => (
              <RiverController
                chartSettings={{ ...useRiverTraceParams }}
                setChartSettings={setRiverTraceParams}
                chartInputs={{ allRiverStations }}
              />
            )}
          />
        </ChartTile>
      )}
    </Box>
  );
};

export default Environment;
