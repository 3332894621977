import { gql, useMutation, useQuery } from '@apollo/client';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Button,
  ButtonGroup,
  Center,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Text,
  VStack,
  useRadioGroup,
  useToast
} from '@chakra-ui/react';
import { permissions } from '@scoot/permissions';
import { AlarmGroupCard } from 'components/Alarm/AlarmGroupCard';
import { AlarmStats } from 'components/Alarm/AlarmStats';
import EditAlarmModal, { EditAlarmForm } from 'components/Alarm/EditAlarmModal';
import { GroupModal } from 'components/Alarm/GroupModal';
import { RecentAlerts } from 'components/Alarm/RecentAlerts';
import { TestEmailModal } from 'components/Alarm/TestEmailModal';
import ViewAlarmModal from 'components/Alarm/ViewAlarmModal';
import RadioCard from 'components/Forms/RadioCard';
import { Notice } from 'components/Notice';
import { RenderError } from 'components/Pages/RenderError';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { ContactType, GetAlarmGroupsQuery, SiteAlarm } from 'graphql/generated';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

const DELETE_ALARM_GROUP = gql`
  mutation DeleteAlarmGroup($alarmGroupId: Int!) {
    deleteAlarmGroup(alarmGroupId: $alarmGroupId)
  }
`;

const GET_ALARM_GROUPS = gql`
  query GetAlarmGroups($projectId: Int!) {
    alarmGroups(projectId: $projectId) {
      id
      name
      description
      unknownIntervalSetting
      thresholds {
        id
        measure
        value
        operator
        timeDimension
        filters
      }
      frequency {
        interval
        unit
      }
      siteAlarms {
        id
        suspended
        notify {
          id
          value
          type
        }
        contactLists {
          id
          contacts
        }
        site {
          id
          name
          smbId
          sublocations
        }
      }
    }
  }
`;

const SET_SUSPENSION = gql`
  mutation SetAlarmSuspension($siteAlarmId: Int!, $suspended: Boolean!) {
    setSiteAlarmSuspension(siteAlarmId: $siteAlarmId, suspended: $suspended)
  }
`;

export type AlarmGroupSelection = GetAlarmGroupsQuery['alarmGroups'][0];

const AlarmIndex = () => {
  const project = useContext(ProjectContext);
  const user = useContext(UserContext);
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const [alarmFilter, setAlarmFilter] = useState('');
  const [viewingBySite, setViewingBySite] = useState(false);
  const [viewingByNotification, setViewingByNotification] = useState(false);
  const [testEmailModalOpen, setTestEmailModalOpen] = useState(false);
  const [creatingNewAlarm, setCreatingNewAlarm] = useState(false);
  const [selectedAlarmGroupId, setSelectedAlarmGroupId] = useState<string | null>();

  const { getRadioProps } = useRadioGroup({
    value: selectedAlarmGroupId?.toString(),
    onChange: (agId) => setSelectedAlarmGroupId(agId)
  });

  const [deleteAlarmGroup] = useMutation(DELETE_ALARM_GROUP, {
    refetchQueries: ['GetAlarmGroups']
  });

  const [setAlarmSuspension] = useMutation(SET_SUSPENSION, {
    refetchQueries: ['GetAlarmGroups']
  });

  const { data, loading, error } = useQuery<GetAlarmGroupsQuery>(GET_ALARM_GROUPS, {
    variables: { projectId: project.id },
    pollInterval: 150000
  });

  const selectedAlarmGroup = useMemo(() => {
    if (!data?.alarmGroups) return null;
    return data?.alarmGroups.find((ag) => ag.id.toString() === selectedAlarmGroupId);
  }, [selectedAlarmGroupId, data?.alarmGroups]);

  const [editingAlarmGroup, setEditingAlarmGroup] = useState<EditAlarmForm>();

  const onAlarmGroupDelete = async ({ groupId }: { groupId: number }) => {
    try {
      const resp = await deleteAlarmGroup({ variables: { alarmGroupId: groupId } });
      if (!resp.data.deleteAlarmGroup) throw new Error('Could not delete alarm group');
      toast({
        status: 'success',
        description: 'Deleted alarm group'
      });
    } catch (e) {
      console.error(e);
      toast({
        status: 'error',
        description: 'Error deleting alarm group'
      });
    }
  };

  const editAlarm = ({ groupId }: { groupId: number }) => {
    const group = data.alarmGroups.find((ag) => ag.id === groupId);

    const siteAlarms: EditAlarmForm['siteAlarms'] = group.siteAlarms.map((s) => {
      return {
        site: { ...s.site, sublocations: s.site?.sublocations ?? [] },
        notify: s.notify,
        contactLists: s.contactLists
      };
    });

    setEditingAlarmGroup({
      id: groupId,
      notifyAll: [],
      siteAlarms: [
        ...siteAlarms.map((sa) => {
          return {
            ...sa,
            notify: [
              ...sa.notify,
              //Splat in contact list info. To be filled in later once contact lists are actually loaded in AlarmNotificationList
              //@ts-ignore
              ...sa.contactLists.map((cl) => ({
                value: '',
                type: ContactType.Email,
                notificationListId: cl.id
              }))
            ]
          };
        })
      ],
      thresholds: group.thresholds.map((threshold) => {
        return {
          ...threshold,
          additionalFilters: Array.isArray(threshold.filters)
            ? threshold.filters
            : JSON.parse(threshold.filters)
        };
      }),
      frequency: group.frequency as EditAlarmForm['frequency'],
      name: group.name,
      description: group.description,
      unknownIntervalSetting: group.unknownIntervalSetting
    });
  };

  const [viewingAlarm, setViewingAlarm] = useState<SiteAlarm | null>();

  useEffect(() => {
    const siteId = searchParams.get('siteId');
    const alarmGroupId = searchParams.get('alarmGroupId');
    if (siteId && alarmGroupId && !loading && data.alarmGroups) {
      const viewingAlarm = data.alarmGroups
        .filter((ag) => ag.id === Number(alarmGroupId))
        .flatMap((ag) => ag.siteAlarms)
        .find((sa) => sa.site.id === Number(siteId));

      //@ts-ignore
      setViewingAlarm(viewingAlarm);
    } else {
      setViewingAlarm(null);
    }
  }, [searchParams, loading, data]);

  useEffect(() => {
    if (data?.alarmGroups && data.alarmGroups.length > 0) {
      if (!selectedAlarmGroupId) {
        setSelectedAlarmGroupId(data.alarmGroups[0].id.toString());
      }
    }
  }, [data?.alarmGroups]);

  const filteredAlarms = useMemo(() => {
    if (!data?.alarmGroups) return [];

    if (isEmpty(alarmFilter)) return data.alarmGroups;

    return data.alarmGroups.filter((ag) =>
      ag.name.toLowerCase().includes(alarmFilter.toLowerCase())
    );
  }, [data?.alarmGroups, alarmFilter]);

  const alarmsBySite = useMemo(() => {
    if (!data?.alarmGroups) return {};

    return data.alarmGroups.reduce((acc, curr) => {
      const siteNames = curr.siteAlarms.map((s) => s.site.name);

      siteNames.forEach((siteName) => {
        if (Object.keys(acc).includes(siteName)) {
          acc[siteName] = [...acc[siteName], curr.name];
        } else {
          acc[siteName] = [curr.name];
        }
      });

      return acc;
    }, {});
  }, [data?.alarmGroups]);

  const alarmsByNotification = useMemo(() => {
    if (!data?.alarmGroups) return {};

    return data.alarmGroups.reduce((acc, curr) => {
      curr.siteAlarms.forEach((sa) => {
        const allContacts = [
          ...sa.notify.map((n) => n.value),
          ...sa.contactLists.flatMap((cl) => cl.contacts)
        ];

        allContacts.forEach((contact) => {
          if (Object.keys(acc).includes(contact)) {
            acc[contact] = [...acc[contact], `${sa.site.name} - ${curr.name}`];
          } else {
            acc[contact] = [`${sa.site.name} - ${curr.name}`];
          }
        });
      });

      return acc;
    }, {});
  }, [data?.alarmGroups]);

  if (error) {
    return <RenderError />;
  }

  return (
    <>
      <Center>
        <Text data-cypress="alarms-header" fontSize="4xl" margin="1em 0">
          Alarms
        </Text>
      </Center>
      <AlarmStats />
      <RecentAlerts />
      <HStack my="15px" justifyContent="space-between">
        {permissions.canManageAlertsForProject(user, project.id) && (
          <ButtonGroup>
            <Button
              leftIcon={<AddIcon />}
              colorScheme="blue"
              onClick={() => setCreatingNewAlarm(true)}>
              <Text data-cypress="new-alarm-btn">New Alarm</Text>
            </Button>
            <Link to={`/project/${project.id}/alarm/notification-list`}>
              <Button>Notification Lists</Button>
            </Link>
            {user.superuser && (
              <Button onClick={() => setTestEmailModalOpen(true)} colorScheme="red">
                Send Test Email
              </Button>
            )}
          </ButtonGroup>
        )}
        <ButtonGroup>
          <Button onClick={() => setViewingBySite(true)}>By Site</Button>
          <Button onClick={() => setViewingByNotification(true)}>By Notification</Button>
        </ButtonGroup>
      </HStack>
      {creatingNewAlarm && <EditAlarmModal onClose={() => setCreatingNewAlarm(false)} />}
      {editingAlarmGroup && (
        <EditAlarmModal
          editingAlarm={editingAlarmGroup}
          onClose={() => setEditingAlarmGroup(null)}
        />
      )}
      {viewingAlarm && (
        <ViewAlarmModal
          alarm={viewingAlarm}
          onClose={() => {
            setSearchParams({});
          }}
        />
      )}
      {loading || !data ? (
        <Skeleton w="100%" h="600px" />
      ) : (
        <>
          {data?.alarmGroups.length === 0 && !loading ? (
            <Notice w="100%">
              <Text>Create an Alarm Group to get started utilizing SeaState Alarms.</Text>
            </Notice>
          ) : (
            <HStack alignItems="start" mb="100px" w="100%">
              <VStack w="30%">
                <InputGroup>
                  <InputRightElement p="5px">
                    <IconButton
                      size="sm"
                      color="blue.500"
                      icon={<DeleteIcon />}
                      onClick={() => setAlarmFilter('')}
                      aria-label="Clear Alarm Group Filter"
                    />
                  </InputRightElement>
                  <Input
                    mb="5px"
                    w="100%"
                    placeholder="Alarm Group Filter..."
                    value={alarmFilter}
                    onChange={(e) => setAlarmFilter(e.currentTarget.value)}
                  />
                </InputGroup>

                <VStack
                  data-cypress="alarm-group-list"
                  w="100%"
                  overflowY="scroll"
                  maxH="800px"
                  p="5px"
                  border="2px"
                  borderRadius="6px"
                  borderColor="gray.100">
                  {filteredAlarms.map((alarmGroup, i) => {
                    return (
                      <RadioCard
                        boxProps={{ 'data-cypress': `alarm-group-${i}`, w: '100%' }}
                        key={alarmGroup.id}
                        {...getRadioProps({ value: alarmGroup.id.toString() })}>
                        {alarmGroup.name}
                      </RadioCard>
                    );
                  })}
                </VStack>
              </VStack>
              {!selectedAlarmGroup && data?.alarmGroups.length > 0 && (
                <Notice w="70%">
                  <Text>Select an Alarm Group on the left to get started.</Text>
                </Notice>
              )}
              {selectedAlarmGroup && (
                <AlarmGroupCard
                  data-cypress="alarm-group"
                  w="80%"
                  alarmGroup={selectedAlarmGroup}
                  onSiteAlarmSelect={({ siteId }) => {
                    setSearchParams({
                      alarmGroupId: selectedAlarmGroup.id.toString(),
                      siteId: siteId.toString()
                    });
                  }}
                  onSiteAlarmSuspend={async ({ siteAlarmId, alarmGroupId, suspend }) => {
                    const ag = data.alarmGroups.find((ag) => ag.id === alarmGroupId);
                    const siteAlarm = ag.siteAlarms.find((sa) => sa.id === siteAlarmId);

                    await setAlarmSuspension({
                      variables: { siteAlarmId: siteAlarm.id, suspended: suspend }
                    });
                  }}
                  onAlarmGroupEdit={
                    permissions.canManageAlertsForProject(user, project.id)
                      ? () => editAlarm({ groupId: selectedAlarmGroup.id })
                      : undefined
                  }
                  onAlarmGroupDelete={
                    permissions.canManageAlertsForProject(user, project.id)
                      ? () => onAlarmGroupDelete({ groupId: selectedAlarmGroup.id })
                      : undefined
                  }
                />
              )}
            </HStack>
          )}
        </>
      )}
      <TestEmailModal isOpen={testEmailModalOpen} onClose={() => setTestEmailModalOpen(false)} />
      <GroupModal
        title="Alarms by Site"
        items={alarmsBySite}
        isOpen={viewingBySite}
        onClose={() => setViewingBySite(false)}
      />
      <GroupModal
        title="Alarms by Notification"
        items={alarmsByNotification}
        isOpen={viewingByNotification}
        onClose={() => setViewingByNotification(false)}
      />
    </>
  );
};

export default AlarmIndex;
