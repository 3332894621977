import { EmailIcon, ExternalLinkIcon, PhoneIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Divider,
  HStack,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
  VStack,
  Wrap
} from '@chakra-ui/react';
import Tile from 'components/Tile';
import { ContactType } from 'graphql/generated';
import { EditAlarmForm } from '../EditAlarmModal';

type AlarmSummaryProps = {
  form: EditAlarmForm;
};

const AlarmSummary = ({ form }: AlarmSummaryProps) => {
  return (
    <Box>
      <Center m="20px">
        <Text fontSize="2xl">Summary</Text>
      </Center>

      <Text mb="5px">
        Review and verify your alarm configuration and notification settings. The following alarms
        will be created at <i>each individual</i> site:
      </Text>
      <Wrap p="5px">
        {form.siteAlarms.map(({ site, notify }) => {
          return (
            <Tile data-cypress={`site-alarm-${site.id}`} w="300px" minH="100px" key={site.id}>
              <Center p="10px" backgroundColor="blue.100">
                <Text fontSize="lg">{site.name}</Text>
              </Center>
              <Divider />
              <List m="10px" spacing={3}>
                {notify.length === 0 && <Text fontStyle="italic">No contacts</Text>}
                {notify.map((n, i) => {
                  return (
                    <ListItem data-cypress={`site-alarm-${site.id}-notify-${i}`} key={n.value}>
                      <ListIcon
                        color="blue.500"
                        mb="2px"
                        as={n.type === ContactType.Email ? EmailIcon : PhoneIcon}
                      />
                      <Text display="inline">{n.value}</Text>
                    </ListItem>
                  );
                })}
              </List>
            </Tile>
          );
        })}
      </Wrap>
      <Center m="20px">
        <Text fontSize="2xl">Notification Preview</Text>
      </Center>
      <Center>
        <Tile w="600px" minH="100px">
          <VStack p="0px 15px">
            <Text mt="10px">
              Alarm: {form.name.replace('{site}', form.siteAlarms[0].site.name)}
            </Text>
            {form.description ? (
              <Text>{form.description.replace('{site}', form.siteAlarms[0].site.name)}</Text>
            ) : (
              <Text fontStyle="italic">No description given</Text>
            )}
            <HStack pt="20px" spacing={10}>
              <Link color="blue.600" isExternal>
                View this alarm in SeaState
                <ExternalLinkIcon mx="2px" />
              </Link>
              <Link color="blue.600" isExternal>
                View your site in Seastate
                <ExternalLinkIcon mx="2px" />
              </Link>
            </HStack>
          </VStack>
        </Tile>
      </Center>
      {form.siteAlarms.length > 1 &&
        (form.name.includes('{site}') || form.description.includes('{site}')) && (
          <Center mt="10px">
            <Text fontStyle="italic">Preview shown with the first site name as an example.</Text>
          </Center>
        )}
    </Box>
  );
};

export default AlarmSummary;
