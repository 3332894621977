import { Skeleton } from '@chakra-ui/react';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, uniq } from 'lodash';
import { Data, PlotlyDataLayoutConfig } from 'plotly.js';
import { useMemo } from 'react';
import { LICE_STAGE_NAMES_PROJECT_ID } from 'shared/Utils';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { locationToIndex } from 'shared/functions/location';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot from '../Plot';
import { BaseChartProps, BaseChartSettings } from '../types';
import { ControllerInputs } from './Controller';

type LiceCubeDatum = {
  'TessLiceLookup.sublocation'?: string;
  'Site.id'?: string;
  'TessLice.measuredAt': string;
  'TessLice.measuredAt.day': string;
  'TessLiceLookup.stageId': string;
  'TessLice.avgLicePerFish': number;
};

// Target data structure for plot output
type LiceStructure = {
  [stageId: string]: {
    [measuredAt: string]: {
      [sublocation: string]: {
        cageAvgLice: number;
        cageSumLice: number;
        cageAvgFish: number;
        plotColor: string;
      };
    };
  };
};

type TimeDataStructure = {
  [sublocation: string]: {
    cageAvgLice: number;
    cageSumLice: number;
    cageAvgFish: number;
    plotColor: string;
  };
};

export type ChartSettings = BaseChartSettings & {
  selectedSublocation: string;
};

const Chart = ({
  granularity = 'day',
  dateRange = 'from 30 days ago to 1 day from now',
  chartRange,
  settings: { selectedSublocation, project, ...settings },
  skip,
  control,
  onDataLoaded
}: BaseChartProps<ChartSettings, ControllerInputs>) => {
  selectedSublocation = selectedSublocation ?? 'All';
  const locationDimension = settings.site?.smbId ? 'TessLiceLookup.sublocation' : 'Site.id';

  const transform = (rawData: LiceCubeDatum[]): LiceStructure => {
    const stages = uniq(
      rawData.flatMap((d) => {
        return d['TessLiceLookup.stageId'];
      })
    );
    const sublocationLookupValue = settings.site?.smbId
      ? selectedSublocation
      : Number(
          Object.keys(project.siteNameMappings).find(
            (subloc) => project.siteNameMappings[subloc] === selectedSublocation
          )
        );
    const pallet = createLocationPallet({ locations: stages });
    let filteredDataSublocation = rawData;
    if (!selectedSublocation || selectedSublocation != 'All') {
      // filter to spp.
      filteredDataSublocation = filteredDataSublocation.filter(
        (d) => d[locationDimension] == sublocationLookupValue
      );
    }
    const byStage = groupBy(filteredDataSublocation, 'TessLiceLookup.stageId');
    return Object.entries(byStage).reduce((accStage, [stage, dataAtStage]) => {
      const byMeasuredAt = groupBy(dataAtStage, `TessLice.measuredAt.${granularity}`);
      accStage[stage] = Object.entries(byMeasuredAt).reduce(
        (accTime, [timestamp, dataAtMeasuredAt]) => {
          accTime[timestamp] = {};
          dataAtMeasuredAt.forEach((d) => {
            accTime[timestamp][d[locationDimension]] = {
              cageAvgLice: d['TessLice.avgLicePerFish'],
              cageSumLice: d['TessLice.sum'],
              cageAvgFish: Number(d['TessLice.avgFishCount']),
              plotColor: pallet[d['TessLiceLookup.stageId']]
            };
          });
          return accTime;
        },
        {}
      );
      return accStage;
    }, {});
  };
  const graph = (data: LiceStructure): PlotlyDataLayoutConfig => {
    //@ts-ignore
    const plotData: Data[] = Object.entries(data).map(([stage, stageData]) => {
      const liceTotals = Object.values(stageData).map((timeData) => {
        return (
          Object.values(timeData).reduce((contAcc, contData) => contAcc + contData.cageAvgLice, 0) /
          Object.keys(timeData).length
        ).toFixed(2);
      });
      const colorMap = {};
      Object.values(stageData).flatMap((timeData) => {
        Object.values(timeData).flatMap((d) => {
          colorMap[stage] = d.plotColor;
        });
      });
      const sublocationPlot = {
        mode: 'lines+markers',
        type: 'scattergl',
        x: Object.keys(stageData),
        y: liceTotals,
        name: LICE_STAGE_NAMES_PROJECT_ID[project.id][stage] || stage,
        meta: LICE_STAGE_NAMES_PROJECT_ID[project.id][stage] || stage,
        // hovertemplate: '%{text}<br><b>%{meta}</b><br>Average: %{y:.2f}<br>Date: %{x} <extra></extra>',
        hovertemplate: '<b>%{x}<br><b>%{meta}</b></br>%{text} <extra></extra>',
        text: Object.values(stageData).map((timeData) => {
          const sortedTimeData: TimeDataStructure = Object.keys(timeData)
            .sort((a, b) => locationToIndex(a) - locationToIndex(b))
            .reduce((acc, key) => {
              acc[key] = timeData[key];
              return acc;
            }, {} as TimeDataStructure);
          const totalLice = Object.values(sortedTimeData)
            .reduce((a, b) => a + b.cageSumLice, 0)
            .toFixed(2);
          const totalFish = Object.values(sortedTimeData)
            .reduce((a, b) => a + b.cageAvgFish, 0)
            .toFixed(2);
          const weightedSiteAvg = (Number(totalLice) / Number(totalFish)).toFixed(2);
          const cageAvg = Object.entries(sortedTimeData)
            .map(([sublocation, val]) => {
              //@ts-ignore
              return `<b>${sublocation}</b>: ${val?.cageAvgLice?.toFixed(2) ?? 0}`;
            })
            .join('<br>');
          const summaryText = cageAvg + `<br><b>Average</b>: ${weightedSiteAvg}`;
          return summaryText;
        }),
        legendgroup: stage,
        marker: {
          color: colorMap[stage],
          size: 8
        }
      };
      return sublocationPlot;
    });

    return {
      data: plotData,
      layout: {
        // ...layout,
        autosize: true,
        // hovermode: 'x unified',
        hovermode: 'closest',
        hoverlabel: {
          align: 'left',
          font: { size: 12 }
        },
        // hoverlabel: { namelength: -1 },
        showlegend: true,
        // barmode: 'stack',
        xaxis: {
          tickfont: { size: 16 },
          title: { font: { size: 16 } },
          range: chartRange
        },
        yaxis: {
          showticklabels: true,
          showline: true,
          rangemode: 'tozero',
          title: {
            text: 'Average Lice per Fish',
            font: {
              size: 16
            }
          },
          tickfont: { size: 16 }
        },
        legend: {
          orientation: 'h',
          x: 0,
          y: 1.25,
          font: {
            size: 16
          }
        }
        //@ts-ignore
        // annotations
      }
    };
  };
  const { isLoading, error, plot, resultSet } = useCubeLTG({
    cubeQuery: {
      timeDimensions: [
        {
          dimension: 'TessLice.measuredAt',
          granularity,
          dateRange
        }
      ],
      dimensions: [locationDimension, 'TessLiceLookup.stageId'],
      measures: ['TessLice.avgLicePerFish', 'TessLice.sum', 'TessLice.avgFishCount'],
      filters: settings.site?.smbId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [settings.site?.smbId.toString()]
            },
            { member: 'TessLice.liceCount', operator: 'gte', values: ['0'] }
          ]
        : [{ member: 'TessLice.liceCount', operator: 'gte', values: ['0'] }],
      timezone: project.timezone
    },
    transform,
    graph,
    options: {
      refreshInterval: 900000,
      skip,
      dependencies: { selectedSublocation: selectedSublocation, chartRange },
      onDataLoaded
    }
  });
  const sublocations = useMemo(() => {
    return (
      uniq(
        resultSet
          ?.rawData()
          .map((d) =>
            settings.site?.smbId
              ? d[locationDimension]
              : project.siteNameMappings[d[locationDimension]]
          )
      ).sort((a, b) => locationToIndex(a) - locationToIndex(b)) ?? []
    );
  }, [resultSet]);

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }

  if (plot?.data?.length === 0 && project.freeTrial) {
    return <NotIncluded minH="450px" />;
  } else if (plot?.data?.length === 0 && !project.freeTrial) {
    return (
      <>
        {control ? control({ availableSublocations: sublocations }) : <></>}
        <NoData minH="450px" />
      </>
    );
  }

  return (
    <>
      {control ? control({ availableSublocations: sublocations }) : <></>}
      <Plot className="w-100 lice-stages-plot" {...plot} useResizeHandler={true} />
    </>
  );
};

export default Chart;
