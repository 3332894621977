import {
  Box,
  Button,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  VStack
} from '@chakra-ui/react';
import { ErrorBoundary } from '@sentry/react';
import { ExploreChart, InflatedChart } from 'components/Charts/types';
import { RenderError } from 'components/Pages/RenderError';
import { useMemo, useRef, useState } from 'react';

type ChartEditorModalProps = {
  editingChart?: InflatedChart;
  charts: ExploreChart[];
  onClose: () => void;
  onSave: (inflatedChart: InflatedChart) => void;
};

const ChartEditorModal = ({ editingChart, charts, onClose, onSave }: ChartEditorModalProps) => {
  const first = useMemo(() => charts?.[0], [charts]);
  //@ts-ignore
  const [selectedChart, setSelectedChart] = useState<ExploreChart>(first || editingChart);
  const [chartSettings, setChartSettings] = useState(
    editingChart?.settings ?? selectedChart.initSettings
  );

  // Really gross I know, but the only way I could get
  // The custom chart controller working reasonably well with
  // the 'preview' / useForm workflow.
  const customRef = useRef(null);

  return (
    <Modal size="full" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Chart Editor</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack align="start">
            {charts.length > 0 && (
              <VStack>
                <Tabs
                  w="250px"
                  orientation="vertical"
                  onChange={(idx) => {
                    setSelectedChart(charts[idx]);
                    setChartSettings(charts[idx].initSettings);
                  }}>
                  <TabList>
                    {charts.map((c) => {
                      return <Tab key={c.displayName}>{c.displayName}</Tab>;
                    })}
                  </TabList>
                </Tabs>
              </VStack>
            )}
            <ErrorBoundary
              fallback={
                <Center width={'100vw'} height={'100vh'}>
                  <RenderError />
                </Center>
              }
              showDialog={false}>
              <Box w="100%">
                {selectedChart.control && !selectedChart?.yieldedControl ? (
                  <selectedChart.control
                    ref={customRef}
                    chartSettings={chartSettings}
                    chartInputs={selectedChart.initInputs}
                    setChartSettings={setChartSettings}
                  />
                ) : (
                  <></>
                )}
                <selectedChart.chart
                  settings={{
                    ...chartSettings
                  }}
                  control={(controlInput) => {
                    return (
                      <selectedChart.control
                        chartInputs={{ ...selectedChart?.initInputs, ...controlInput }}
                        chartSettings={chartSettings}
                        setChartSettings={setChartSettings}
                      />
                    );
                  }}
                />
              </Box>
            </ErrorBoundary>
          </HStack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            onClick={() => {
              let customSettings = null;
              if (customRef?.current?.getChartSettings) {
                customSettings = customRef.current.getChartSettings();
              }

              // Handle custom chart display name
              const displayName = selectedChart.custom
                ? customSettings?.displayName
                  ? customSettings.displayName
                  : 'Custom'
                : selectedChart.displayName;

              onSave({
                ...selectedChart,
                displayName,
                settings: { ...chartSettings, ...customSettings }
              });
            }}>
            {editingChart ? 'Save' : 'Add'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChartEditorModal;
