import { BaseChartProps } from '../types';
import { BasicLineChart } from '../BasicLineChart';

type AtmosDatum = {
  'TessAtmos.avgTempC': string;
  'TessAtmos.avgPressureMb': string;
  'TessAtmos.measuredAt': string;
};

const Chart = (props: BaseChartProps) => {
  return (
    <BasicLineChart<AtmosDatum>
      className="atmos-conditions-chart"
      {...props}
      settings={{
        ...props.settings,
        traces: [
          {
            title: 'Atmospheric Temperature',
            measure: 'TessAtmos.avgTempC',
            yAxisLabel: 'Temperature (C)'
          },
          {
            title: 'Atmospheric Pressure',
            measure: 'TessAtmos.avgPressureMb',
            yAxisLabel: 'Pressure (Mb)'
          }
        ],
        timeDimension: 'TessAtmos.measuredAt',
        xAxisLabel: 'Hour'
      }}
    />
  );
};

export { Chart };
