import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, BoxProps, Spacer, useRadio, UseRadioProps } from '@chakra-ui/react';
import { FC } from 'react';

const RadioCard: FC<
  UseRadioProps & { boxProps?: BoxProps & { 'data-cypress'?: string }; checkedColor?: string }
> = ({ children, boxProps, checkedColor, ...props }) => {
  const { getInputProps, getRadioProps, state } = useRadio(props);

  const input = getInputProps();
  const radio = getRadioProps();

  return (
    <Box {...boxProps} as="label">
      <input {...input} />
      <Box
        {...radio}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: checkedColor ?? 'blue.400',
          color: 'white',
          borderColor: 'blue.400'
        }}
        _focus={{
          boxShadow: 'outline'
        }}
        px={5}
        py={3}>
        {state.isChecked ? (
          <CheckCircleIcon boxSize="5" mr="5px" />
        ) : (
          <Spacer display="inline-block" w="25px" h="5px" />
        )}
        {children}
      </Box>
    </Box>
  );
};

export default RadioCard;
