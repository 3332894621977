import { Skeleton } from '@chakra-ui/react';
import { DateRange } from '@cubejs-client/core';
import { EquipmentTypeWithSiteEquipment } from 'components/Site/Tabs/Equipment/Data';
import { ProjectContext } from 'contexts/ProjectContext';
import useCubeLTG from 'hooks/useCubeLTG';
import { useContext } from 'react';
import GraphError from '../GraphError';
import NoData from '../NoData';
import Plot from '../Plot';
import { BaseChartProps, BaseChartSettings } from '../types';
import { ControllerInputs } from './Controller';

export type ChartSettings = BaseChartSettings & {
  selectedEquipment: number;
  equipmentType: EquipmentTypeWithSiteEquipment;
  stateDimension?: number;
  dateRange?: DateRange;
};

type EquipmentDatum = {
  'TessEquipmentLookup.pk': string;
  'TessEquipmentLookup.equipmentSiteId': number;
  'TessEquipmentLookup.stateId': number;
  'TessEquipmentLookup.actionId': number;
  'TessEquipment.value': string;
  'TessEquipment.measuredAt': string;
  'TessEquipmentLookup.type': string;
};

const Chart = ({
  dateRange,
  chartRange,
  skip,
  control,
  onDataLoaded,
  settings: { selectedEquipment, equipmentType, stateDimension, site }
}: BaseChartProps<ChartSettings, ControllerInputs>) => {
  const project = useContext(ProjectContext);
  const transform = (data: EquipmentDatum[]) => {
    return data;
  };
  const graph = (data: EquipmentDatum[]) => {
    const state = equipmentType.states.find((s) => s.id === stateDimension);
    const equipmentData = data.filter((d) => d['TessEquipmentLookup.stateId'] === stateDimension);

    const stateData = {
      type: 'scatter',
      mode: 'lines+markers',
      name: state.name,
      x: equipmentData.map((d) => d[`TessEquipment.measuredAt`]),
      y: equipmentData
        .filter((d) => d['TessEquipmentLookup.stateId'] === stateDimension)
        .map((d) =>
          state.__typename !== 'EquipmentSelectionState'
            ? d['TessEquipment.value']
            : state.options?.find((o) => o.id.toString() === d['TessEquipment.value'])?.name ??
              'Undefined'
        ),
      showlegend: true
    };
    const layout = {
      legend: {
        orientation: 'h',
        x: 0,
        y: 1.25
      },
      yaxis: {
        title: {
          text: state.name,
          font: {
            size: 14
          }
        }
      },
      xaxis: {
        range: chartRange
      },
      autosize: true
    };

    const actionData = equipmentType.actions.map((a) => {
      const tessActions = data.filter((d) => d['TessEquipmentLookup.actionId'] === a.id);

      return {
        type: 'scatter',
        mode: 'markers',
        x: tessActions.map((d) => d[`TessEquipment.measuredAt`]),
        y: tessActions.map(() => 0),
        line: { dash: 'dash', color: 'red' },
        name: a.name
      };
    });

    return { data: [stateData, ...actionData], layout };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      dimensions: [
        'TessEquipmentLookup.pk',
        'TessEquipmentLookup.equipmentSiteId',
        'TessEquipmentLookup.stateId',
        'TessEquipmentLookup.actionId',
        'TessEquipment.value',
        'TessEquipment.measuredAt',
        'TessEquipmentLookup.type'
      ],
      timeDimensions: [
        {
          dimension: 'TessEquipment.measuredAt',
          dateRange
        }
      ],
      filters: [
        {
          member: 'TessEquipmentLookup.equipmentSiteId', // this field wraps a lookup for (site + sublocation)
          operator: 'equals',
          values: [selectedEquipment?.toString()]
        },
        {
          member: 'Site.id',
          operator: 'equals',
          values: [site.smbId.toString()]
        }
      ],
      timezone: project.timezone,
      order: { 'TessEquipment.measuredAt': 'desc' }
    },
    transform,
    //@ts-ignore
    graph,
    options: {
      skip: skip || !selectedEquipment || !stateDimension,
      onDataLoaded,
      dependencies: [selectedEquipment, stateDimension]
    }
  });

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }

  //@ts-ignore
  return plot?.data?.[0].x?.length ? (
    <>
      {control ? control({ equipmentType }) : <></>}
      <Plot className="w-100 equipment-plot" useResizeHandler={true} {...plot} />
    </>
  ) : (
    <>
      {control ? control({ equipmentType }) : <></>}
      <NoData minH="450px" />
    </>
  );
};

export default Chart;
