import { ProjectContext } from 'contexts/ProjectContext';
import { closestTo, lightFormat } from 'date-fns';
import useCubeLTG from 'hooks/useCubeLTG';
import { Data, PlotlyDataLayoutConfig } from 'plotly.js';
import { useContext } from 'react';
import { toTimezone } from 'shared/Utils';
import { BaseChartProps, BaseChartSettings } from '../types';
import { uniq } from 'lodash';

type TessTideCubeDatum = {
  'TessTides.waterlevelAvg': number;
  'TessTides.diiAvg': number;
  'TessTides.liAvg': number;
  'TessTides.tideStationId': string;
  'TessTides.tideStationLat': number;
  'TessTides.tideStationLon': number;
  'TessTides.measuredAt': string;
  'TessTides.measuredAt.minute': string;
  'TessTides.measuredAt.hour': string;
};

type ForecastedTideStructure = {
  waterLevel: { [datetime: string]: number };
  stationId: string;
  stationLat: number;
  stationLon: number;
};

export type UseTideTraceParams = BaseChartSettings & {
  showNow?: boolean;
  showAxisTitle?: boolean;
  selectedTideStations?: { label: string; value: string }[];
  searchRadius?: { label: string; value: string };
  lunarParameter?: { label: string; value: string };
};

const useTideTrace = ({
  skip,
  dateRange,
  chartRange,
  onDataLoaded,
  settings
}: BaseChartProps<UseTideTraceParams>) => {
  const nowMarker = settings?.showNow ?? false;
  const axisTitle = settings?.showAxisTitle ?? false;
  const timeDimension = settings.site?.smbId
    ? 'TessTides.measuredAt.minute'
    : 'TessTides.measuredAt.hour';
  const transform = (data: TessTideCubeDatum[]): ForecastedTideStructure => {
    const waterLevelData = data.reduce(
      (acc, forecast) => {
        acc[forecast[timeDimension]] =
          forecast[settings?.lunarParameter?.value ?? 'TessTides.waterlevelAvg'];
        return acc;
      },
      {} as ForecastedTideStructure['waterLevel']
    );
    return {
      waterLevel: waterLevelData,
      stationId: uniq(data.map((d) => d['TessTides.tideStationId']))[0],
      stationLat: uniq(data.map((d) => d['TessTides.tideStationLat']))[0],
      stationLon: uniq(data.map((d) => d['TessTides.tideStationLon']))[0]
    };
  };

  const graph = (data: ForecastedTideStructure): PlotlyDataLayoutConfig => {
    let plotData: Data = {
      //@ts-ignore
      type: 'spline',
      x: Object.keys(data.waterLevel),
      y: Object.values(data.waterLevel),
      name: 'Forecasted',
      fill: 'tozeroy',
      fillcolor: 'rgba(132, 255, 249, 0.5)',
      mode: 'none',
      hovertemplate:
        '<b>Forecasted Tide: %{y:.1f} m</b></br>' +
        `<b>Time: %{x} ${projectContext.timezoneLabel}</b><br>` +
        '<extra></extra>'
    };

    if (nowMarker) {
      const closest = closestTo(
        toTimezone(new Date(), projectContext.timezoneOffset),
        Object.keys(data.waterLevel).map((d) => {
          return new Date(d);
        })
      );

      if (closest) {
        const projectNow = lightFormat(closest, `yyyy-MM-dd'T'HH:mm:00.000`);

        //@ts-ignore
        plotData = [
          plotData,
          {
            type: 'scatter',
            x: [projectNow],
            y: [data.waterLevel[projectNow]],
            name: 'Now',
            hoverinfo: 'skip',
            marker: { size: 12 }
          }
        ];
      }
    }
    const layout = {
      legend: {
        orientation: 'h',
        traceorder: 'normal',
        x: 0,
        y: 1.1
      },
      margin: { t: 40, b: 40, l: 60, r: 60 },
      yaxis: axisTitle
        ? {
            title: settings?.lunarParameter?.label,
            side: 'left'
          }
        : {},
      xaxis: {
        range: chartRange
      },
      annotations: nowMarker
        ? [
            {
              xref: 'paper',
              yref: 'paper',
              x: 1,
              borderpad: 45,
              xanchor: 'right',
              y: 1.2,
              yanchor: 'center',
              textangle: 0,
              text: `Tide Station: ${data.stationId} (${data?.stationLat} / ${data?.stationLon})`,
              font: { size: 12 },
              showarrow: false
            }
          ]
        : []
    };
    return {
      data: Array.isArray(plotData) ? plotData : [plotData],
      //@ts-ignore
      layout: layout
    };
  };
  const projectContext = useContext(ProjectContext);
  const querySiteFilter = settings.site?.smbId
    ? [
        {
          member: 'TessTides.fromLat',
          operator: 'equals',
          values: [settings?.site?.lat ?? '']
        },
        {
          member: 'TessTides.fromLon',
          operator: 'equals',
          values: [settings?.site?.lon ?? '']
        },
        {
          member: 'TessTides.filterRadius',
          operator: 'equals',
          values: [settings?.searchRadius?.value ?? '5000']
        },
        {
          member: 'TessTides.includeStationList',
          operator: 'equals',
          values: [
            settings?.site?.tideStations?.include.length > 0
              ? settings?.site?.tideStations?.include.join(',')
              : 'NULL'
          ]
        },
        {
          member: 'TessTides.excludeStationList',
          operator: 'equals',
          values: [
            settings?.site?.tideStations?.exclude.length > 0
              ? settings?.site?.tideStations?.exclude.join(',')
              : 'NULL'
          ]
        }
      ]
    : [];
  return useCubeLTG({
    cubeQuery: {
      measures: ['TessTides.waterlevelAvg', 'TessTides.diiAvg', 'TessTides.liAvg'],
      dimensions: [
        'TessTides.tideStationId',
        'TessTides.tideStationLon',
        'TessTides.tideStationLat'
      ],
      timeDimensions: [
        {
          dimension: 'TessTides.measuredAt',
          granularity: settings.site?.smbId ? 'minute' : 'hour',
          dateRange
        }
      ],
      //@ts-ignore
      filters: querySiteFilter,
      timezone: projectContext.timezone
    },
    transform,
    graph,
    options: {
      refreshInterval: 900000,
      skip,
      onDataLoaded,
      dependencies: [settings]
    }
  });
};

export default useTideTrace;
