import ChartSwitchPanel from '../ChartSwitchPanel';
import { ChartController } from '../types';
import { ChartSettings, ChartInputs } from './Chart';

const Controller = ({
  chartSettings,
  setChartSettings,
  chartInputs: { sublocations } = {
    sublocations: [{ value: 'All', label: 'All' }]
  }
}: ChartController<ChartSettings, ChartInputs>) => {
  return (
    <ChartSwitchPanel
      switches={[
        {
          switchProps: { isChecked: chartSettings.showByCauseName },
          left: { value: 'left', label: 'Cause Group' },
          right: { value: 'right', label: 'Cause' },
          toggle: (x) => setChartSettings({ ...chartSettings, showByCauseName: x.checked })
        },
        {
          switchProps: { isChecked: chartSettings.showInCauseBiomass },
          left: { value: 'count', label: 'Count' },
          right: { value: 'biomass', label: 'Biomass' },
          toggle: (x) => setChartSettings({ ...chartSettings, showInCauseBiomass: x.checked })
        }
      ]}
      selects={[
        {
          value: chartSettings.selectedSublocation,
          width: '200px',
          options: [{ label: 'All', value: 'All' }, ...sublocations],
          label: 'Sublocation',
          onChange: (x) =>
            setChartSettings({
              ...chartSettings,
              selectedSublocation: Object.values(x)?.map((d) => {
                return { value: d.value, label: d.label };
              })[0] as {
                label: string;
                value: string;
              }
            })
        }
      ]}
    />
  );
};

export default Controller;
