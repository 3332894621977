import { HStack, Tab, TabList, Tabs, Text, VStack } from '@chakra-ui/react';
import { permissions } from '@scoot/permissions';
import NotFound from 'components/Pages/NotFound';
import TabHeadline from 'components/TabHeadline';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { useContext } from 'react';
import { MdOutlineDeviceHub } from 'react-icons/md';
import { Navigate, Outlet, useMatch, useNavigate } from 'react-router-dom';

export const EquipmentAdminLayout = () => {
  const user = useContext(UserContext);
  const project = useContext(ProjectContext);
  const navigate = useNavigate();
  const tabMatch = useMatch('/project/:id/admin/equipment/:tabId');

  const tabs = [
    {
      id: 'manage',
      displayName: 'Manage',
      url: `/project/${project.id}/admin/equipment/manage`
    },
    {
      id: 'assign',
      displayName: 'Assign',
      url: `/project/${project.id}/admin/equipment/assign`
    }
  ];

  if (!tabMatch) {
    return <Navigate to={tabs[0].url} replace={true} />;
  }

  const tabId = tabMatch.params.tabId;
  if (!permissions.canAdminProject(user, project.id)) {
    return <NotFound />;
  }

  return (
    <VStack w="100%" mb="2rem">
      <HStack alignItems={'start'}>
        <Text fontSize="4xl" mt="1em">
          Equipment Management{' '}
        </Text>
        <Text color="blue.500" fontSize="xs" pt="40px">
          New!
        </Text>
      </HStack>
      <TabHeadline
        icon={<MdOutlineDeviceHub />}
        text="Manage types of equipment in your organization. Create 'states' and 'actions' for site operators to submit changes in equipment status."
      />
      <Tabs
        variant="soft-rounded"
        align="center"
        size="lg"
        colorScheme="gray"
        mb="20px"
        index={tabs.map((t) => t.id).indexOf(tabId)}
        defaultIndex={0}>
        <TabList mb="20px">
          {tabs.map((tab) => (
            <Tab
              onClick={() => navigate(tab.url)}
              key={tab.id}
              data-cypress={`site-tab-${tab.displayName.toLowerCase().replace(' ', '-')}`}>
              {tab.displayName}
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <Outlet />
    </VStack>
  );
};
