import { ProjectContext } from 'contexts/ProjectContext';
import useCubeLTG from 'hooks/useCubeLTG';
import { PlotlyDataLayoutConfig, PlotData } from 'plotly.js';
import { useContext, useMemo } from 'react';
import { BaseChartProps, BaseChartSettings } from '../types';
import { groupBy, uniq, toLower, startCase } from 'lodash';
import { createLocationPallet } from 'shared/functions/colorPallets';

type TessRiverCubeDatum = {
  'TessRivers.waterlevelAvg': number;
  'TessRivers.dischargeAvg': number;
  'TessRivers.measuredAt': string;
  'TessRivers.measuredAt.minute': string;
  'TessRivers.measuredAt.hour': string;
  'TessRivers.riverSationName': string;
  'TessRivers.riverSationLat': number;
  'TessRivers.riverSationLon': number;
};

type ForecastedRiverStructure = {
  [stationName: string]: {
    [datetime: string]: number;
  };
};

export type UseRiverTraceParams = BaseChartSettings & {
  selectedRiverStations?: { label: string; value: string }[];
  searchRadius?: { label: string; value: string };
  isWaterLevel?: boolean;
};

const useRiverTrace = ({
  skip,
  dateRange,
  chartRange,
  onDataLoaded,
  settings
}: BaseChartProps<UseRiverTraceParams>) => {
  const timeDimension = settings.site?.smbId
    ? 'TessRivers.measuredAt.hour'
    : 'TessRivers.measuredAt.hour';

  const transform = (data: TessRiverCubeDatum[]): ForecastedRiverStructure => {
    const selectedParameter = settings.isWaterLevel
      ? 'TessRivers.waterlevelAvg'
      : 'TessRivers.dischargeAvg';
    const filteredStations = data.filter((d) =>
      settings?.selectedRiverStations?.length > 0
        ? settings?.selectedRiverStations
            .map((d) => d.value)
            .includes(d['TessRivers.riverStationName'])
        : true
    );
    const byStation = groupBy(filteredStations, (d) => d['TessRivers.riverStationName']);
    const allStations = Object.entries(byStation).reduce((stAcc, [stationName, stationValues]) => {
      const stationData = stationValues.reduce((acc, forecast) => {
        acc[forecast[timeDimension]] = forecast[selectedParameter];
        return acc;
      }, {});
      stAcc[stationName] = stationData;
      return stAcc;
    }, {} as ForecastedRiverStructure);
    return allStations;
  };
  const graph = (data: ForecastedRiverStructure): PlotlyDataLayoutConfig => {
    const pallet = createLocationPallet({ locations: Object.keys(data) });
    //@ts-ignore
    const plotData: Partial<PlotData>[] = Object.entries(data).map(([stationName, stationData]) => {
      const readableStationName = startCase(toLower(stationName.replaceAll('_', ' ')));
      return {
        type: 'line+marker',
        x: Object.keys(stationData),
        y: Object.values(stationData),
        name: settings?.site?.riverStations?.include.includes(stationName)
          ? readableStationName + '*'
          : readableStationName,
        line: { color: pallet[stationName], width: 2 },
        marker: { color: pallet[stationName], size: 5 },
        // fill: 'tozeroy',
        // fillcolor: 'rgba(132, 255, 249, 0.5)',
        mode: 'lines+markers',
        hovertemplate:
          `<b>${
            settings?.site?.riverStations?.include.includes(stationName)
              ? readableStationName + '*'
              : readableStationName
          }</b><br>` +
          `<b>${settings.isWaterLevel ? 'Water Level (m)' : 'Discharge (cms)'}: %{y:.2f}</b></br>` +
          `<b>Time: %{x} ${settings.project.timezoneLabel}</b><br>` +
          '<extra></extra>'
      };
    });
    return {
      //@ts-ignore
      data: Array.isArray(plotData) ? plotData : [plotData],
      layout: {
        showlegend: true,
        legend: {
          orientation: 'h',
          traceorder: 'normal',
          x: 0,
          y: 1.4
        },
        margin: { t: 100, b: 40, l: 60, r: 60 },
        yaxis: settings.site?.smbId
          ? {
              title: settings.isWaterLevel ? 'Water Level (m)' : 'Discharge (cms)',
              side: 'left'
            }
          : {},
        xaxis: {
          range: chartRange
        }
      }
    };
  };

  const projectContext = useContext(ProjectContext);

  const { isLoading, error, plot, resultSet } = useCubeLTG({
    cubeQuery: {
      measures: ['TessRivers.waterlevelAvg', 'TessRivers.dischargeAvg'],
      timeDimensions: [
        {
          dimension: 'TessRivers.measuredAt',
          granularity: settings.site?.smbId ? 'hour' : 'hour',
          dateRange
        }
      ],
      dimensions: ['TessRivers.riverStationName'],
      //@ts-ignore
      filters: [
        {
          member: 'TessRivers.fromLat',
          operator: 'equals',
          values: [settings?.site?.lat ?? projectContext.latitude.toString()]
        },
        {
          member: 'TessRivers.fromLon',
          operator: 'equals',
          values: [settings?.site?.lon ?? projectContext?.longitude.toString()]
        },
        {
          member: 'TessRivers.filterRadius',
          operator: 'equals',
          values: [settings?.searchRadius?.value ?? '50']
        },
        {
          member: 'TessRivers.includeStationList',
          operator: 'equals',
          values: [settings?.site?.riverStations?.include.join(',')] ?? ['']
        },
        {
          member: 'TessRivers.excludeStationList',
          operator: 'equals',
          values: [settings?.site?.riverStations?.exclude.join(',')] ?? ['']
        }
      ],
      timezone: projectContext.timezone,
      limit: 50000
    },
    transform,
    graph,
    options: {
      refreshInterval: 900000,
      skip,
      onDataLoaded,
      dependencies: [settings]
    }
  });

  const allStationNames = useMemo(() => {
    return (
      uniq(resultSet?.rawData().map((d) => d['TessRivers.riverStationName']))
        .sort((a, b) => a.localeCompare(b))
        .map((d) => {
          return settings.site?.smbId
            ? { value: d, label: startCase(toLower(d.replaceAll('_', ' '))) }
            : {
                value: d,
                label: d
              };
        }) ?? []
    );
  }, [resultSet]);

  return { isLoading, error, plot, resultSet, allStationNames };
};

export default useRiverTrace;
