import AbsoluteMortality from 'components/Charts/AbsoluteMortality/Chart';
import AverageWeight from 'components/Charts/AverageWeight/Chart';
import ChartSwitchPanel from 'components/Charts/ChartSwitchPanel';
import ChartTile from 'components/Charts/ChartTile';
import FeedUse from 'components/Charts/FeedUse/Chart';
import MortalityByCause from 'components/Charts/MortalityByCause/Chart';
import PercentMortality from 'components/Charts/PercentageMortality/Chart';
import SFR from 'components/Charts/SpecificFeedingRate/Chart';
import TotalFish from 'components/Charts/TotalFish/Chart';
import TreatmentsCombo from 'components/Charts/Treatments/Combo';
import { ProjectContextType } from 'contexts/ProjectContext';
import { Site } from 'graphql/generated';
import { useState } from 'react';

type FishHealthProps = {
  site?: Site;
  project: ProjectContextType;
};

const FishHealth = (props: FishHealthProps) => {
  const [totalFishBiomassToggle, setTotalFishBiomassToggle] = useState(false);
  const [mortalityBiomassToggle, setMortalityBiomassToggle] = useState(false);
  const [mortalityCauseBiomassToggle, setMortalityCauseBiomassToggle] = useState(false);
  const [mortalityCauseGroupToggle, setMortalityCauseGroupToggle] = useState(true);
  const [selectedSubloc, setSelectedSubloc] = useState({ value: 'All', label: 'All' });
  const [feedUseTotalToggle, setFeedUseTotalToggle] = useState(false);
  const [appetiteTotalToggle, setAppetiteToggle] = useState(false);

  return (
    <>
      <ChartTile
        hash="absolute-mortality"
        heading="Absolute Mortality"
        site={props.site ?? null}
        control={
          <ChartSwitchPanel
            switches={[
              {
                left: { value: 'count', label: 'Count' },
                right: { value: 'biomass', label: 'Biomass' },
                switchProps: { isChecked: mortalityBiomassToggle },
                toggle: ({ checked }) => setMortalityBiomassToggle(checked)
              }
            ]}
          />
        }>
        <AbsoluteMortality
          settings={{
            site: props?.site,
            project: props.project,
            showInBiomass: mortalityBiomassToggle
          }}
        />
      </ChartTile>

      <ChartTile heading="Percentage Mortality" site={props.site}>
        <PercentMortality settings={{ project: props.project, site: props?.site }} />
      </ChartTile>

      <ChartTile hash="mortality-by-cause" heading={'Mortality by Cause'} site={props.site}>
        <MortalityByCause
          settings={{
            project: props.project,
            site: props?.site,
            showInCauseBiomass: mortalityCauseBiomassToggle,
            showByCauseName: mortalityCauseGroupToggle,
            selectedSublocation: selectedSubloc
          }}
          control={({ sublocations }) => (
            <ChartSwitchPanel
              switches={[
                {
                  switchProps: { isChecked: mortalityCauseGroupToggle },
                  left: { value: 'left', label: 'Cause Group' },
                  right: { value: 'right', label: 'Cause' },
                  toggle: ({ checked }) => setMortalityCauseGroupToggle(checked)
                },
                {
                  switchProps: { isChecked: mortalityCauseBiomassToggle },
                  left: { value: 'count', label: 'Count' },
                  right: { value: 'biomass', label: 'Biomass' },
                  toggle: ({ checked }) => setMortalityCauseBiomassToggle(checked)
                }
              ]}
              selects={[
                {
                  label: 'Sublocation ',
                  value: selectedSubloc,
                  options: [{ label: 'All', value: 'All' }, ...sublocations],
                  width: '100%',
                  maxWidth: '400px',
                  minWidth: '150px',
                  onChange: (selectedOption) =>
                    setSelectedSubloc(selectedOption as { value: string; label: string })
                }
              ]}
            />
          )}
        />
      </ChartTile>

      <ChartTile
        hash="total-fish"
        heading="Total Fish"
        site={props.site}
        control={
          <ChartSwitchPanel
            switches={[
              {
                left: { value: 'count', label: 'Count' },
                right: { value: 'biomass', label: 'Biomass' },
                toggle: ({ checked }) => setTotalFishBiomassToggle(checked)
              }
            ]}
          />
        }>
        <TotalFish
          settings={{
            project: props.project,
            site: props?.site,
            showInBiomass: totalFishBiomassToggle
          }}
        />
      </ChartTile>

      <ChartTile hash="average-weight" heading="Average Weight" site={props.site}>
        <AverageWeight settings={{ project: props.project, site: props?.site }} />
      </ChartTile>

      <ChartTile heading="Treatments" site={props.site}>
        <TreatmentsCombo site={props.site} project={props.project} />
      </ChartTile>

      <ChartTile
        hash="feed-use"
        heading="Feed Use"
        site={props.site}
        control={
          <ChartSwitchPanel
            switches={[
              {
                left: { value: 'sublocations', label: 'Sublocations' },
                right: { value: 'site', label: 'Site Total' },
                toggle: ({ checked }) => setFeedUseTotalToggle(checked)
              },
              {
                left: { value: 'feedUseKg', label: 'Feed Use (kg)' },
                right: { value: 'appetite', label: 'Appetite (%)' },
                toggle: ({ checked }) => setAppetiteToggle(checked)
              }
            ]}
          />
        }>
        <FeedUse
          settings={{
            project: props.project,
            site: props?.site,
            useTotal: feedUseTotalToggle,
            useAppetite: appetiteTotalToggle
          }}
        />
      </ChartTile>

      <ChartTile hash="sfr" heading="Specific Feeding Rate" site={props.site}>
        <SFR settings={{ project: props.project, site: props?.site }} />
      </ChartTile>
    </>
  );
};

export default FishHealth;
