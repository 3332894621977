import { Box, HStack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { ProjectContext } from 'contexts/ProjectContext';
import { format } from 'date-fns';
import { useContext } from 'react';
import { toTimezone } from 'shared/Utils';
import { getSunrise } from 'sunrise-sunset-js';
import { WiSunrise } from 'weather-icons-react';

const Sunrise = ({ lat, lon }: { lat: number; lon: number }) => {
  const projectContext = useContext(ProjectContext);
  const sunrise = getSunrise(lat, lon);
  let sunrise_formatted = '';
  if (!isNaN(sunrise.getTime())) {
    sunrise_formatted = format(toTimezone(sunrise, projectContext.timezoneOffset), 'kk:mm');
  } else {
    sunrise_formatted = '--';
  }
  return (
    <VStack>
      <Tooltip label="Time of Sunrise">
        <HStack>
          <Box color="orange.400">
            <WiSunrise size={40} />
          </Box>
          <Text>{sunrise_formatted}</Text>
        </HStack>
      </Tooltip>
    </VStack>
  );
};

export default Sunrise;
