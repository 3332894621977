import { Skeleton } from '@chakra-ui/react';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, uniq } from 'lodash';
import { PlotlyDataLayoutConfig } from 'plotly.js';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { locationToIndex } from 'shared/functions/location';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot from '../Plot';
import { BaseChartProps, BaseChartSettings } from '../types';

type MortalityDatum = {
  'BiologyLookup.sublocation'?: string;
  'Site.id'?: string;
  'Biology.measuredAt': string;
  'Biology.mortalityCountSum': number;
  'Biology.mortalityWeightSum': number;
};

type MortalityStructure = {
  [sublocation: string]: {
    [measuredAt: string]: {
      count: number;
      biomass: number;
    };
  };
};

export type ChartSettings = BaseChartSettings & {
  showInBiomass?: boolean;
};

const Chart = ({
  dateRange = 'Last 30 days',
  chartRange,
  granularity = 'day',
  skip,
  settings: { showInBiomass, project, ...settings },
  onDataLoaded
}: BaseChartProps<ChartSettings>) => {
  const locationDimension = settings?.site ? 'BiologyLookup.sublocation' : 'Site.id';

  const transform = (data: MortalityDatum[]): MortalityStructure => {
    const bySublocation = groupBy(data, (d) => d[locationDimension]);
    return Object.keys(bySublocation).reduce((acc, subloc: string) => {
      const byMeasuredAt = bySublocation[subloc].reduce((subAcc, datum) => {
        subAcc[datum[`Biology.measuredAt.${granularity}`]] = {
          count: datum['Biology.mortalityCountSum'],
          biomass: datum['Biology.mortalityWeightSum']
        };
        return subAcc;
      }, {});
      acc[subloc] = byMeasuredAt;
      return acc;
    }, {});
  };

  const graph = (data: MortalityStructure, dependencies?: any): PlotlyDataLayoutConfig => {
    const sublocations = uniq(Object.keys(data)).sort(
      (a, b) => locationToIndex(a) - locationToIndex(b)
    );
    const pallet = createLocationPallet({ locations: sublocations });
    const plotData = sublocations.map((subloc) => {
      return {
        type: 'bar',
        name: settings?.site?.smbId ? subloc : project.siteNameMappings[subloc],
        x: Object.keys(data[subloc]),
        y: Object.values(data[subloc]).flatMap((d) =>
          dependencies?.biomassToggle ? d.biomass : d.count
        ),
        marker: {
          color: pallet[subloc]
        },
        hovertemplate: `<b>%{x}</b><br>${
          settings.site?.smbId ? subloc : project.siteNameMappings[subloc]
        }<br>${
          dependencies?.biomassToggle
            ? 'total biomass (kg): <b>%{y:.1f}</b>'
            : 'total count: <b>%{y:0.f}</b>'
        }<extra></extra>`
      };
    });

    const layout = {
      barmode: 'stack',
      yaxis: {
        title: {
          text: dependencies?.biomassToggle ? 'Weight (kg)' : 'Count by Cage',
          font: {
            size: 14
          }
        }
      },
      xaxis: {
        range: chartRange
      },
      autosize: true,
      showlegend: true,
      legend: {
        orientation: 'h',
        x: 0,
        y: 1.25,
        traceorder: 'normal'
      },
      margin: {
        t: 25
      },
      hovermode: 'closest'
    };

    return {
      //@ts-ignore
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      measures: ['Biology.mortalityCountSum', 'Biology.mortalityWeightSum'],
      timeDimensions: [
        {
          dimension: 'Biology.measuredAt',
          granularity,
          dateRange
        }
      ],
      dimensions: [locationDimension],
      filters: settings.site?.smbId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [settings.site?.smbId.toString()]
            }
          ]
        : [],
      timezone: project.timezone
    },
    transform,
    graph,
    options: {
      skip,
      dependencies: { biomassToggle: showInBiomass, chartRange },
      onDataLoaded
    }
  });

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }

  return plot?.data?.length === 0 && project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : plot?.data?.length === 0 && !project.freeTrial ? (
    <NoData minH="450px" />
  ) : (
    <Plot className="w-100" useResizeHandler={true} {...plot} />
  );
};

export default Chart;
