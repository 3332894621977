import { Box, Flex, HStack, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { useCubeQuery } from '@cubejs-client/react';
import { convertToIndex, siwiDatum } from 'components/Charts/SIWI/SIWIShared';
import FishLoader from 'components/Loaders/FishLoader';
import { ProjectContext } from 'contexts/ProjectContext';
import { format, formatDistance } from 'date-fns';
import { Site } from 'graphql/generated';
import { capitalize, startCase, sum, uniq } from 'lodash';
import { Fragment, useContext, useMemo } from 'react';
import { BiWater } from 'react-icons/bi';
import {
  GiBubbles,
  GiCirclingFish,
  GiFishbone,
  GiHighTide,
  GiKitchenScale,
  GiLongAntennaeBug,
  GiLowTide
} from 'react-icons/gi';
import { HiArrowDown, HiArrowUp } from 'react-icons/hi';
import { RiBug2Line, RiPercentLine } from 'react-icons/ri';
import { TbGrain, TbSalt } from 'react-icons/tb';
import { MOTILE_LICE_STAGE_IDS_PROJECT_ID, toTimezone } from 'shared/Utils';
import { thresholdForSpeciesValue } from 'shared/plankton';
import { WiThermometer } from 'weather-icons-react';
import Stat from '../../../Stat';

type CubeHeadline = {
  'TessSensorHydrography.max': number;
  'TessSensorHydrography.min': number;
};

export const safeFormat = (value?: number, digits = 2, placeholder = '-') =>
  value ? Number(value.toFixed(digits)).toLocaleString() : placeholder;

const HeadlineStats = ({ site }: { site: Site }) => {
  const projectContext = useContext(ProjectContext);

  const recentDistance = (dateTime: Date) => {
    if (!dateTime || dateTime.toString() === 'Invalid Date') return 'Most Recent';
    const words = formatDistance(dateTime, toTimezone(new Date(), projectContext.timezoneOffset), {
      addSuffix: true
    });

    if (!Number.isInteger(words?.[0])) {
      return capitalize(words);
    } else {
      return words;
    }
  };

  const { resultSet: manualSet, isLoading: manualLoading } = useCubeQuery<CubeHeadline>(
    {
      measures: [
        'ManualHydrography.salinityMax',
        'ManualHydrography.salinityMin',
        'ManualHydrography.oxygenSaturationMax',
        'ManualHydrography.oxygenSaturationMin',
        'ManualHydrography.waterTempMax',
        'ManualHydrography.waterTempMin'
      ],
      timeDimensions: [
        {
          dimension: 'ManualHydrography.measuredAt',
          granularity: 'hour',
          dateRange: 'from 168 hours ago to now'
        }
      ],
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: [site.smbId.toString()]
        },
        {
          member: 'ManualHydrography.salinity',
          operator: 'set'
        },
        {
          member: 'ManualHydrography.waterTemp',
          operator: 'set'
        },
        {
          member: 'ManualHydrography.oxygenSaturation',
          operator: 'set'
        }
      ],
      order: { 'ManualHydrography.measuredAt': 'desc' },
      limit: 1,
      timezone: projectContext.timezone
    },
    { subscribe: true }
  );

  const { resultSet: mortCauseSet, isLoading: mortCausueLoading } = useCubeQuery<CubeHeadline>(
    {
      measures: ['TessMortality.mortalityCountSum'],
      dimensions: ['TessMortalityLookup.mortalityCauseGroupName'],
      timeDimensions: [
        {
          dimension: 'TessMortality.measuredAt',
          dateRange: 'from 7 days ago to now'
        }
      ],
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: [site.smbId.toString()]
        },
        {
          member: 'TessMortality.mortalityCountSum',
          operator: 'gt',
          values: ['0']
        }
      ],

      order: { 'TessMortality.mortalityCountSum': 'desc' },
      limit: 3,
      // ungrouped: true,
      timezone: projectContext.timezone
    },
    { subscribe: true }
  );

  const { resultSet: weeklyBioSet, isLoading: weeklyBioLoading } = useCubeQuery<CubeHeadline>(
    {
      dimensions: [
        'Biology.measuredAt',
        'Biology.specificFeedRateD',
        'Biology.feedUseWeightD',
        'Biology.endCount',
        'Biology.avgWeightD',
        'Biology.mortalityCount',
        'Site.id'
      ],
      timeDimensions: [
        {
          dimension: 'Biology.measuredAt',
          dateRange: 'from 7 days ago to now'
        }
      ],
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: [site.smbId.toString()]
        }
      ],

      order: { 'Biology.measuredAt': 'desc' },
      // ungrouped: true,
      timezone: projectContext.timezone
    },
    { subscribe: true }
  );

  const { resultSet: waterlevelSet, isLoading: waterlevelLoading } = useCubeQuery<CubeHeadline>(
    {
      measures: ['TessTides.waterlevelAvg'],
      timeDimensions: [
        {
          dimension: 'TessTides.measuredAt',
          granularity: 'minute',
          dateRange: 'last 120 minutes from now'
        }
      ],
      filters: [
        {
          member: 'TessTides.fromLat',
          operator: 'equals',
          values: [site?.lat ?? '41']
        },
        {
          member: 'TessTides.fromLon',
          operator: 'equals',
          values: [site?.lon ?? '-131']
        },
        {
          member: 'TessTides.filterRadius',
          operator: 'equals',
          values: ['10000']
        },
        {
          member: 'TessTides.includeStationList',
          operator: 'equals',
          values: [site?.tideStations?.include.join(',') ?? 'NULL']
        },
        {
          member: 'TessTides.excludeStationList',
          operator: 'equals',
          values: [site?.tideStations?.exclude.join(',') ?? 'NULL']
        }
      ],
      order: {
        'TessTides.measuredAt': 'desc'
      },
      timezone: projectContext.timezone
    },
    { subscribe: true }
  );

  const { resultSet: hiloSet, isLoading: hiloLoading } = useCubeQuery<CubeHeadline>(
    {
      measures: ['TessTides.waterlevelAvg'],
      timeDimensions: [
        {
          dimension: 'TessTides.measuredAt',
          granularity: 'minute',
          dateRange: 'next 720 minutes'
        }
      ],
      filters: [
        {
          member: 'TessTides.fromLat',
          operator: 'equals',
          values: [site?.lat ?? '']
        },
        {
          member: 'TessTides.fromLon',
          operator: 'equals',
          values: [site?.lon ?? '']
        },
        {
          member: 'TessTides.filterRadius',
          operator: 'equals',
          values: ['10000']
        },
        {
          member: 'TessTides.includeStationList',
          operator: 'equals',
          values: [site?.tideStations?.include.join(',') ?? 'NULL']
        },
        {
          member: 'TessTides.excludeStationList',
          operator: 'equals',
          values: [site?.tideStations?.exclude.join(',') ?? 'NULL']
        },
        {
          member: 'TessTides.hilo',
          operator: 'equals',
          values: ['true']
        }
      ],
      order: {
        'TessTides.measuredAt': 'asc'
      },
      timezone: projectContext.timezone
    },
    { subscribe: true }
  );

  const { resultSet: liceSet, isLoading: liceLoading } = useCubeQuery<CubeHeadline>(
    {
      measures: ['TessLice.avgLicePerFish'],
      timeDimensions: [
        {
          dimension: 'TessLice.measuredAt',

          dateRange: 'from 7 days ago to now'
        }
      ],
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: [site.smbId.toString()]
        },
        {
          member: 'TessLiceLookup.stageId',
          operator: 'equals',
          values: MOTILE_LICE_STAGE_IDS_PROJECT_ID[projectContext.id]
        }
      ],
      timezone: projectContext.timezone
    },
    {
      subscribe: true,
      skip: (MOTILE_LICE_STAGE_IDS_PROJECT_ID?.[projectContext.id]?.length ?? 0) === 0
    }
  );

  const { resultSet: planktonSet, isLoading: planktonLoading } = useCubeQuery<CubeHeadline>(
    {
      measures: ['LatestTessPlankton.avgCellCount', 'LatestTessPlankton.maxCellCount'],
      timeDimensions: [
        {
          dimension: 'LatestTessPlankton.measuredAt',
          dateRange: 'from 4320 minutes ago to now',
          granularity: 'minute'
        }
      ],
      dimensions: ['TessPlanktonLookup.species'],
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: [site.smbId.toString()]
        },
        {
          member: 'TessPlanktonLookup.method',
          operator: 'equals',
          values: ['discrete']
        },
        {
          member: 'TessPlanktonLookup.sublocation',
          operator: 'set'
        }
      ],
      timezone: projectContext.timezone
    },
    { subscribe: true }
  );

  type PlanktonAbundance = {
    safe: { species: string; concentration: number; time: Date; numberSpp: number };
    caution: { species: string; concentration: number; time: Date; numberSpp: number };
    lethal: { species: string; concentration: number; time: Date; numberSpp: number };
  };

  const planktonSpp: PlanktonAbundance = useMemo(() => {
    const plankton = planktonSet?.rawData();
    if (plankton) {
      const mostAbundant = {} as PlanktonAbundance;
      const allSpp = [];
      Object.values(plankton).map((d) => {
        allSpp.push(d['TessPlanktonLookup.species']);
        const thisConcentration = d['LatestTessPlankton.maxCellCount'];
        const threshold = thresholdForSpeciesValue(
          projectContext,
          d['TessPlanktonLookup.species'],
          d['LatestTessPlankton.maxCellCount']
        );
        mostAbundant[threshold] = mostAbundant[threshold] ?? {
          species: '',
          concentration: 0,
          time: '',
          numberSpp: 0
        };
        if (mostAbundant[threshold]['concentration'] < thisConcentration) {
          mostAbundant[threshold]['species'] = d['TessPlanktonLookup.species'];
          mostAbundant[threshold]['concentration'] = thisConcentration;
          mostAbundant[threshold]['time'] = d['LatestTessPlankton.measuredAt'];
        }
      });
      mostAbundant['numberSpp'] = uniq(allSpp).length;
      return mostAbundant;
    } else
      return {
        safe: { species: null, concentration: null, time: null, numberSpp: null },
        caution: { species: null, concentration: null, time: null, numberSpp: null },
        lethal: { species: null, concentration: null, time: null, numberSpp: null }
      };
  }, [planktonSet, projectContext]);

  const scariestPlankton = planktonSpp.lethal
    ? { ...planktonSpp.lethal, threshold: 'danger' }
    : planktonSpp.caution
      ? { ...planktonSpp.caution, threshold: 'caution' }
      : { ...planktonSpp.safe, threshold: 'safe' };

  const { resultSet: siwiSet, isLoading: siwiLoading } = useCubeQuery<siwiDatum>({
    measures: ['LatestSiwi.sT'],
    filters: [{ member: 'Site.id', operator: 'equals', values: [site.smbId.toString()] }],
    timezone: projectContext.timezone,
    timeDimensions: [
      {
        dimension: 'LatestSiwi.measuredAt',
        granularity: 'day'
      }
    ]
  });

  const avgLicePerFish = useMemo(() => {
    return liceSet?.rawData()[0]['TessLice.avgLicePerFish'];
  }, [liceSet]);

  const [salMin, salMax] = useMemo(() => {
    const latestData = manualSet?.rawData()?.[0];
    if (latestData)
      return [
        Number(latestData['ManualHydrography.salinityMin']),
        Number(latestData['ManualHydrography.salinityMax'])
      ];
    else return [null, null];
  }, [manualSet]);

  const [tempMin, tempMax] = useMemo(() => {
    const latestData = manualSet?.rawData()?.[0];
    if (latestData)
      return [
        Number(latestData['ManualHydrography.waterTempMin']?.toFixed(1)),
        Number(latestData['ManualHydrography.waterTempMax']?.toFixed(1))
      ];
    else return [null, null];
  }, [manualSet]);

  const [oxMin, oxMax] = useMemo(() => {
    const latestData = manualSet?.rawData()?.[0];
    if (latestData)
      return [
        Number(latestData['ManualHydrography.oxygenSaturationMin']?.toFixed(1)),
        Number(latestData['ManualHydrography.oxygenSaturationMax']?.toFixed(1))
      ];
    else return [null, null];
  }, [manualSet]);

  const latestBio = useMemo(() => {
    const set = weeklyBioSet?.rawData();
    if (!set || set.length === 0) return null;

    const latest = set[0]['Biology.measuredAt'];
    return set.filter((d) => d['Biology.measuredAt'] === latest);
  }, [weeklyBioSet]);

  const totalFishCount = useMemo(() => {
    if (latestBio) return sum(latestBio.map((d) => d['Biology.endCount']));
  }, [latestBio]);

  const avgFishWeight = useMemo(() => {
    if (latestBio) {
      const nonZero = latestBio.map((d) => d['Biology.avgWeightD']).filter((w) => w > 0);
      return sum(nonZero) / nonZero.length;
    }
  }, [latestBio]);

  const dailyMorts = useMemo(() => {
    // If today's date doesn't have data, look back a day.
    const set = weeklyBioSet?.rawData();
    if (!set || set.length === 0) return null;

    const latest = set.find(
      (d) => d['Biology.mortalityCount'] && d['Biology.mortalityCount'] !== 0
    );

    const allOnDate = set.filter((d) => d['Biology.measuredAt'] === latest?.['Biology.measuredAt']);

    return {
      date: latest?.['Biology.measuredAt'],
      sum: sum(allOnDate.map((d) => d['Biology.mortalityCount']))
    };
  }, [latestBio]);

  const weeklyMorts = useMemo(() => {
    if (weeklyBioSet?.rawData()) {
      return sum(weeklyBioSet?.rawData().map((d) => d['Biology.mortalityCount']));
    }
  }, [weeklyBioSet]);

  const mortCauses = useMemo(() => {
    if (mortCauseSet?.rawData()) {
      return mortCauseSet.rawData();
    }
  }, [mortCauseSet]);

  const weeklyFeed = useMemo(() => {
    if (weeklyBioSet?.rawData()) {
      return sum(weeklyBioSet?.rawData().map((d) => d['Biology.feedUseWeightD']));
    }
  }, [weeklyBioSet]);

  const sfrAvg = useMemo(() => {
    if (weeklyBioSet?.rawData()) {
      const nonZero = weeklyBioSet
        .rawData()
        .map((d) => d['Biology.specificFeedRateD'])
        .filter((sfr) => sfr > 0);
      return sum(nonZero) / nonZero.length;
    }
    return weeklyBioSet?.rawData()?.[0]['Biology.specificFeedRateD'];
  }, [weeklyBioSet]);

  const [currentTide, lastTide] = useMemo(() => {
    if (!waterlevelSet?.rawData()?.[0] || !waterlevelSet?.rawData()?.[1]) return [null, null];
    return [
      Number(waterlevelSet?.rawData()?.[0]['TessTides.waterlevelAvg']),
      Number(waterlevelSet?.rawData()?.[1]['TessTides.waterlevelAvg'])
    ];
  }, [waterlevelSet]);

  const [currentHilo, nextHilo] = useMemo(() => {
    const current = hiloSet?.rawData()?.[0];
    const next = hiloSet?.rawData()?.[1];
    if (!current || !next) return [null, null];
    return [
      { value: Number(current['TessTides.waterlevelAvg']), time: current['TessTides.measuredAt'] },
      { value: Number(next['TessTides.waterlevelAvg']), time: next['TessTides.measuredAt'] }
    ];
  }, [hiloSet]);

  const siwiTotal = useMemo(() => {
    return projectContext.siwi ? convertToIndex(siwiSet?.rawData()?.[0]?.['LatestSiwi.sT']) : 0;
  }, [siwiSet, projectContext.siwi]);

  return (
    <Box w="100%" m="1rem" minH="415px" textAlign="center">
      <Text fontSize="3xl">{site.name}</Text>
      {liceLoading ||
      waterlevelLoading ||
      hiloLoading ||
      manualLoading ||
      weeklyBioLoading ||
      planktonLoading ||
      mortCausueLoading ||
      siwiLoading ? (
        <FishLoader h="365px" />
      ) : (
        <Flex flexWrap="wrap">
          {site.type !== 'seed' && site.type !== 'asc' && (
            <>
              <Stat
                key="water-temp"
                cyId="headline-stat"
                label="Water Temperature (&deg;C)"
                value={`${safeFormat(tempMin, 2, '')} - ${safeFormat(tempMax, 2, '')}`}
                timeLabel="Last 24 hours"
                icon={
                  <Box color="blue.500">
                    <WiThermometer size={30} />
                  </Box>
                }
                href={`/project/${projectContext.id}/site/${site.id}/environment#barge-hydrography`}
              />
              <Stat
                key="oxygen-percent"
                cyId="headline-stat"
                label="Oxygen (%)"
                value={`${safeFormat(oxMin, 2, '')} - ${safeFormat(oxMax, 2, '')}`}
                timeLabel="Last 24 hours"
                icon={
                  <Box padding="5px" color="blue.500">
                    <GiBubbles size={20} />
                  </Box>
                }
                href={`/project/${projectContext.id}/site/${site.id}/environment#barge-hydrography`}
              />
              <Stat
                key="salinity-psu"
                cyId="headline-stat"
                label="Salinity (psu)"
                timeLabel="Last 24 hours"
                value={`${safeFormat(salMin, 2, '')} - ${safeFormat(salMax, 2, '')}`}
                icon={
                  <Box padding="5px" color="blue.500">
                    <TbSalt size={20} />
                  </Box>
                }
                href={`/project/${projectContext.id}/site/${site.id}/environment#barge-hydrography`}
              />
            </>
          )}
          {site.type === 'farm' && (
            <>
              <Stat
                key="total-fish-count"
                cyId="headline-stat"
                label="Total Fish (count)"
                value={safeFormat(totalFishCount)}
                timeLabel={recentDistance(new Date(latestBio?.[0]?.['Biology.measuredAt']))}
                icon={
                  <Box padding="5px" color="blue.500">
                    <GiCirclingFish size={20} />
                  </Box>
                }
                href={`/project/${projectContext.id}/site/${site.id}/fish-health#total-fish`}
              />
              <Stat
                key="average-weight-kg"
                cyId="headline-stat"
                label="Average Weight (kg)"
                value={safeFormat(avgFishWeight)}
                timeLabel={recentDistance(new Date(latestBio?.[0]?.['Biology.measuredAt']))}
                icon={
                  <Box padding="5px" color="blue.500">
                    <GiKitchenScale size={20} />
                  </Box>
                }
                href={`/project/${projectContext.id}/site/${site.id}/fish-health#average-weight`}
              />
              <Stat
                key="morts-count-daily"
                cyId="headline-stat"
                label="Morts (count)"
                value={safeFormat(dailyMorts?.sum)}
                timeLabel={recentDistance(new Date(dailyMorts?.date))}
                icon={
                  <Box padding="5px" color="blue.500">
                    <GiFishbone size={20} />
                  </Box>
                }
                href={`/project/${projectContext.id}/site/${site.id}/fish-health#absolute-mortality`}
              />
              <Stat
                key="morts-count-weekly"
                cyId="headline-stat"
                label="Morts (count)"
                value={safeFormat(weeklyMorts)}
                timeLabel="Last 7 days"
                icon={
                  <Box padding="5px" color="blue.500">
                    <GiFishbone size={20} />
                  </Box>
                }
                href={`/project/${projectContext.id}/site/${site.id}/fish-health#absolute-mortality`}
              />
              <Stat
                key="top-mortality"
                cyId="headline-stat"
                label="Top Mortality Causes"
                value={
                  <SimpleGrid columns={2}>
                    {mortCauses?.map((mortInfo) => {
                      return (
                        <Fragment
                          key={`group-${mortInfo['TessMortalityLookup.mortalityCauseGroupName']}`}>
                          <Text align="start" w="100%" fontSize="xs">
                            {startCase(mortInfo['TessMortalityLookup.mortalityCauseGroupName'])}
                          </Text>
                          <Text align="end" w="100%" fontSize="xs">
                            {safeFormat(mortInfo['TessMortality.mortalityCountSum'], 0)}
                          </Text>
                        </Fragment>
                      );
                    })}
                  </SimpleGrid>
                }
                timeLabel="Last 7 days"
                icon={
                  <Box padding="5px" color="blue.500">
                    <GiFishbone size={20} />
                  </Box>
                }
                href={`/project/${projectContext.id}/site/${site.id}/fish-health#mortality-by-cause`}
              />
              <Stat
                key="feed-use-kg"
                cyId="headline-stat"
                label="Feed Use (kg)"
                value={safeFormat(weeklyFeed, 0)}
                timeLabel="Last 7 days"
                icon={
                  <Box padding="5px" color="blue.500">
                    <TbGrain size={20} />
                  </Box>
                }
                href={`/project/${projectContext.id}/site/${site.id}/fish-health#feed-use`}
              />
              <Stat
                key="sfr-avg"
                cyId="headline-stat"
                label="SFR (avg)"
                timeLabel="Last 7 days"
                value={safeFormat(sfrAvg)}
                icon={
                  <Box padding="6px" color="blue.500">
                    <RiPercentLine size={18} />
                  </Box>
                }
                href={`/project/${projectContext.id}/site/${site.id}/fish-health#sfr`}
              />
              <Stat
                key="motile-lice-per-fish"
                cyId="headline-stat"
                label="Motile Lice (lice/fish)"
                timeLabel="Last 7 days"
                value={safeFormat(avgLicePerFish)}
                icon={
                  <Box padding="5px" color="blue.500">
                    <RiBug2Line size={20} />
                  </Box>
                }
                href={`/project/${projectContext.id}/site/${site.id}/sea-lice#motile-per-fish`}
              />
            </>
          )}
          <Stat
            key="tide-height"
            cyId="headline-stat"
            label="Tide Height (m)"
            timeLabel="Now"
            value={
              <HStack justifyContent="center" w="100%">
                <Text>{safeFormat(currentTide)}</Text>
                {currentTide && lastTide && (
                  <Box color="blue.500">
                    {currentTide < lastTide ? (
                      <HiArrowDown style={{ marginBottom: '2px' }} />
                    ) : (
                      <HiArrowUp style={{ marginBottom: '2px' }} />
                    )}
                  </Box>
                )}
              </HStack>
            }
            icon={
              <Box padding="5px" color="blue.500">
                <BiWater size={20} />
              </Box>
            }
            href={`/project/${projectContext.id}/site/${site.id}/environment#tides`}
          />
          <Stat
            key="next-tide"
            cyId="headline-stat"
            label="Next Tide High/Low (m)"
            timeLabel={
              currentHilo?.time ? `At ${format(Date.parse(currentHilo?.time), 'HH:mm')}` : '-'
            }
            value={
              <HStack justifyContent="center" w="100%">
                <Text>{safeFormat(currentHilo?.value)}</Text>
                {currentHilo?.value && nextHilo?.value && (
                  <Box color="blue.500">
                    {currentHilo.value < nextHilo.value ? (
                      <GiLowTide style={{ marginBottom: '6px' }} />
                    ) : (
                      <GiHighTide style={{ marginBottom: '6px' }} />
                    )}
                  </Box>
                )}
              </HStack>
            }
            icon={
              <Box padding="5px" color="blue.500">
                <BiWater size={20} />
              </Box>
            }
            href={`/project/${projectContext.id}/site/${site.id}/environment#tides`}
          />
          {projectContext.siwi && site.siteLabel === 'Farm' && (
            <Stat
              key="latest-siwi"
              cyId="headline-stat-siwi"
              label="Latest SIWI"
              timeLabel="Last 24 hours"
              value={safeFormat(siwiTotal, 0)}
              icon={
                <Box color="blue.500" m="5px">
                  <Image src="/siwi_icon.jpg" h="20px" />
                </Box>
              }
              href={`/project/${projectContext.id}/site/${site.id}/siwi#overview`}
            />
          )}
          {projectContext.hasModule('plankton') && site.type !== 'asc' ? (
            <>
              <Stat
                key="plankton-cells-ml"
                cyId="headline-stat"
                label="Plankton (cells/mL)"
                timeLabel={recentDistance(new Date(scariestPlankton?.time))}
                value={
                  <>
                    <Box fontSize="medium" fontWeight={'bold'} color={'black'}>{`${
                      scariestPlankton.concentration
                        ? `${capitalize(scariestPlankton.threshold)}: `
                        : ''
                    }${safeFormat(Number(scariestPlankton.concentration))}`}</Box>
                    <Box>
                      <Text fontSize="small" color="gray.500">
                        {scariestPlankton?.species &&
                          projectContext.findPlanktonPolicy(scariestPlankton.species).name}
                      </Text>
                    </Box>
                  </>
                }
                icon={
                  <Box padding="5px" color="blue.500">
                    <GiLongAntennaeBug size={20} />
                  </Box>
                }
                href={`/project/${projectContext.id}/site/${site.id}/plankton/data#latest-sample`}
              />
            </>
          ) : (
            <></>
          )}
        </Flex>
      )}
    </Box>
  );
};

export default HeadlineStats;
