import { Skeleton } from '@chakra-ui/react';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, uniq } from 'lodash';
import { PlotlyDataLayoutConfig } from 'plotly.js';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { locationToIndex } from 'shared/functions/location';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot from '../Plot';
import { BaseChartProps } from '../types';

type AvgFishWeightDatum = {
  'Biology.avgWeight': number;
  'BiologyLookup.sublocation'?: string;
  'Site.id'?: string;
  'Biology.measuredAt': string;
};

type AvgFishWeightStructure = {
  [sublocation: string]: {
    [measuredAt: string]: {
      value: number;
    };
  };
};

const Chart = ({
  skip,
  settings,
  granularity = 'day',
  dateRange = 'last 30 days',
  chartRange,
  onDataLoaded
}: BaseChartProps) => {
  const locationDimension = settings.site?.smbId ? 'BiologyLookup.sublocation' : 'Site.id';

  const transform = (data: AvgFishWeightDatum[]): AvgFishWeightStructure => {
    const bySublocation = groupBy(data, (d) => d[locationDimension]);
    return Object.keys(bySublocation).reduce((acc, subloc: string) => {
      const byMeasuredAt = bySublocation[subloc].reduce((subAcc, datum) => {
        subAcc[datum['Biology.measuredAt']] = datum['Biology.avgWeight'];
        return subAcc;
      }, {});
      acc[subloc] = byMeasuredAt;
      return acc;
    }, {});
  };

  const graph = (data: AvgFishWeightStructure): PlotlyDataLayoutConfig => {
    const sublocs = uniq(Object.keys(data)).sort((a, b) => locationToIndex(a) - locationToIndex(b));
    const pallet = createLocationPallet({ locations: sublocs });
    const plotData = sublocs.map((subloc) => {
      return {
        x: Object.keys(data[subloc]),
        y: Object.values(data[subloc]),
        mode: 'lines+markers',
        type: 'scatter',
        showlegend: true,
        name: settings.site?.smbId ? subloc : settings.project.siteNameMappings[subloc],
        marker: {
          color: pallet[subloc]
        },
        hovertemplate: '<b>%{y:0.2f}</b>'
      };
    });

    const layout = {
      legend: {
        orientation: 'h',
        x: 0,
        y: 1.25
      },
      yaxis: {
        title: {
          text: 'Weight (kg)',
          font: {
            size: 14
          }
        }
      },
      xaxis: {
        range: chartRange
      },
      autosize: true
    };

    return {
      //@ts-ignore
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      measures: ['Biology.avgWeight'],
      dimensions: [locationDimension],
      filters: settings.site?.smbId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [settings.site?.smbId.toString()]
            }
          ]
        : [],
      timezone: settings.project.timezone,
      timeDimensions: [
        {
          dimension: 'Biology.measuredAt',
          granularity,
          dateRange
        }
      ]
    },
    transform,
    graph,
    options: { skip, onDataLoaded, dependencies: { chartRange } }
  });

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }

  return plot?.data?.length ? (
    <Plot className="w-100 average-fish-weight-plot" useResizeHandler={true} {...plot} />
  ) : plot?.data?.length === 0 && settings.project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : (
    <NoData minH="450px" />
  );
};

export default Chart;
