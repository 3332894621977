import { Skeleton } from '@chakra-ui/react';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, uniq } from 'lodash';
import { Data, PlotlyDataLayoutConfig } from 'plotly.js';
import { createLocationPallet } from 'shared/functions/colorPallets';
import GraphError from '../GraphError';
import NoData from '../NoData';
import Plot from '../Plot';
import { BaseChartProps } from '../types';

type PlanktonCubeDatum = {
  'TessPlanktonLookup.sublocation'?: string;
  'Site.id'?: string;
  'TessPlankton.measuredAt': string;
  'TessPlankton.measuredAt.minute': string;
  'TessPlanktonLookup.species': string;
  'TessPlanktonLookup.depth': string;
  'TessPlankton.avgCellCount': number;
};

// Target data structure for plot output
type PlanktonStructure = {
  [sublocation: string]: {
    [species: string]: {
      [measuredAt: string]: {
        depth: number;
        avgCellCount: number;
      };
    };
  };
};

const Chart = ({
  settings,
  granularity = 'minute',
  dateRange = 'from 1 week ago to 1 day from now',
  chartRange,
  skip,
  onDataLoaded
}: BaseChartProps) => {
  const locationDimension = settings.site?.smbId ? 'TessPlanktonLookup.sublocation' : 'Site.id';

  const transform = (rawData: PlanktonCubeDatum[]): PlanktonStructure => {
    const bySublocation = groupBy(rawData, locationDimension);
    // Aggregate on sublocation
    const sppData = Object.entries(bySublocation)
      .sort((one, two) => (one > two ? 1 : -1))
      .reduce((sublocAcc, [sublocation, dataAtLocation]) => {
        // Aggregate on species
        const bySpecies = Object.entries(
          groupBy(dataAtLocation, 'TessPlanktonLookup.species')
        ).reduce((sppAcc, [species, dataForSpecies]) => {
          sppAcc[species] = sppAcc[species] || {};
          dataForSpecies.forEach((d) => {
            sppAcc[species][d[`TessPlankton.measuredAt.${granularity}`]] = {
              avgCellCount: d['TessPlankton.avgCellCount'],
              depth: d['TessPlanktonLookup.depth']
            };
          });
          return sppAcc;
        }, {});
        sublocAcc[sublocation] = bySpecies;
        return sublocAcc;
      }, {});
    return sppData;
  };
  const graph = (data: PlanktonStructure): PlotlyDataLayoutConfig => {
    const locations = uniq(Object.keys(data));
    const pallet = createLocationPallet({ locations: locations });
    //@ts-ignore
    const plotData: Data[] = Object.entries(data).flatMap(([sublocation, sublocationData]) => {
      let legendFlag = true;
      const sppPlots = Object.entries(sublocationData)
        .sort()
        .map(([species, speciesData]) => {
          const name = settings.project.findPlanktonPolicy(species).name;
          const sppPlot = {
            mode: 'markers',
            type: 'scattergl',
            x: Object.keys(speciesData),
            y: Array(Object.keys(speciesData).length).fill('<i><b>' + name),
            hovertemplate: '%{text} <extra></extra>',
            hoverlabel: {
              align: 'left'
            },
            text: Object.values(
              Object.entries(speciesData).reduce((depAcc, [time, dataAtTime]) => {
                depAcc[time] = `<b>${name}</b> <br> tow depth: ${dataAtTime['depth']}m`;
                return depAcc;
              }, {})
            ),
            name: settings.site?.smbId
              ? sublocation
              : settings.project.siteNameMappings[sublocation],
            legendgroup: sublocation,
            showlegend: legendFlag,
            marker: {
              color: pallet[sublocation],
              size: Array(Object.values(speciesData).length).fill(15)
            }
          };
          if (legendFlag) {
            legendFlag = false;
          }
          return sppPlot;
        });
      return sppPlots;
    });

    return {
      data: plotData,
      layout: {
        // ...layout,
        // autosize: true,
        height: 500, // make this dynamic w/ number of species, fix the ones with really long words
        showlegend: true,
        margin: {
          // pad: -50,
          t: 50,
          b: 50
        },
        hovermode: 'x unified',
        yaxis: {
          showticklabels: true,
          automargin: true,
          rangemode: 'tozero',
          autorange: 'reversed',
          showline: true,
          title: {
            // text: 'Species Present',
            font: {
              size: 16
            },
            standoff: 0
          },

          tickfont: {
            size: 14
          },
          tickangle: 0
        },
        legend: {
          orientation: 'h',
          x: 0,
          y: 1.15,
          yanchor: 'top',
          font: {
            size: 14
          }
        },
        xaxis: {
          range: chartRange
        }
        //@ts-ignore
        // annotations
      }
    };
  };
  // const graph = (data: PlanktonStructure): PlotlyDataLayoutConfig => {};
  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      timeDimensions: [
        {
          dimension: 'TessPlankton.measuredAt',
          granularity,
          dateRange
        }
      ],
      dimensions: [locationDimension, 'TessPlanktonLookup.species', 'TessPlanktonLookup.depth'],
      measures: ['TessPlankton.avgCellCount'],
      filters: settings.site?.smbId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [settings.site?.smbId.toString()]
            },
            { member: 'TessPlanktonLookup.method', operator: 'equals', values: ['tow'] },
            { member: locationDimension, operator: 'set' },
            { member: 'TessPlankton.avgCellCount', operator: 'gt', values: ['0'] }
          ]
        : [
            { member: 'TessPlanktonLookup.method', operator: 'equals', values: ['tow'] },
            { member: locationDimension, operator: 'set' },
            { member: 'TessPlankton.avgCellCount', operator: 'gt', values: ['0'] }
          ],
      timezone: settings.project.timezone
      // ungrouped: true
    },
    transform,
    graph,
    options: {
      refreshInterval: 900000,
      skip,
      onDataLoaded,
      dependencies: { chartRange }
    }
  });

  if (isLoading) {
    return <Skeleton minH="500px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="500px" />;
  }
  return plot?.data?.length > 0 ? (
    <Plot className="w-100 plankton-tow-summary" {...plot} />
  ) : (
    <NoData minH="500px" />
  );
};

export default Chart;
