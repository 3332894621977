import { HStack, Skeleton } from '@chakra-ui/react';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, uniq } from 'lodash';
import { PlotlyDataLayoutConfig } from 'plotly.js';
import { useMemo } from 'react';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { locationToIndex } from 'shared/functions/location';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot from '../Plot';
import { BaseChartProps, BaseChartSettings } from '../types';
import { ControllerInputs } from './Controller';

type TreatmentsDatum = {
  'TessTreatmentsLookup.sublocation'?: string;
  'Site.id'?: string;
  'TessTreatments.treatmentCount': number;
  'TessTreatments.treatmentType': string;
  'TessTreatments.treatmentMethod': string;
  'TessTreatments.reasonForTreatment': string;
  'TessTreatments.measuredAt': string;
};

type TreatmentsStructure = {
  [treatmentType: string]: {
    [measuredAt: string]: {
      count: number;
      method: string;
      reason: string;
      sublocations: string;
    };
  };
};

// prettier-ignore
const treatmentToNumMap = {
  'Sedation': 1,
  'Antibiotics': 2,
  'Bath': 3,
  'Benzoak': 4,
  'Anti-Parasitic': 5,
  'Tricaine': 6,
  'Feeding': 7,
  'Other Treatments': 8
};

// prettier-ignore
const treatmentTransformMap = {
  "Sedative": "Sedation",
  "Not Defined": "Other Treatments",
  "Antibiotics": "Antibiotics",
  "Sedation": "Sedation",
  "Bath": "Bath",
  "Benzoak": "Benzoak",
  "Anti-Parasitic": "Anti-Parasitic",
  "Other treatments": "Other Treatments",
  "Tricaine": "Tricaine",
  "Feeding": "Feeding",
  "Disinfectants and anti-parasite treatments": "Anti-Parasitic"
}

export type ChartSettings = BaseChartSettings & {
  selectedSublocation?: string;
};

const Chart = ({
  skip,
  granularity = 'day',
  dateRange = 'Last 30 days',
  chartRange,
  settings: { selectedSublocation, ...settings },
  control,
  onDataLoaded
}: BaseChartProps<ChartSettings, ControllerInputs>) => {
  const locationDimension = settings.site?.smbId ? 'TessTreatmentsLookup.sublocation' : 'Site.id';

  const transform = (data: TreatmentsDatum[]): TreatmentsStructure => {
    // Filter for the selected sublocation
    const sublocationLookupValue = settings.site?.smbId
      ? selectedSublocation
      : Number(
          Object.keys(settings.project.siteNameMappings).find(
            (subloc) => settings.project.siteNameMappings[subloc] === selectedSublocation
          )
        );

    const filteredData =
      selectedSublocation == 'All'
        ? data
        : data.filter((d) => d[locationDimension] === sublocationLookupValue);

    // Highest level group is treatment
    const byTreatmentType = groupBy(filteredData, (d) =>
      d['TessTreatments.treatmentType'] in treatmentTransformMap
        ? treatmentTransformMap[d['TessTreatments.treatmentType']]
        : 'Other Treatments'
    );
    return Object.keys(byTreatmentType).reduce((acc, treatmentType: string) => {
      // Next we group all the occurances of the treatment on the same day
      const byMeasuredAt = groupBy(
        byTreatmentType[treatmentType],
        (d) => d[`TessTreatments.measuredAt.${granularity}`]
      );
      const measuredAtGroups = Object.keys(byMeasuredAt).reduce((subAcc, measuredAt) => {
        const measuredAtGroup = {
          count: byMeasuredAt[measuredAt].reduce(
            (countAcc, datum) => countAcc + datum['TessTreatments.treatmentCount'],
            0
          ),
          sublocations: byMeasuredAt[measuredAt].reduce(
            (sublocAcc: string, datum: TreatmentsDatum) => {
              // sublocAcc = sublocAcc.concat(datum[locationDimension], ', ');
              sublocAcc = settings.site?.smbId
                ? sublocAcc.concat(
                    datum[locationDimension],
                    ': ',
                    datum['TessTreatments.treatmentCount'].toFixed(0),
                    ', '
                  )
                : sublocAcc.concat(
                    settings.project.siteNameMappings[datum[locationDimension]],
                    ': ',
                    datum['TessTreatments.treatmentCount'].toFixed(0),
                    ', '
                  );
              return sublocAcc;
            },
            ''
          ),
          method: byMeasuredAt[measuredAt][0]['TessTreatments.treatmentMethod'],
          reason: byMeasuredAt[measuredAt][0]['TessTreatments.reasonForTreatment']
        };
        subAcc[measuredAt] = measuredAtGroup;
        return subAcc;
      }, {});
      acc[treatmentType] = measuredAtGroups;
      return acc;
    }, {});
  };

  const graph = (data: TreatmentsStructure): PlotlyDataLayoutConfig => {
    const pallet = createLocationPallet({ locations: Object.keys(treatmentToNumMap) });
    const plotData = Object.keys(treatmentToNumMap).map((treatment) => {
      const treatmentData = data[treatment];
      return {
        type: 'scatter',
        name: treatment,
        x: Object.keys(treatmentData ?? {}),
        y: Array(Object.keys(treatmentData ?? {}).length).fill(treatmentToNumMap[treatment]),
        marker: {
          color: pallet[treatment],
          size: Object.values(treatmentData ?? {}).map((x) => Math.log(x.count) * 5 + 5)
        },
        mode: 'markers',
        hovertemplate: Object.values(treatmentData ?? {}).map(
          (x) =>
            `<b>%{x}</b><br> <b>Total Count:</b> ${x.count.toFixed(
              1
            )}<br><b>Sublocations:</b><br> ${x.sublocations
              .substring(0, x.sublocations.length - 2)
              .replaceAll(',', '<br>')} <br><b>Method:</b> ${x.method}<br><b>Reason:</b> ${
              x.reason
            }`
        ),
        showlegend: false,
        visible: true
      };
    });

    // x-axis date limits
    const today = new Date();
    const startDate = new Date(new Date().setDate(new Date().getDate() - 33));

    const layout = {
      yaxis: {
        showTickLabels: true,
        zeroline: false,
        // autorange: false,
        title: {
          text: 'Treatments',
          standoff: 5
        },
        automargin: true,
        font: {
          size: 14
        },
        tickmode: 'array',
        ticktext: Object.keys(treatmentToNumMap).map((x: string) => {
          return x.replace(' ', '<br>');
        }),
        tickvals: Object.values(treatmentToNumMap),
        range: [0, Object.keys(treatmentToNumMap).length + 1]
      },
      xaxis: {
        type: 'date',
        range: chartRange ?? [startDate, today],
        autorange: false
      },
      showlegend: true,
      margin: {
        t: 20,
        r: 0,
        l: 130
      },
      autosize: true
    };

    return {
      //@ts-ignore
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };

  const legendGraph = (): PlotlyDataLayoutConfig => {
    const pallet = createLocationPallet({ locations: ['filler'] });
    // add the markers for the legend
    const legendMarkers = [20, 2000, 20000];
    const plotData = legendMarkers.map((x, idx) => {
      return {
        type: 'scatter',
        name: x.toString(),
        x: [1],
        y: [(idx + 1) * 10],
        marker: {
          color: pallet['filler'],
          size: [Math.log(x) * 5 + 5]
        },
        mode: 'markers',
        hovertemplate: [''],
        visible: true,
        showlegend: false,
        autosize: true
      };
    });

    const layout = {
      yaxis: {
        zeroLine: false,
        showTickLabels: true,
        tickmode: 'array',
        ticktext: ['20', '2,000', '200,000'],
        tickvals: [10, 20, 30],
        showgrid: false,
        autorange: true
      },
      xaxis: {
        showticklabels: false,
        zeroline: false,
        showline: false,
        showgrid: false,
        range: [0.96, 1.03]
      },
      showLegend: false,
      title: 'Fish Count<br>Legend'
    };

    return {
      //@ts-ignore
      data: plotData,
      //@ts-ignore
      layout: layout,
      //@ts-ignore
      config: { staticPlot: true, displayModeBar: false }
    };
  };

  const { isLoading, error, plot, resultSet } = useCubeLTG({
    cubeQuery: {
      measures: ['TessTreatments.treatmentCount'],
      dimensions: [
        locationDimension,
        'TessTreatments.treatmentType',
        'TessTreatments.treatmentMethod',
        'TessTreatments.reasonForTreatment',
        'TessTreatments.measuredAt'
      ],
      filters: settings.site?.smbId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [settings.site?.smbId.toString()]
            }
          ]
        : [],
      timezone: settings.project.timezone,
      timeDimensions: [
        {
          dimension: 'TessTreatments.measuredAt',
          granularity,
          dateRange
        }
      ]
    },
    transform,
    graph,
    options: {
      dependencies: {
        sublocation: selectedSublocation,
        chartRange
      },
      skip,
      onDataLoaded
    }
  });

  const sublocations = useMemo(() => {
    if (!resultSet?.rawData()) return [];

    return uniq(
      resultSet
        .rawData()
        .map((d) =>
          settings.site?.smbId
            ? d[locationDimension]
            : settings.project.siteNameMappings[d[locationDimension]]
        )
    ).sort((a, b) => locationToIndex(a) - locationToIndex(b));
  }, [resultSet]);

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }
  //@ts-ignore
  const noData = plot?.data.every((d) => d.x.length === 0);
  return !noData ? (
    <>
      {control ? control({ availableSublocations: sublocations }) : <></>}
      <HStack spacing="0">
        <Plot className="w-80" useResizeHandler={true} {...plot} />
        <Plot className="w-20" useResizeHandler={true} {...legendGraph()} />
      </HStack>
    </>
  ) : noData && settings.project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : (
    <>
      {control ? control({ availableSublocations: sublocations }) : <></>}
      <NoData minH="450px" />
    </>
  );
};

export default Chart;
