import { Skeleton } from '@chakra-ui/react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import useCubeLTG from 'hooks/useCubeLTG';
import { PlotlyDataLayoutConfig } from 'plotly.js';
import { siwiPallet, trafficLightPallet } from 'shared/functions/colorPallets';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot from '../Plot';
import {
  categoryPrefix,
  fullDatumTransform,
  indicatorMap,
  indicatorOrder,
  safeFormat,
  siwiDimensions,
  siwiMeasures,
  siwiStructure,
  statusCodeMap,
  unitMap
} from '../SIWI/SIWIShared';
import { BaseChartProps, BaseChartSettings } from '../types';

export type ChartSettings = BaseChartSettings & {
  showCategories: boolean;
};

const Chart = ({
  granularity = 'day',
  dateRange = 'From 13 days ago to now',
  chartRange,
  skip,
  settings,
  onDataLoaded
}: BaseChartProps<ChartSettings>) => {
  const graph = (data: siwiStructure, dependencies?: any): PlotlyDataLayoutConfig => {
    const categories = Object.values(indicatorMap).filter((x) => x.includes(categoryPrefix));
    const indicators = Object.keys(data)
      .filter((ind) =>
        dependencies.showCategories
          ? categories.includes(ind)
          : !categories.includes(ind) && ind != indicatorMap.sT
      )
      .sort((a, b) => indicatorOrder[a] - indicatorOrder[b]);
    const plotData = [
      {
        x: Object.keys(data[indicatorMap.sT]).map((t) => new Date(t)),
        y: Array(Object.keys(data[indicatorMap.sT]).length).fill(2),
        type: 'scatter',
        mode: 'markers+text',
        marker: {
          size: 12,
          symbol: Object.values(data[indicatorMap.sT]).map((d) =>
            statusCodeMap[d.statusCode]['level'] === 'ok'
              ? 'circle'
              : statusCodeMap[d.statusCode]['level'] === 'warning'
                ? 'triangle-up'
                : 'x'
          ),
          color: Object.values(data[indicatorMap.sT]).map((d) =>
            statusCodeMap[d.statusCode]['level'] === 'ok'
              ? trafficLightPallet[0]
              : statusCodeMap[d.statusCode]['level'] === 'warning'
                ? trafficLightPallet[0.5]
                : trafficLightPallet[1]
          )
        },
        name: '',
        hovertemplate: Object.values(data[indicatorMap.sT]).map(
          (d) => `Status Code: "${statusCodeMap[d.statusCode]['readable']}"`
        ),
        showlegend: false
      }
    ];

    // Get the max score for when we have a KO
    // const max_score = Object.values(data[indicatorMap.sT]).reduce((max, x) => {
    //   return x.score > max ? x.score : max;
    // }, 0);

    plotData.push(
      //@ts-ignore
      ...indicators.map((indicator, idx) => {
        return {
          x: Object.keys(data[indicator]).map((t) => new Date(t)),
          y: Object.keys(data[indicator]).flatMap((t) =>
            !data[indicator][t].ko
              ? data[indicator][t].score * data[indicator][t].weight
              : // Sum all the other indicators and minus from 100. We want the failing indicator to fill the space to 100
                100 -
                indicators.reduce((sum, ind) => {
                  if (ind != indicator) sum += data[ind][t].score * data[ind][t].weight;
                  return sum;
                }, 0)
          ),
          type: 'bar',
          name: indicator.replace(categoryPrefix, '').replace(' ', '<br>'),
          marker: {
            color: siwiPallet[indicator],
            pattern: {
              shape: Object.values(data[indicatorMap.sT]).map((d) => (d.ko ? 'x' : '')),
              fillmode: 'overlay',
              fgcolor: '#FFFFFF',
              fgopacity: 1
            }
          },
          text: Object.values(data[indicatorMap.sT]).map((d) =>
            d.ko && idx === indicators.length - 1 ? '<b>KO</b>' : ''
          ),
          textposition: 'outside',
          hoverinfo: 'text',
          hovertext: Object.keys(data[indicator]).flatMap((t) => {
            return (
              (data[indicator][t].ko ? '<br><b>KO</b><br>' : '') +
              `Measured on: ${t !== 'undefined' ? format(parseISO(t), 'MMM dd') : 'Unknown'}` +
              `<br>Contribution to total stress: ${
                data[indicator][t].ko
                  ? 'KO'
                  : safeFormat(data[indicator][t].score * data[indicator][t].weight, 2, '0.0')
              }` +
              (dependencies.showCategories
                ? ''
                : `<br>${indicator.replace(categoryPrefix, '')} score: ${
                    data[indicator][t].ko ? 'KO' : safeFormat(data[indicator][t].score, 0, '0')
                  } <br>${unitMap[data[indicator][t].units]}: ${
                    isNaN(data[indicator][t].value)
                      ? data[indicator][t].value
                      : Number(data[indicator][t].value).toPrecision(2)
                  }`) +
              `<br>Data Source: ${data[indicator][t].missing ? 'Estimated' : 'Farm Data'}` +
              `<br>SIWI Stress Total: ${safeFormat(data[indicatorMap.sT][t].score, 0)}`
            );
          }),
          yaxis: 'y2'
        };
      })
    );

    const layout = {
      barmode: 'stack',
      yaxis: {
        domain: [0, 0.1],
        fixedrange: true,
        range: [1.5, 2.5],
        tickmode: 'array',
        ticktext: ['Status Code'],
        tickvals: [2],
        side: 'right'
      },
      yaxis2: {
        title: {
          text: 'Contribution to SIWI Stress Total'
        },
        zeroline: false,
        domain: [0.1, 1]
      },
      xaxis: {
        tickformat: '%b %d',
        range: chartRange
      },
      legend: {
        title: {
          text: 'Stress Indicators'
        }
      },
      autosize: true,
      hovermode: 'closest',
      margin: {
        t: 10,
        b: 20,
        l: 40,
        r: 160
      }
    };

    return {
      //@ts-ignores
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      measures: siwiMeasures('TessSiwi'),
      dimensions: siwiDimensions('TessSiwi'),
      filters: [
        {
          member: 'Site.id',
          operator: 'equals',
          values: [settings.site.smbId.toString()]
        }
      ],
      timezone: settings.project.timezone,
      timeDimensions: [
        {
          dimension: 'TessSiwi.measuredAt',
          granularity,
          dateRange
        }
      ],
      order: {
        'TessSiwi.measuredAt': 'desc'
      }
    },
    transform: (rawData, dependencies) =>
      //@ts-ignore
      fullDatumTransform(rawData, dependencies, granularity),
    graph,
    options: {
      skip,
      dependencies: {
        cubeName: 'TessSiwi',
        showCategories: settings.showCategories,
        chartRange
      },
      onDataLoaded
    }
  });

  if (isLoading) {
    return <Skeleton minH="450px" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }

  return plot?.data?.[0]['x'].length === 0 && settings.project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : plot?.data?.[0]['x'].length === 0 ? (
    <NoData minH="450px" />
  ) : (
    <Plot className="w-100 h-100 siwi-stacked-bar-plot" useResizeHandler={true} {...plot} />
  );
};

export default Chart;
