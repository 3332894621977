import { HStack, Text } from '@chakra-ui/react';
import { ChartController } from '../types';
import { ChartSettings } from './Chart';
import Select from 'react-select';

export type ControllerInputs = {
  availableSublocations: string[];
};

const Controller = ({
  chartSettings,
  setChartSettings,
  chartInputs: { availableSublocations } = { availableSublocations: [] }
}: ChartController<ChartSettings, ControllerInputs>) => {
  const locationOptions = [
    { label: 'All', value: 'All' },
    ...availableSublocations.map((s) => ({ label: s, value: s }))
  ];

  return (
    <HStack alignItems="center" justifyContent="center">
      <Text>Sublocation: </Text>
      <Select
        className="w-20 tl"
        value={
          chartSettings.selectedSublocation
            ? locationOptions.find((lo) => lo.value === chartSettings.selectedSublocation)
            : { label: 'All', value: 'All' }
        }
        onChange={(x) => setChartSettings({ ...chartSettings, selectedSublocation: x.value })}
        options={locationOptions}
      />
    </HStack>
  );
};

export default Controller;
