import { Skeleton } from '@chakra-ui/react';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, uniq } from 'lodash';
import { PlotlyDataLayoutConfig } from 'plotly.js';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { locationToIndex } from 'shared/functions/location';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot from '../Plot';
import { BaseChartProps } from '../types';

type MortPercentDatum = {
  'BiologyLookup.sublocation'?: string;
  'Site.id'?: string;
  'Biology.measuredAt': string;
  'Biology.mortalityCountPercent': number;
  'Biology.startCountAvg': number;
  'Biology.mortalityCountAvg': number;
};

type MortPercentStructure = {
  [sublocation: string]: {
    [measuredAt: string]: {
      percent: number;
    };
  };
};

const Chart = ({
  settings,
  dateRange = 'Last 30 days',
  chartRange,
  granularity = 'day',
  skip,
  onDataLoaded
}: BaseChartProps) => {
  const locationDimension = settings.site?.smbId ? 'BiologyLookup.sublocation' : 'Site.id';

  const transform = (data: MortPercentDatum[]): MortPercentStructure => {
    // Create the structure for the sublocations
    const bySublocation = groupBy(data, (d) => d[locationDimension]);
    const output = Object.keys(bySublocation).reduce((acc, sublocation: string) => {
      const byMeasuredAt = bySublocation[sublocation].reduce((subAcc, datum) => {
        subAcc[datum[`Biology.measuredAt.${granularity}`]] = {
          percent: datum['Biology.mortalityCountPercent']
        };
        return subAcc;
      }, {});
      acc[sublocation] = byMeasuredAt;
      return acc;
    }, {});

    const byMeasuredAt = groupBy(data, (d) => d[`Biology.measuredAt.${granularity}`]);
    output['site-mortality'] = Object.keys(byMeasuredAt).reduce((acc, measuredAt: string) => {
      const totalCount = byMeasuredAt[measuredAt].reduce(
        (subAcc, datum) => subAcc + datum['Biology.startCountAvg'],
        0
      );
      const totalMortCount = byMeasuredAt[measuredAt].reduce(
        (subAcc, datum) => subAcc + datum['Biology.mortalityCountAvg'],
        0
      );

      acc[measuredAt] = {
        percent: (totalMortCount / totalCount) * 100
      };
      return acc;
    }, {});

    return output;
  };

  const graph = (data: MortPercentStructure): PlotlyDataLayoutConfig => {
    const sublocations = uniq(Object.keys(data)).sort(
      (a, b) => locationToIndex(a) - locationToIndex(b)
    );
    // Move the 'site' location to the end so its more visible
    sublocations.push(sublocations.shift());
    const pallet = createLocationPallet({ locations: sublocations });
    const plotData = sublocations.map((subloc) => {
      return {
        type: 'scatter',
        mode: 'lines+markers',
        name: settings.site?.smbId
          ? subloc
          : settings.project.siteNameMappings[subloc]
            ? settings.project.siteNameMappings[subloc]
            : subloc == 'site-mortality'
              ? 'Average'
              : 'unknown',
        x: Object.keys(data[subloc]),
        y: Object.values(data[subloc]).flatMap((d) => d.percent),
        marker: {
          color: pallet[subloc]
        },
        line: {
          dash: subloc.includes('site') ? 'dash' : 'solid',
          width: subloc.includes('site') ? 3 : 2
        },
        showlegend: true,
        hovertemplate: `<b>%{x}</b><br>${
          settings.site?.smbId
            ? subloc
            : settings.project.siteNameMappings[subloc]
              ? settings.project.siteNameMappings[subloc]
              : subloc == 'site-mortality'
                ? 'Average'
                : 'unknown'
        }<br><b>%{y:.3f}</b><extra></extra>`
      };
    });

    const layout = {
      legend: {
        orientation: 'h',
        x: 0,
        y: 1.25
      },
      yaxis: {
        title: {
          text: 'Mortality Loss (%)',
          font: {
            size: 14
          }
        }
      },
      xaxis: {
        range: chartRange
      },
      autosize: true
    };

    return {
      //@ts-ignore
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };

  const { isLoading, error, plot } = useCubeLTG({
    cubeQuery: {
      measures: [
        'Biology.mortalityCountPercent',
        'Biology.startCountAvg',
        'Biology.mortalityCountAvg'
      ],
      timeDimensions: [
        {
          dimension: 'Biology.measuredAt',
          granularity,
          dateRange
        }
      ],
      dimensions: [locationDimension],
      // @ts-ignore
      filters: settings.site?.smbId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [settings.site?.smbId]
            }
          ]
        : [],
      timezone: settings.project.timezone
    },
    transform: transform,
    graph,
    options: {
      skip,
      onDataLoaded,
      dependencies: {
        chartRange
      }
    }
  });

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }
  const noData = plot?.data[0]['x'].length == 0;
  return !noData ? (
    <Plot className="w-100 percent-mort-plot" useResizeHandler={true} {...plot} />
  ) : noData && settings.project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : (
    <NoData minH="450px" />
  );
};

export default Chart;
