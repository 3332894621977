import { Skeleton } from '@chakra-ui/react';
import { aggregatePlankton } from 'components/Site/Tabs/Explore/TessTraceHelpers';
import { groupby } from 'components/Site/Tabs/Explore/TraceHelpers';
import useCubeLTG from 'hooks/useCubeLTG';
import { max, range, uniq } from 'lodash';
import { Data, PlotlyDataLayoutConfig } from 'plotly.js';
import { useMemo } from 'react';
import { paramOptions } from 'shared/Config';
import { trafficLightPallet } from 'shared/functions/colorPallets';
import { planktonThresholdValue, thresholdForSpeciesValue } from 'shared/plankton';
import GraphError from '../GraphError';
import NoData from '../NoData';
import Plot from '../Plot';
import { BaseChartProps, BaseChartSettings } from '../types';
import { ControllerInputs } from './Controller';

type PlanktonCubeDatum = {
  'TessPlanktonLookup.sublocation'?: string;
  'Site.id'?: string;
  'TessPlankton.measuredAt': string;
  'TessPlankton.measuredAt.minute': string;
  'TessPlanktonLookup.species': string;
  'TessPlanktonLookup.depth': string;
  'TessPlankton.avgCellCount': number;
  'TessPlankton.maxCellCount': number;
};

type ContourColor = [any[], any[], any[], any[]];

const colors: ContourColor = [
  [0, '#eeeeee'],
  [0.000001, trafficLightPallet[0.0]],
  [0.5, trafficLightPallet[0.5]],
  [1, trafficLightPallet[1.0]]
];

export type ChartSettings = BaseChartSettings & {
  sublocations: string[];
  displayConcentration: boolean;
};

type ContourData = {
  value: number;
  time: Date;
  depth: number;
  info?: string;
  measurement?: number;
  threshold?: string;
};

const fillContourData = (
  contourHoverPoints: ContourData[][],
  defaultValue: ContourData,
  dataArray: { time: Date }[]
): ContourData[] => {
  // get sorted and deduplicated time values
  const timeValues = dataArray
    .map((v) => v.time)
    .sort((a, b) => (a > b ? 1 : -1))
    .filter((v, i, a) => a.indexOf(v) === i);
  const completeContourHoverPoints = contourHoverPoints.map((row) =>
    timeValues.map((dt) => {
      const cell = Object.assign({}, defaultValue);
      cell.time = dt;
      cell.depth = row[0].depth;
      return cell;
    })
  );
  completeContourHoverPoints.forEach((row, rowIndex) => {
    row.forEach((cell, columnIndex) => {
      const value = contourHoverPoints[rowIndex].filter((v) => v.time === cell.time);
      if (value.length > 0) {
        completeContourHoverPoints[rowIndex][columnIndex] = value[0];
      }
    });
  });
  // concatenate list of lists
  // eslint-disable-next-line prefer-spread
  return [].concat.apply([], completeContourHoverPoints);
};

const Chart = ({
  granularity = 'minute',
  dateRange = 'From 1 week ago to 1 day from now',
  chartRange,
  settings,
  skip,
  control,
  onDataLoaded
}: BaseChartProps<ChartSettings, ControllerInputs>) => {
  const locationDimension = settings.site?.smbId ? 'TessPlanktonLookup.sublocation' : 'Site.id';

  // transform raw query results
  const transform = (rawData: PlanktonCubeDatum[]): ContourData[] => {
    let planktonData = rawData;
    if (settings.sublocations.length > 0 && !settings.sublocations.includes('All')) {
      const sublocationLookupValues = settings.site?.smbId
        ? settings.sublocations
        : Object.values(settings.sublocations).flatMap((d) => {
            return Object.keys(settings.project.siteNameMappings).find(
              (sublocId) => settings.project.siteNameMappings[sublocId] === d
            );
          });
      planktonData = planktonData.filter((d) => {
        return sublocationLookupValues.includes(d[locationDimension].toString());
      });
    }
    const processedPlanktonData = planktonData.map((d) => ({
      ...d,
      thresholdVal: planktonThresholdValue(
        settings.project,
        d['TessPlanktonLookup.species'],
        d['TessPlankton.maxCellCount']
      ),
      threshold: thresholdForSpeciesValue(
        settings.project,
        d['TessPlanktonLookup.species'],
        d['TessPlankton.maxCellCount']
      )
    }));
    const values = aggregatePlankton(
      settings.project,
      processedPlanktonData.sort((a, b) => {
        return b.thresholdVal - a.thresholdVal;
      }),
      'TessPlankton.avgCellCount',
      granularity
    );
    values.sort((a, b) => (a.depth < b.depth ? 1 : -1));
    values.sort((a, b) => (a.time > b.time ? 1 : -1));
    if (!settings.displayConcentration) {
      // Plot thresholdVal instead of concentration (avgCellCount)
      values.forEach((v) => (v.value = v.thresholdVal));
    }
    // const data = [];
    const defaultValue = {
      time: null, // placeholder
      depth: 0, // placeholder
      value: 0,
      info: '<b>No record available</b><br>',
      threshold: 'None'
    };
    const customData: ContourData[] = fillContourData(
      Object.values(groupby(values, 'depth')),
      defaultValue,
      values
    );
    return customData;
  };
  const graph = (data: ContourData[]): PlotlyDataLayoutConfig => {
    const uniqueDepths = uniq(Object.values(data).flatMap((d) => d.depth));
    const uniqueTimes = uniq(Object.values(data).flatMap((d) => d.time));
    if (uniqueTimes.length < 2 || uniqueDepths.length < 2) {
      // Does not meet minimum samples need to render contours
      return { data: [], layout: {} };
    }
    const zmax = max(
      data.flatMap((e) => {
        return Number(e.value);
      })
    );
    let zTransform = function (d) {
      return d;
    };
    if (zmax >= 100) {
      zTransform = Math.log;
    }
    //@ts-ignore
    const plotData: Data[] = [
      {
        // x: data.map((d) => d.time),
        x: data.map((d) => {
          return d.time;
        }),
        y: data.map((e) => {
          return e.depth;
        }),
        z: data.map((e) => {
          return settings.displayConcentration ? zTransform(Number(e.value) + 1) : e.value;
        }),
        //@ts-ignore
        customdata: data,
        type: 'contour',
        hovertemplate:
          `<b>${paramOptions.plankton_threshold_value.name}: %{customdata.threshold} </b><br>` +
          `<b>Time: %{x}</b><br>` +
          '<b>Depth: %{y}</b><br>' +
          '%{customdata.info}' +
          '<extra></extra>',
        showlegend: false,
        zsmooth: 'best',
        connectgaps: false,
        //@ts-ignore
        zauto: false,
        zmin: settings.displayConcentration ? 0 : paramOptions.plankton_threshold_value.range[0],
        zmax: settings.displayConcentration
          ? zTransform(zmax)
          : paramOptions.plankton_threshold_value.range[1],
        // showscale: true,
        name: paramOptions.plankton_threshold_value.label,
        //@ts-ignore
        colorscale: settings.displayConcentration ? paramOptions.plankton_cell_count.color : colors,
        // : paramOptions.plankton_threshold_value.color,
        line: { smoothing: 1, width: 0 },
        contours: {
          coloring: 'fill',
          showlines: false,
          start: settings.displayConcentration ? 0 : paramOptions.plankton_threshold_value.range[0],
          end: settings.displayConcentration
            ? zTransform(zmax)
            : paramOptions.plankton_threshold_value.range[1],
          size:
            (paramOptions.plankton_threshold_value.range[1] -
              paramOptions.plankton_threshold_value.range[0]) /
            (settings.displayConcentration ? 10 : 10)
        }
      }
    ];
    plotData[0]['colorbar'] = {
      lenmode: 'fraction',
      //formula is an approximation for where center of colorbar should be
      y: 0.5,
      len: 0.75,
      ypad: 0,
      yanchor: 'middle',
      tickmode: 'array',
      tickangle: 0
    };
    if (settings.displayConcentration) {
      plotData[0]['colorbar'] = {
        title: 'Cells/mL',
        tickvals: range(0, zTransform(zmax + 1), zTransform(zmax + 1) / 10).map((d) =>
          d.toFixed(2)
        ),
        ticktext: range(0, zmax, zmax / 10).map((d) => d.toFixed(1))
      };
    } else {
      plotData[0]['colorbar'] = {
        title: 'Threshold',
        tickvals: [-0.1, 0.4, 1, 1.8], // should these be project specific?
        ticktext: ['Zero', 'Safe', 'Caution', 'Danger']
      };
    }
    return {
      data: plotData,
      layout: {
        xaxis: {
          showspikes: true,
          spikemode: 'across',
          spikecolor: 'black',
          type: 'date',
          hoverformat: '%Y-%m-%d %H:00',
          range: chartRange
        },
        yaxis: {
          side: 'left',
          rangemode: 'tozero',
          showline: true,
          title: {
            text: 'Depth (m)',
            font: { size: 14 }
          },
          range: [25, 0]
        },
        autosize: true,
        clickmode: 'event',
        margin: { t: 30, b: 50 },
        hovermode: 'closest',
        height: 400
      }
    };
  };
  const { isLoading, error, plot, resultSet } = useCubeLTG({
    cubeQuery: {
      timeDimensions: [
        {
          dimension: 'TessPlankton.measuredAt',
          granularity,
          dateRange
        }
      ],
      dimensions: [locationDimension, 'TessPlanktonLookup.species', 'TessPlanktonLookup.depth'],
      measures: ['TessPlankton.avgCellCount', 'TessPlankton.maxCellCount'],
      filters: settings.site?.smbId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [settings.site?.smbId.toString()]
            },
            { member: locationDimension, operator: 'set' },
            { member: 'TessPlanktonLookup.depth', operator: 'set' },
            { member: 'TessPlanktonLookup.method', operator: 'equals', values: ['discrete'] }
          ]
        : [
            { member: locationDimension, operator: 'set' },
            { member: 'TessPlanktonLookup.depth', operator: 'set' },
            { member: 'TessPlanktonLookup.method', operator: 'equals', values: ['discrete'] }
          ],
      timezone: settings.project.timezone
    },
    transform,
    graph,
    options: {
      refreshInterval: 900000,
      skip,
      dependencies: {
        discreteSummaryToggle: settings.displayConcentration,
        sublocations: settings.sublocations,
        chartRange
      },
      onDataLoaded
    }
  });

  const availableSublocations = useMemo(() => {
    if (!resultSet?.rawData()) return [];
    return uniq(
      resultSet
        .rawData()
        .map((d) =>
          settings.site?.smbId
            ? d[locationDimension]
            : settings.project.siteNameMappings[d[locationDimension]]
        )
    );
  }, [resultSet]);

  return (
    <>
      {control ? control({ ...settings, availableSublocations }) : <></>}

      {isLoading ? (
        <Skeleton minH="500px" height="100%" width="100%" />
      ) : error ? (
        <GraphError minH="500px" />
      ) : plot?.data?.length > 0 ? (
        <Plot className="w-100 plankton-discrete-summary" {...plot} useResizeHandler={true} />
      ) : (
        <NoData minH="500px" />
      )}
    </>
  );
};

export default Chart;
