import ChartSwitchPanel from '../ChartSwitchPanel';
import { ChartController } from '../types';
import { ChartSettings } from './Chart';

const Controller = ({ setChartSettings, chartSettings }: ChartController<ChartSettings>) => {
  return (
    <ChartSwitchPanel
      switches={[
        {
          switchProps: { isChecked: chartSettings.useTotal },
          left: { value: 'sublocations', label: 'Sublocations' },
          right: { value: 'site', label: 'Site' },
          toggle: ({ checked }) => setChartSettings({ ...chartSettings, useTotal: checked })
        },
        {
          switchProps: { isChecked: chartSettings.useAppetite },
          left: { value: 'feedUseKg', label: 'Feed Use (kg)' },
          right: { value: 'appetite', label: 'Appetite (%)' },
          toggle: ({ checked }) => setChartSettings({ ...chartSettings, useAppetite: checked })
        }
      ]}
    />
  );
};

export default Controller;
